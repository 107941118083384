
import Vue from "vue";
import { iReason, iSopStep } from "@/managers/sop";
import { iTicket } from "@/managers/tickets";
import { TicketFlag, ActionType } from "@/managers/ticketActions";
import { mgr } from "@/managers";

export default Vue.extend({
  name: "Reason",
  components: {},
  props: {
    step: { type: Object as () => iSopStep, required: true },
    ticket: { type: Object as () => iTicket, required: true },
    index: { type: Number, required: true },
  },
  data: () => ({
    selectedReason: "",
    validOther: "",
    invalidOther: "",
  }),
  computed: {
    actionTypes(): ActionType[] {
      return this.ticket.actions.map((x) => x.actionType);
    },
    isResolved(): boolean {
      return this.actionTypes.includes(ActionType.REASON_ADDED);
    },
    validReasons(): iReason[] {
      return this.step.reasons?.filter((x) => x.valid) ?? [];
    },
    invalidReasons(): iReason[] {
      return this.step.reasons?.filter((x) => !x.valid) ?? [];
    },
    canSubmit(): boolean {
      if (this.isResolved) return false;

      if (this.selectedReason === "Valid other" && this.validOther.length >= 4) return true;
      if (this.selectedReason === "Invalid other" && this.invalidOther.length >= 4) return true;
      if (
        this.selectedReason !== "Valid other" &&
        this.selectedReason !== "Invalid other" &&
        this.selectedReason.length > 3
      )
        return true;
      return false;
    },
  },
  methods: {
    submitReason() {
      if (this.isResolved) return;
      let reason = this.selectedReason;
      if (reason == "Valid other") reason = "Valid other: " + this.validOther;
      if (reason == "Invalid other") reason = "Invalid other: " + this.invalidOther;
      mgr.ticketActions.reasonAdded(this.ticket.id, reason);
    },
    maybeAddStep(reasonText: string) {
      const reason = this.step.reasons?.find((x) => x.text === reasonText);
      if (!reason || !reason.nextStep) return;
      this.$emit("addStep", { step: reason.nextStep, index: this.index });
    },
    setFocus(ref: string) {
      if (this.isResolved) return;
      if (ref == "valid" && this.selectedReason !== "Valid other") {
        this.selectedReason = "Valid other";
        this.$nextTick(() => {
          const textField: any = this.$refs[ref];
          textField?.$el?.querySelector("input")?.focus?.();
        });
      }
      if (ref == "invalid" && this.selectedReason !== "Invalid other") {
        this.selectedReason = "Invalid other";
        this.$nextTick(() => {
          const textField: any = this.$refs[ref];
          textField?.$el?.querySelector("input")?.focus?.();
        });
      }
    },
    updateStep() {
      if (this.isResolved) {
        this.$emit("updateStep", { step: this.step, resolved: true });
        const action = this.ticket.actions.find((x) => x.actionType == ActionType.REASON_ADDED);
        if (action) {
          const reasonText: string = action.data.reason || "";
          if (reasonText.startsWith("Invalid other: ")) this.invalidOther = reasonText.replace("Invalid other: ", "");
          else if (reasonText.startsWith("Valid other: ")) this.validOther = reasonText.replace("Valid other: ", "");
          else this.selectedReason = reasonText || "";
          this.maybeAddStep(reasonText);

          // Add specifiedFlags. If reason is invalid, always add DRIVER_DISCIPLINE
          const reason = this.step.reasons?.find((x) => x.text === reasonText);
          const specifiedFlags = reason?.flags || [];
          if (reasonText.startsWith("Invalid other: ") && !specifiedFlags.includes(TicketFlag.DRIVER_DISCIPLINE))
            specifiedFlags.push(TicketFlag.DRIVER_DISCIPLINE);
          this.$emit("specifiedFlags", specifiedFlags);
        }
      } else {
        this.$emit("updateStep", {
          step: this.step,
          resolved: false,
          unResolvedReason: "No reason submitted",
        });
        this.$emit("specifiedFlags", []);
      }
    },
  },
  watch: {
    actionTypes() {
      this.updateStep();
    },
  },
  mounted() {
    this.updateStep();
    if (!this.isResolved) {
      this.selectedReason = "";
      this.validOther = "";
      this.invalidOther = "";
    }
  },
});
