
import Vue from "vue";
import { auth } from "@/plugins/auth";
import { Role } from "@/managers/roles";
import FindTicket from "@/components/inputs/FindTicket.vue";
import { iTicketCount } from "@/managers/tickets";
import { store } from "@/store/index";

export default Vue.extend({
  name: "AppSideMenu",
  components: { FindTicket },
  props: { value: { type: Boolean, default: true } },
  data: () => ({
    links: [{ text: "Test", icon: "cast" }],
    ticketIdSearch: 0,
  }),
  computed: {
    isSignedIn(): boolean {
      return auth.currentUser() !== null;
    },
    linkGroups(): any[] {
      const groups = [
        {
          text: "Inboxes",
          open: true,
          links: [
            {
              text: "Inbox",
              icon: "inbox",
              route: "/",
              requiresRole: [Role.OPERATOR, Role.DRIVER_DISCIPLINE_OPERATOR, Role.CUSTOMER_CARE_OPERATOR, Role.MAINTENANCE_OPERATOR],
              forbidRoles: [],
            },
            {
              text: "Driver discipline",
              icon: "bus_alert",
              route: "/disciplinary-tickets",
              requiresRole: [Role.OPERATOR, Role.DRIVER_DISCIPLINE_OPERATOR],
              forbidRoles: [],
            },
            {
              text: "Customer care",
              icon: "support_agent",
              route: "/customer-care-tickets",
              requiresRole: [Role.OPERATOR, Role.CUSTOMER_CARE_OPERATOR],
              forbidRoles: [],
            },
            {
              text: "Maintenance",
              icon: "build_circle",
              route: "/maintenance-tickets",
              requiresRole: [Role.OPERATOR, Role.MAINTENANCE_OPERATOR],
              forbidRoles: [],
            },
            {
              text: "Telemetry provider",
              icon: "settings_remote",
              route: "/telemetry-provider-tickets",
              requiresRole: [Role.OPERATOR, Role.TELEMETRY_PROVIDER],
              forbidRoles: [],
            },
            {
              text: "Transport schedule",
              icon: "date_range",
              route: "/transport-schedule-tickets",
              requiresRole: [Role.OPERATOR, Role.TRANSPORT_SCHEDULE_OPERATOR],
              forbidRoles: [],
            },
          ],
        },
        {
          text: "Tickets",
          open: true,
          links: [
            {
              text: "All tickets",
              icon: "list",
              route: "/tickets",
              requiresRole: [Role.OPERATOR, Role.DRIVER_DISCIPLINE_OPERATOR, Role.CUSTOMER_CARE_OPERATOR, Role.MAINTENANCE_OPERATOR],
              forbidRoles: [],
            },
            {
              text: "Dashboard",
              icon: "dashboard",
              route: "/dashboard",
              requiresRole: [
                Role.ADMIN,
                Role.OPERATOR,
                Role.DRIVER_DISCIPLINE_OPERATOR,
                Role.CUSTOMER_CARE_OPERATOR,
                Role.MAINTENANCE_OPERATOR,
                Role.TRANSPORT_SCHEDULE_OPERATOR,
                Role.DASHBOARD_VIEWER,
              ],
              forbidRoles: [],
            },
            {
              text: "SOP documentation",
              icon: "article",
              route: "/sop-documentation",
              requiresRole: [
                Role.ADMIN,
                Role.OPERATOR,
                Role.DRIVER_DISCIPLINE_OPERATOR,
                Role.CUSTOMER_CARE_OPERATOR,
                Role.MAINTENANCE_OPERATOR,
                Role.TRANSPORT_SCHEDULE_OPERATOR,
                Role.DASHBOARD_VIEWER,
              ],
              forbidRoles: [],
            },
            {
              text: "Create alerts",
              icon: "notifications_active",
              route: "/alerts",
              requiresRole: [Role.ADMIN],
              forbidRoles: [],
            },
          ],
        },
        {
          text: "Admin",
          open: true,
          links: [{ text: "Access", icon: "people", route: "/access", requiresRole: [Role.ADMIN], forbidRoles: [] }],
        },
      ];

      return groups
        .map((group) => {
          group.links = group.links
            .map((x) => {
              if (!x.requiresRole) x.requiresRole = [];
              if (!x.forbidRoles) x.forbidRoles = [];
              return x;
            })
            .filter((x) => auth.hasAtLeastOneRole(x.requiresRole) && auth.hasNoneOfRoles(x.forbidRoles));
          return group;
        })
        .filter((group) => group.links.length !== 0);
    },
    inboxTicketCount(): number {
      const count = store.ticketCount() as iTicketCount;
      if (count) return count.inboxTicketCount;
      return 0;
    },
    driverDisciplineTicketCount(): number {
      const count = store.ticketCount() as iTicketCount;
      if (count) return count.driverDisciplineTicketCount;
      return 0;
    },
    customerCareTicketCount(): number {
      const count = store.ticketCount() as iTicketCount;
      if (count) return count.customerCareTicketCount;
      return 0;
    },
    maintenanceTicketCount(): number {
      const count = store.ticketCount() as iTicketCount;
      if (count) return count.maintenanceTicketCount;
      return 0;
    },
    telemetryProviderTicketCount(): number {
      const count = store.ticketCount() as iTicketCount;
      if (count) return count.telemetryProviderFollowupTicketCount;
      return 0;
    },
    transportScheduleTicketCount(): number {
      const count = store.ticketCount() as iTicketCount;
      if (count) return count.transportSchedulingTicketCount;
      return 0;
    },
  },
  methods: {
    requestNotificationPermission(menuItemName: String) {
      // Only work on one button
      if (menuItemName !== "Inbox") return;
      // https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API
      if (window.Notification && Notification.permission !== "granted") Notification.requestPermission();
    },
    navigateTo(id: number) {
      const path = `/tickets/${id}`;
      if (this.$router.currentRoute.path !== path) this.$router.replace(path);
      this.ticketIdSearch = 0;
    },
    getTicketCounts(menuItem: string) {
      if (!menuItem) return;
      if (menuItem == "Inbox" && this.inboxTicketCount != 0) return this.inboxTicketCount;
      if (menuItem == "Driver discipline" && this.driverDisciplineTicketCount != 0) return this.driverDisciplineTicketCount;
      if (menuItem == "Customer care" && this.customerCareTicketCount != 0) return this.customerCareTicketCount;
      if (menuItem == "Maintenance" && this.maintenanceTicketCount != 0) return this.maintenanceTicketCount;
      if (menuItem == "Telemetry provider" && this.telemetryProviderTicketCount != 0) return this.telemetryProviderTicketCount;
      if (menuItem == "Transport schedule" && this.transportScheduleTicketCount != 0) return this.transportScheduleTicketCount;
      return;
    },
  },
  watch: {
    isSignedIn(signedIn: boolean) {
      // once user is signed in fetch latest ticket counts if they don't exist already
      if (signedIn && !store.ticketCount()) {
        store.setTicketCounts();
      }
    },
  },
});
