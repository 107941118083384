
import Vue from "vue";
import { iTicket } from "@/managers/tickets";
import MapViewer from "@/components/MapViewer.vue";

export default Vue.extend({
  name: "TicketMap",
  components: { MapViewer },
  props: {
    ticket: { type: Object as () => iTicket, required: true },
    startOpen: { type: Boolean, default: false },
  },
  data: () => ({
    expanded: false,
  }),
  computed: {},
  methods: {},
  beforeMount() {
    if (this.startOpen == true) {
      this.expanded = true;
    }
  },
});
