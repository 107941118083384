import router from "@/router";
import axios, { AxiosResponse, AxiosError } from "axios";
import { auth } from "./auth";
import { flash } from "./flashNotifications";

/*
    To use, in main.ts add:
    import axiosInterceptors from "./plugins/axiosInterceptors";
    Vue.use(axiosInterceptors);
*/

export default {
  install(Vue: any) {
    axios.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosError) => {
        // if (error.request?.status === 401) {
        //   flash.error("Please sign in to continue");
        //   auth.signOut()
        // }

        // Show error details to user
        // if (
        //   error.response?.data?.detail &&
        //   typeof error.response.data.detail === "string"
        // ) {
        //   flash.error(error.response.data.detail);
        // }
        return Promise.reject(error);
      }
    );
  },
};
