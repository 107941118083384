
import Vue from "vue";
import { flash, iNotification, NotificationLevel } from "@/plugins/flashNotifications";

export default Vue.extend({
  name: "Notifications",
  computed: {
    notifications(): iNotification[] {
      return flash.getAll();
    },
  },
  methods: {
    icon(notification: iNotification): string {
      switch (notification.level) {
        case NotificationLevel.INFO:
          return "info";
        case NotificationLevel.SUCCESS:
          return "check_circle";
        case NotificationLevel.WARNING:
          return "warning";
        case NotificationLevel.ERROR:
          return "error";
        default:
          return "";
      }
    },
    close(notification: iNotification) {
      flash.clear(notification);
    },
  },
});
