// Source for all settings and environmental variables
// All env vars are still public and are shipped to the client in the js

interface ISettings {
  wsServerHost: string;
}

export const settings: ISettings = {
  wsServerHost: process.env.NODE_ENV === 'development' ? 'ws://localhost/' : 'wss://bureau.dataq.co.za/'
};
