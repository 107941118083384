import { render, staticRenderFns } from "./TicketNoteAdder.vue?vue&type=template&id=0a73d659&scoped=true&"
import script from "./TicketNoteAdder.vue?vue&type=script&lang=ts&"
export * from "./TicketNoteAdder.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a73d659",
  null
  
)

export default component.exports