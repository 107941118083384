
import Vue from "vue";
import { mgr } from "@/managers";
import { iTicket } from "@/managers/tickets";
import { iSopStep } from "@/managers/sop";
import { ActionType } from "@/managers/ticketActions";

export default Vue.extend({
  name: "Resolve",
  components: {},
  props: {
    step: { type: Object as () => iSopStep, required: true },
    ticket: { type: Object as () => iTicket, required: true },
    outstandingSteps: { type: Array as () => string[], required: true },
  },
  data: () => ({}),
  computed: {
    actionTypes(): ActionType[] {
      return this.ticket.actions.map((x) => x.actionType);
    },
    isResolved(): boolean {
      if (this.actionTypes.includes(ActionType.CLOSED)) return true;
      if (this.actionTypes.includes(ActionType.RESOLVED)) return true;
      return false;
    },
    canResolve(): boolean {
      return this.outstandingSteps.length === 0;
    },
  },
  methods: {
    resolveTicket() {
      if (this.isResolved || !this.canResolve) return;

      mgr.ticketActions.resolveTicket(this.ticket.id)?.then(() => {
        // Navigate back to inbox you came from once you have resolved the ticket
        const matches = /([\/a-z-]*)\/\d*/.exec(this.$router.currentRoute.fullPath);
        const root = matches ? matches[1] : undefined;
        if (root) this.$router.replace(root);
      });
    },
    updateStep() {
      if (this.isResolved) {
        this.$emit("updateStep", { step: this.step, resolved: true });
      } else {
        this.$emit("updateStep", { step: this.step, resolved: false });
      }
    },
  },
  watch: {
    actionTypes() {
      this.updateStep();
    },
  },
  mounted() {
    this.updateStep();
  },
});
