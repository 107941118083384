import { Store } from "vuex";
import { VueConstructor } from "vue/types/umd";
import clonedeep from "lodash.clonedeep";

/*
  A central way to store and access simple key value pairs.
  The the value has and id use the data store instead
*/


export enum kvStoreKeys {
  // Global stat
  loading = "loading",
  timeAgoBaseDate = "timeAgoBaseDate",
}


interface iKvStoreState {
  // Global stat
  loading: boolean,
  timeAgoBaseDate: Date,
}

let STORE = {} as Store<any>; // The vuex store instance after installation

export default {
  install(Vue: VueConstructor<Vue>, { store }: { store: Store<any> }) {
    if (!store) throw new Error("Please provide vuex store.");
    STORE = store;

    const state: iKvStoreState = {
      // Global stat
      loading: false,
      timeAgoBaseDate: new Date(),
    };

    const mutations = {
      _setKvValue(state: iKvStoreState, { key, value }: { key: kvStoreKeys; value: any }) { state[key] = clonedeep(value) },
    };

    store.registerModule("keyValueStore", { state, mutations });
  },
};

export const kvStore = {
  // Base functions
  set(key: kvStoreKeys, value: any) { STORE.commit("_setKvValue", { key, value }) },
  get(key: kvStoreKeys) { return STORE.state.keyValueStore[key] },

  // Custom helpers
  loading: {
    show() {
      kvStore.set(kvStoreKeys.loading, true);
      window.setTimeout(() => {
        kvStore.set(kvStoreKeys.loading, false);
      }, 10 * 1000) // 10 seconds max
    },
    hide() { kvStore.set(kvStoreKeys.loading, false) },
    isLoading() { return kvStore.get(kvStoreKeys.loading) as boolean },
  },
}