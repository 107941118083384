
import Vue from "vue";
import { mgr } from "@/managers";
import { iTicket } from "@/managers/tickets";
import { iSopStep } from "@/managers/sop";
import { ActionType } from "@/managers/ticketActions";
import ConfirmationCard from "@/components/ConfirmationCard.vue";
import { sopContacts } from "@/managers/sop";

export default Vue.extend({
  name: "ConfirmDriverSafe",
  components: { ConfirmationCard },
  props: {
    step: { type: Object as () => iSopStep, required: true },
    ticket: { type: Object as () => iTicket, required: true },
    driverAtRisk: { type: Boolean, required: true },
  },
  data: () => ({
    driverStatusConfirmed: false,
    showConfirmSafeDialog: false,
    showConfirmDangerDialog: false,
    contactedRiskManager: false,
    contactedRiskController: false,
    contactedTransportManager: false,
    contactedTracker: false,
  }),
  computed: {
    actionTypes(): ActionType[] {
      return this.ticket.actions.map((x) => x.actionType);
    },
    isResolved(): boolean {
      if (this.actionTypes.includes(ActionType.DRIVER_SAFE)) return true;
      if (this.actionTypes.includes(ActionType.DRIVER_AT_RISK)) {
        let contactedRiskManager = false;
        let contactedRiskController = false;
        let contactedTransportManager = false;
        this.ticket.actions.forEach((action) => {
          if (action.actionType !== ActionType.CONTACT_CONFIRMED) return;
          if (action.data.person == sopContacts.RISK_MANAGER) contactedRiskManager = true;
          if (action.data.person == sopContacts.RISK_CONTROLLER) contactedRiskController = true;
          if (action.data.person == sopContacts.TRANSPORT_MANAGER) contactedTransportManager = true;
        });

        if (contactedRiskManager && contactedRiskController && contactedTransportManager) return true;
        return false;
      }
      return false;
    },
  },
  methods: {
    openSafeConfirmation() {
      if (this.isResolved) return;
      this.showConfirmSafeDialog = true;
    },
    openDangerConfirmation() {
      if (this.isResolved) return;
      this.showConfirmDangerDialog = true;
    },
    confirmDriverSafe() {
      mgr.ticketActions.driverSafe(this.ticket.id);
      this.driverStatusConfirmed = true;
      this.showConfirmSafeDialog = false;
      this.$emit("driverAtRisk", false);
    },
    confirmDriverAtRisk() {
      this.driverStatusConfirmed = true;
      mgr.ticketActions.driverAtRisk(this.ticket.id);
      this.showConfirmDangerDialog = false;
      this.$emit("driverAtRisk", true);
    },
    contactRiskManager() {
      if (this.contactedRiskManager === true) return;
      mgr.ticketActions.contactConfirmed(this.ticket.id, sopContacts.RISK_MANAGER);
      this.contactedRiskManager = true;
    },
    contactRiskController() {
      if (this.contactedRiskController === true) return;
      mgr.ticketActions.contactConfirmed(this.ticket.id, sopContacts.RISK_CONTROLLER);
      this.contactedRiskController = true;
    },
    contactTransportManager() {
      if (this.contactedTransportManager === true) return;
      mgr.ticketActions.contactConfirmed(this.ticket.id, sopContacts.TRANSPORT_MANAGER);
      this.contactedTransportManager = true;
    },
    contactTracker() {
      if (this.contactedTracker === true) return;
      mgr.ticketActions.contactConfirmed(this.ticket.id, sopContacts.TRACKER);
      this.contactedTracker = true;
    },
    updateStep() {
      this.driverStatusConfirmed = false;
      this.contactedRiskManager = false;
      this.contactedRiskController = false;
      this.contactedTransportManager = false;
      this.contactedTracker = false;

      if (this.actionTypes.includes(ActionType.DRIVER_SAFE)) {
        this.driverStatusConfirmed = true;
        this.$emit("updateStep", { step: this.step, resolved: true });
      } else if (this.actionTypes.includes(ActionType.DRIVER_AT_RISK)) {
        this.$emit("driverAtRisk", true);
        this.driverStatusConfirmed = true;

        // Set all contacted based on CONTACT_CONFIRMED
        this.ticket.actions.forEach((action) => {
          if (action.actionType !== ActionType.CONTACT_CONFIRMED) return;
          if (action.data.person == sopContacts.RISK_MANAGER) this.contactedRiskManager = true;
          if (action.data.person == sopContacts.RISK_CONTROLLER) this.contactedRiskController = true;
          if (action.data.person == sopContacts.TRANSPORT_MANAGER) this.contactedTransportManager = true;
          if (action.data.person == sopContacts.TRACKER) this.contactedTracker = true;
        });

        if (!this.contactedRiskManager) {
          this.$emit("updateStep", {
            step: this.step,
            resolved: false,
            unResolvedReason: "Risk manager has not been contacted",
          });
          return;
        }

        if (!this.contactedRiskController) {
          this.$emit("updateStep", {
            step: this.step,
            resolved: false,
            unResolvedReason: "Risk controller has not been contacted",
          });
          return;
        }

        if (!this.contactedTransportManager) {
          this.$emit("updateStep", {
            step: this.step,
            resolved: false,
            unResolvedReason: "Transport manager has not been contacted",
          });
          return;
        }

        if (this.contactedRiskManager && this.contactedRiskController && this.contactedTransportManager) {
          this.$emit("updateStep", {
            step: this.step,
            resolved: true,
          });
          return;
        }
      } else {
        this.$emit("updateStep", {
          step: this.step,
          resolved: false,
          unResolvedReason: "Driver not confirmed to be safe or not",
        });
        this.$emit("driverAtRisk", false);
      }
    },
  },
  watch: {
    actionTypes() {
      this.updateStep();
    },
    driverAtRisk() {
      this.updateStep();
    },
  },
  mounted() {
    this.updateStep();
  },
});
