
import Vue from "vue";

export default Vue.extend({
  name: "ConfirmationCard",
  props: {
    title: { type: String, default: "Confirm delete" },
    text: { type: String, default: "Are you sure you want to permanently delete this?" },
    btnText: { type: String, default: "Permanently Delete" },
    positive: { type: Boolean, default: false },
    item: { required: false },
  },
});
