
import Vue from "vue";
import { mgr } from "@/managers";
import { iTicket } from "@/managers/tickets";
import { iSopStep } from "@/managers/sop";
import { ActionType } from "@/managers/ticketActions";

export default Vue.extend({
  name: "ContactStore",
  components: {},
  props: {
    step: { type: Object as () => iSopStep, required: true },
    ticket: { type: Object as () => iTicket, required: true },
    index: { type: Number, required: true },
  },
  data: () => ({
    successfulContact: false,
    failedContact: false,
    didNotContact: false,
    showCanNotContactStoreDialog: false,
    attemptsFormValid: false,
    contactPersonIsValid: false,
    contactAttempts: 0,
    contactComment: "",
    storeContact: "",
    storeContactPosition: "",
  }),
  computed: {
    actionTypes(): ActionType[] {
      return this.ticket.actions.map((x) => x.actionType);
    },
    isResolved(): boolean {
      return (
        this.actionTypes.includes(ActionType.STORE_CONTACTED) ||
        this.actionTypes.includes(ActionType.STORE_UNCONTACTABLE) ||
        this.actionTypes.includes(ActionType.STORE_NOT_CONTACTED)
      );
    },
    canCheckContacted(): boolean {
      return this.storeContact.length > 0 && this.storeContactPosition.length > 0;
    },
    canSubmit(): boolean {
      if (this.isResolved) return false;

      return this.successfulContact || this.failedContact || this.didNotContact;
    },
  },
  methods: {
    openAttemptsDialog() {
      if (this.isResolved) return;
      this.successfulContact = false;
      this.didNotContact = false;
      if (this.failedContact) this.showCanNotContactStoreDialog = true;
    },
    closeAttemptsDialog() {
      this.showCanNotContactStoreDialog = false;
      (this.$refs["attempts-form"] as any).resetValidation();
    },
    confirmStoreContacted() {
      if (this.isResolved) return;
      this.failedContact = false;
      this.didNotContact = false;
    },
    couldNotContactStore() {
      if (this.isResolved) return;
      if (!(this.$refs["attempts-form"] as any).validate()) return;
      this.closeAttemptsDialog();
    },
    confirmDidNotContactStore() {
      if (this.isResolved) return;
      this.successfulContact = false;
      this.failedContact = false;
    },
    updateStep() {
      this.successfulContact = false;
      this.failedContact = false;
      this.contactAttempts = 0;
      this.contactComment = "";
      this.storeContact = "";
      this.storeContactPosition = "";

      if (this.actionTypes.includes(ActionType.STORE_CONTACTED)) {
        this.$emit("updateStep", { step: this.step, resolved: true, unResolvedReason: false });
        this.successfulContact = true;
        this.failedContact = false;
        const data: any = this.ticket.actions.find((x) => x.actionType == ActionType.STORE_CONTACTED)?.data;
        this.storeContact = data.store_contact || "";
        this.storeContactPosition = data.store_contact_position || "";
        if (this.step.addOnSuccess) this.$emit("addStep", { step: this.step.addOnSuccess, index: this.index });
      } else if (this.actionTypes.includes(ActionType.STORE_UNCONTACTABLE)) {
        this.$emit("updateStep", { step: this.step, resolved: true, unResolvedReason: false });
        this.successfulContact = false;
        this.failedContact = true;
        const data: any = this.ticket.actions.find((x) => x.actionType == ActionType.STORE_UNCONTACTABLE)?.data;
        this.storeContact = data.store_contact || "";
        this.storeContactPosition = data.store_contact_position || "";
        if (this.step.addOnFail) this.$emit("addStep", { step: this.step.addOnFail, index: this.index });
      } else if (this.actionTypes.includes(ActionType.STORE_NOT_CONTACTED)) {
        this.$emit("updateStep", { step: this.step, resolved: true, unResolvedReason: false });
        this.successfulContact = false;
        this.failedContact = false;
        this.didNotContact = true;
        if (this.step.addOnFail) this.$emit("addStep", { step: this.step.addOnFail, index: this.index });
      } else {
        this.$emit("updateStep", { step: this.step, resolved: false, unResolvedReason: "Store must be contacted" });
      }
    },
    confirmStoreContact() {
      if (this.isResolved) return;
      if (this.successfulContact)
        mgr.ticketActions.storeContacted(this.ticket.id, this.storeContact, this.storeContactPosition);

      if (this.failedContact)
        mgr.ticketActions.storeUncontactable(
          this.ticket.id,
          this.storeContact,
          this.storeContactPosition,
          this.contactAttempts,
          this.contactComment
        );

      if (this.didNotContact) mgr.ticketActions.storeNotContacted(this.ticket.id);
    },
  },
  watch: {
    actionTypes() {
      this.updateStep();
    },
  },
  mounted() {
    this.updateStep();
  },
});
