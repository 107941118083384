
import Vue from "vue";
import { auth } from "@/plugins/auth";
import router from "@/router";

import EmailInput from "@/components/inputs/EmailInput.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import { Role } from "@/managers/roles";

export default Vue.extend({
  name: "SignIn",
  components: { EmailInput, PasswordInput },
  props: {},
  data: () => ({
    email: "",
    password: "",
    formIsValid: false,
  }),
  computed: {},
  methods: {
    signIn() {
      if (!(this.$refs["form"] as any).validate()) return;
      auth
        .signIn(this.email, this.password)
        .then((user) => {
          if (user.roles.map((x) => x.name).includes(Role.ADMIN)) router.replace("/dashboard");
          else if (user.roles.map((x) => x.name).includes(Role.TELEMETRY_PROVIDER))
            router.replace("/telemetry-provider-tickets");
          else if (user.roles.map((x) => x.name).includes(Role.DASHBOARD_VIEWER)) router.replace("/dashboard");
          else router.replace("/inbox");
        })
        .catch((err) => {});
    },
  },
  beforeMount() {},
});
