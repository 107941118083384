
import Vue from "vue";
import { mgr } from "@/managers";
import { iTicket } from "@/managers/tickets";
import { iSopStep } from "@/managers/sop";
import { ActionType } from "@/managers/ticketActions";

export default Vue.extend({
  name: "ContactDriver",
  components: {},
  props: {
    step: { type: Object as () => iSopStep, required: true },
    ticket: { type: Object as () => iTicket, required: true },
    driverAtRisk: { type: Boolean, required: true },
    index: { type: Number, required: true },
  },
  data: () => ({
    successfulContact: false,
    failedContact: false,
    showCanNotContactDriverDialog: false,
    attemptsFormValid: false,
    diverContactAttempts: 0,
    diverContactComment: "",
  }),
  computed: {
    actionTypes(): ActionType[] {
      return this.ticket.actions.map((x) => x.actionType);
    },
    isResolved(): boolean {
      return (
        this.actionTypes.includes(ActionType.DRIVER_CONTACTED) ||
        this.actionTypes.includes(ActionType.DRIVER_UNCONTACTABLE)
      );
    },
  },
  methods: {
    openAttemptsDialog() {
      if (this.isResolved) return;
      if (!this.failedContact) this.showCanNotContactDriverDialog = true;
    },
    closeAttemptsDialog() {
      this.showCanNotContactDriverDialog = false;
      (this.$refs["attempts-form"] as any).resetValidation();
    },
    confirmDriverContacted() {
      if (this.isResolved) return;
      mgr.ticketActions.driverContacted(this.ticket.id);
    },
    couldNotContactDriver() {
      if (this.isResolved) return;
      if (!(this.$refs["attempts-form"] as any).validate()) return;
      this.closeAttemptsDialog();
      mgr.ticketActions.driverUncontactable(this.ticket.id, this.diverContactAttempts, this.diverContactComment);
    },
    updateStep() {
      this.successfulContact = false;
      this.failedContact = false;
      this.diverContactAttempts = 0;
      this.diverContactComment = "";

      if (this.driverAtRisk) this.$emit("updateStep", { step: this.step, required: false, unResolvedReason: false });

      if (this.actionTypes.includes(ActionType.DRIVER_CONTACTED)) {
        this.$emit("updateStep", { step: this.step, resolved: true, unResolvedReason: false });
        this.successfulContact = true;
        this.failedContact = false;
      } else if (this.actionTypes.includes(ActionType.DRIVER_UNCONTACTABLE)) {
        this.$emit("updateStep", { step: this.step, resolved: true, unResolvedReason: false });
        this.successfulContact = false;
        this.failedContact = true;
        if (this.step.addOnFail) this.$emit("addStep", { step: this.step.addOnFail, index: this.index });
      } else {
        this.$emit("updateStep", { step: this.step, resolved: false, unResolvedReason: "Driver must be contacted" });
      }
    },
  },
  watch: {
    actionTypes() {
      this.updateStep();
    },
    driverAtRisk() {
      this.updateStep();
    },
  },
  mounted() {
    this.updateStep();
  },
});
