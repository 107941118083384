
import Vue from "vue";
import clonedeep from "lodash.clonedeep";
import { componentNames, getSop, iSop, iSopStep, SopType } from "@/managers/sop";
import { iTicket } from "@/managers/tickets";
import { TicketFlag } from "@/managers/ticketActions";

import MarkAsSeen from "@/components/sops/MarkAsSeen.vue";
import CheckMap from "@/components/sops/CheckMap.vue";
import CheckVideo from "@/components/sops/CheckVideo.vue";
import ContactDriver from "@/components/sops/ContactDriver.vue";
import ContactOthers from "@/components/sops/ContactOthers.vue";
import ContactStore from "@/components/sops/ContactStore.vue";
import ConfirmDriverSafe from "@/components/sops/ConfirmDriverSafe.vue";
import Reason from "@/components/sops/Reason.vue";
import Description from "@/components/sops/Description.vue";
import SetFlags from "@/components/sops/SetFlags.vue";
import ConfirmDeparture from "@/components/sops/ConfirmDeparture.vue";
import ConfirmDelayEnded from "@/components/sops/ConfirmDelayEnded.vue";
import ConfirmCommunicationRestored from "@/components/sops/ConfirmCommunicationRestored.vue";
import ConfirmBatterReconnected from "@/components/sops/ConfirmBatterReconnected.vue";
import Resolve from "@/components/sops/Resolve.vue";
import CheckCustomerHistory from "@/components/sops/CheckCustomerHistory.vue";
import CheckDriverHistory from "@/components/sops/CheckDriverHistory.vue";
import CheckMaintenanceHistory from "@/components/sops/CheckMaintenanceHistory.vue";
import CheckTelemetryHistory from "@/components/sops/CheckTelemetryHistory.vue";
import CheckSchedulingHistory from "@/components/sops/CheckSchedulingHistory.vue";
import OngoingInvestigation from "@/components/sops/OngoingInvestigation.vue";
import ReassignFlag from "@/components/sops/ReassignFlag.vue";
import CloseTicket from "@/components/sops/CloseTicket.vue";
import JobCardNumber from "@/components/sops/JobCardNumber.vue";
import CompleteRequiredFields from "@/components/sops/CompleteRequiredFields.vue";
import ConfirmEventReportSent from "@/components/sops/ConfirmEventReportSent.vue";

interface iUpdateOptions {
  step: iSopStep;
  resolved?: boolean;
  required?: boolean;
  unResolvedReason?: string | false;
}

export default Vue.extend({
  name: "SopManager",
  components: {
    MarkAsSeen,
    CheckMap,
    CheckVideo,
    ContactDriver,
    ContactOthers,
    ContactStore,
    ConfirmDriverSafe,
    Reason,
    Description,
    SetFlags,
    ConfirmDeparture,
    ConfirmDelayEnded,
    ConfirmCommunicationRestored,
    ConfirmBatterReconnected,
    Resolve,
    CloseTicket,
    CheckCustomerHistory,
    CheckDriverHistory,
    CheckMaintenanceHistory,
    CheckTelemetryHistory,
    CheckSchedulingHistory,
    OngoingInvestigation,
    ReassignFlag,
    JobCardNumber,
    CompleteRequiredFields,
    ConfirmEventReportSent,
  },
  props: {
    ticket: { type: Object as () => iTicket, required: true },
    sopType: { type: String as () => SopType, required: true },
    startClosed: { type: Boolean, default: false },
  },
  data: () => ({
    driverAtRisk: false,
    specifiedFlags: [] as TicketFlag[],
    expanded: true,
    sop: {} as iSop, // Set in beforeMount
  }),
  computed: {
    outstandingSteps(): string[] {
      const reasons = this.sop.steps
        .filter((x) => x.required === true && x.resolved !== true)
        .map((x) => x.unResolvedReason || x.component);
      return reasons.filter((x) => x !== "Resolve");
    },
    canResolve(): boolean {
      return this.sop.steps.filter((x) => x.required && x.resolved === false).length == 0;
    },
  },
  methods: {
    updateStep({ step, resolved, required, unResolvedReason }: iUpdateOptions) {
      if (resolved !== undefined) Vue.set(step, "resolved", resolved);
      if (required !== undefined) Vue.set(step, "required", required);
      if (typeof unResolvedReason === "string") Vue.set(step, "unResolvedReason", unResolvedReason);
      if (unResolvedReason === false) Vue.set(step, "unResolvedReason", undefined);
    },
    setDriverAtRisk(atRisk: boolean) {
      this.driverAtRisk = atRisk;
    },
    addStep({ step, index }: { step: iSopStep; index: number }) {
      // Don't add duplicates
      if (this.sop.steps[index + 1].component === step.component) return;
      const updatedSteps = [...this.sop.steps];
      updatedSteps.splice(index + 1, 0, step);
      this.sop.steps = updatedSteps;
    },
    removeStep(componentName: componentNames) {
      const index = this.sop.steps.findIndex((x) => x.component === componentName);
      if (index >= 0) Vue.delete(this.sop.steps, index);
    },
    setSpecifiedFlags(flags: TicketFlag[]) {
      this.specifiedFlags = flags;
    },
  },
  beforeMount() {
    this.sop = clonedeep(getSop(this.ticket.alertType, this.sopType));
    if (this.startClosed == true) this.expanded = false;
  },
});
