
import Vue from "vue";
import LayoutTicket from "@/components/LayoutTicket.vue";
import { SopType } from "@/managers/sop";

export default Vue.extend({
  name: "Ticket",
  components: { LayoutTicket },
  computed: {
    sopType() {
      return SopType.INBOX;
    },
  },
});
