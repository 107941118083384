import { render, staticRenderFns } from "./AlertTypeChip.vue?vue&type=template&id=16a49366&scoped=true&"
import script from "./AlertTypeChip.vue?vue&type=script&lang=ts&"
export * from "./AlertTypeChip.vue?vue&type=script&lang=ts&"
import style0 from "./AlertTypeChip.vue?vue&type=style&index=0&id=16a49366&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16a49366",
  null
  
)

export default component.exports