
import Vue from "vue";
import { mgr } from "@/managers";
import { iTicket } from "@/managers/tickets";
import { iSopContact, iSopStep } from "@/managers/sop";
import { ActionType } from "@/managers/ticketActions";
import { sopContacts } from "@/managers/sop";

export default Vue.extend({
  name: "ContactOthers",
  components: {},
  props: {
    step: { type: Object as () => iSopStep, required: true },
    ticket: { type: Object as () => iTicket, required: true },
  },
  data: () => ({
    contacted: {} as Record<string, boolean>,
  }),
  computed: {
    actionTypes(): ActionType[] {
      return this.ticket.actions.map((x) => x.actionType);
    },
    requiredContacts(): iSopContact[] {
      return this.step.toContact?.filter((x) => x.required === true) ?? [];
    },
    isResolved(): boolean {
      // All required contacts have been contacted
      // const contacted = Object.keys(this.contacted).filter((key) => this.contacted[key] === true);
      // return contacted.length === this.requiredContacts.length;
      const unContacted = this.requiredContacts.filter((x) => this.contacted[x.person] !== true).map((x) => x.person);
      return unContacted.length === 0;
    },
  },
  methods: {
    confirmContact(contact: iSopContact) {
      if (this.isResolved) return;
      if (this.contacted[contact.person] === true) return;
      mgr.ticketActions.contactConfirmed(this.ticket.id, contact.person);
    },
    updateStep() {
      if (this.requiredContacts.length == 0) {
        this.$emit("updateStep", { step: this.step, resolved: true });
        this.contacted = {} as any;
      }

      this.ticket.actions.forEach((action) => {
        if (action.actionType !== ActionType.CONTACT_CONFIRMED) return;
        const person = action.data.person as sopContacts;
        this.contacted = { ...this.contacted, ...{ [person]: true } };
      });

      const unContacted = this.requiredContacts.filter((x) => this.contacted[x.person] !== true).map((x) => x.person);

      if (unContacted.length == 0) {
        this.$emit("updateStep", { step: this.step, resolved: true });
      } else {
        this.$emit("updateStep", {
          step: this.step,
          resolved: false,
          unResolvedReason: `The following people have not been contacted: ${unContacted.join(", ")}`,
        });
      }
    },
  },
  watch: {
    actionTypes() {
      this.updateStep();
    },
  },
  mounted() {
    this.updateStep();
  },
});
