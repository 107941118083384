
import Vue from "vue";
import { mgr } from "@/managers";
import { iTicket } from "@/managers/tickets";
import { iSopStep } from "@/managers/sop";
import { ActionType } from "@/managers/ticketActions";
import { flash } from "@/plugins/flashNotifications";

export default Vue.extend({
  name: "ProvideDescription",
  components: {},
  props: {
    step: { type: Object as () => iSopStep, required: true },
    ticket: { type: Object as () => iTicket, required: true },
  },
  data: () => ({
    incidentDescription: "",
  }),
  computed: {
    actionTypes(): ActionType[] {
      return this.ticket.actions.map((x) => x.actionType);
    },
    isResolved(): boolean {
      return this.actionTypes.includes(ActionType.DESCRIPTION_ADDED);
    },
  },
  methods: {
    submitDescription() {
      if (this.isResolved) return;
      if (this.incidentDescription.length < 10) {
        flash.warning("You need to add an incident description that is longer than 10 characters");
        return;
      }
      // this.$emit("setDescription", this.incidentDescription);
      mgr.ticketActions.descriptionAdded(this.ticket.id, this.incidentDescription);
    },
    updateStep() {
      if (this.isResolved) {
        this.$emit("updateStep", { step: this.step, resolved: true });
        this.incidentDescription =
          this.ticket.actions.find((x) => x.actionType == ActionType.DESCRIPTION_ADDED)?.data.description ?? "";
      } else {
        this.$emit("updateStep", {
          step: this.step,
          resolved: false,
          unResolvedReason: "Incident description not added yet",
        });
      }
    },
  },
  watch: {
    actionTypes() {
      this.updateStep();
    },
  },
  mounted() {
    this.updateStep();
  },
});
