
import Vue from "vue";
import TicketHeader from "@/components/TicketHeader.vue";
import { mgr } from "@/managers";
import TimeMixin from "@/mixins/TimeMixin";
import DisplayMixin from "@/mixins/DisplayMixin";
import { AlertLevel, iTicket } from "@/managers/tickets";
import { auth } from "@/plugins/auth";
import { flash } from "@/plugins/flashNotifications";
import TicketInfoBar from "@/components/TicketInfoBar.vue";
import TicketHistory from "@/components/TicketHistory.vue";
import TicketNoteAdder from "@/components/TicketNoteAdder.vue";
import TicketMap from "@/components/TicketMap.vue";
import SopManager from "@/components/sops/SopManager.vue";
import RelatedTickets from "@/components/RelatedTickets.vue";

export default Vue.extend({
  name: "InboxTicket",
  mixins: [TimeMixin, DisplayMixin],
  components: { TicketInfoBar, TicketHistory, TicketNoteAdder, TicketMap, SopManager, TicketHeader, RelatedTickets },
  props: {},
  data: () => ({
    ticketId: null as null | number,
    loading: true,
  }),
  computed: {
    ticket(): iTicket | null {
      if (this.ticketId) return mgr.tickets.getById(this.ticketId);
      else return null;
    },
    isAssignedToMe() {
      const ticket = this.ticket as iTicket | null;
      if (!ticket) return false;
      return ticket.assignedUser?.id == auth.currentUser()?.id;
    },
    isFreeToClaim() {
      const ticket = this.ticket as iTicket | null;
      if (!ticket) return false;
      return ticket.assignedUser == null;
    },
  },
  methods: {
    alertLevelColor(level: AlertLevel): String {
      return "ticket-" + level.toLowerCase();
    },
    claimTicket() {
      const ticket = this.ticket as iTicket | null;
      const user = auth.currentUser();
      if (!ticket || !user) return;
      flash.info("Assigning tickets to yourself...");
      mgr.ticketActions.claimBy(ticket.id)?.then(() => {
        flash.success("Ticket assigned to you");
      });
    },
  },
  watch: {
    $route(to) {
      const id = parseInt(to.params?.id || "-1");
      this.ticketId = id;
      mgr.tickets.fetchForIdIfEmpty(id);
    },
    ticket(ticket: iTicket | null) {
      if (ticket) this.loading = false;
    },
  },
  beforeMount() {
    this.ticketId = mgr.tickets.fetchByIdFromRouteParams();
  },
});
