// import '@mdi/font/css/materialdesignicons.css'
// import Vuetify from 'vuetify/lib/framework';
import Vue from 'vue';
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: "#383838",
        secondary: "#673ab7",
        accent: "#009688",
        error: "#ff5722",
        warning: "#ff9800",
        info: "#2196f3",
        success: "#8bc34a"
      },
      // light: {
      //   primary: "#9c27b0",
      //   secondary: "#673ab7",
      //   accent: "#009688",
      //   error: "#ff5722",
      //   warning: "#ff9800",
      //   info: "#2196f3",
      //   success: "#8bc34a"
      // },
    },
  },
});
