import { baseTransformInbound, canCreate, canFetch, canGetAll, canUpdate } from "./_baseManager";
import { StoreKey, store } from "@/plugins/dataStore";
import axios from "axios"
import { iRole, Role } from "./roles";

export interface iApikey {
  id: number;
  userId: number;
  roles: iRole[];
  active: boolean;
  createdUtc: string;
  lastSeenUtc: string;
}

export interface iApikeyCreate {
  userId: number;
  roles: Role[];
}

export interface iApikeyUpdate {
  roles?: Role[];
  active?: boolean;
}

export const apikeys = function () {
  const storeKey = StoreKey.APIKEYS;
  const baseUrl = "/app/apikeys";

  return {
    storeKey: storeKey,
    url: baseUrl,
    ...canCreate<iApikey, iApikeyCreate>(storeKey, baseUrl),
    ...canUpdate<iApikey, iApikeyUpdate>(storeKey, baseUrl),
    ...canGetAll<iApikey>(storeKey),
    ...canFetch<iApikey>(storeKey, baseUrl),
    createForSelf() {
      return axios.post("/app/apikeys/self").then((res) => {
        return { key: res.data.key, apikey: baseTransformInbound<iApikey>(res.data.apikey) }
      });
    },
    invalidate(apikeyId: number) {
      return axios.patch(`/app/apikeys/invalidate/${apikeyId}`)
    },
    invalidateForSelf() {
      return axios.patch("/app/apikeys/invalidate")
    },
    refresh(apikeyId: number) {
      return axios.patch(`/app/apikeys/refresh/${apikeyId}`).then((res) => {
        if (!res) return ""
        const apikey = baseTransformInbound<iApikey>(res.data.apikey)
        store.upsertItems(storeKey, [baseTransformInbound<iApikey>(res.data.apikey)]);
        return res.data.key
      });;
    },
    getCurrentApikey(): Promise<iApikey | null> {
      return axios
        .get("/app/auth/apikey")
        .then((res) => {
          return baseTransformInbound<iApikey>(res.data)
        })
    }
  } as const
}()