
import { iTicket } from "@/managers/tickets";
import Vue from "vue";
import TimeMixin from "@/mixins/TimeMixin";
import DisplayMixin from "@/mixins/DisplayMixin";
import router from "@/router";
import TicketsMultiMap from "@/components/TicketsMultiMap.vue";
import TicketTable from "@/components/TicketTable.vue";
import { FilterSetKey } from "@/plugins/dataStore";
import { mgr } from "@/managers";

export default Vue.extend({
  name: "LayoutTicketList",
  mixins: [TimeMixin, DisplayMixin],
  components: { TicketsMultiMap, TicketTable },
  props: {
    title: { type: String, default: "Tickets" },
    navPath: { type: String, default: "/tickets" },
    filterSetKey: { type: String as () => FilterSetKey, default: FilterSetKey.VIEWER_TICKETS },
    searchDefaults: { type: Object, default: () => {} },
    optionsDefaults: {
      type: Object,
      default: () => {
        return { sortBy: ["createdUtc"], sortDesc: [true] };
      },
    },
    hideSearch: { type: Array as () => string[], default: () => [] },
    hideHeaders: { type: Array as () => string[], default: () => [] },
  },
  data: () => ({}),
  computed: {
    tickets(): iTicket[] {
      return mgr.tickets.getFilterSet(this.filterSetKey)?.items || [];
    },
  },
  methods: {
    openTicket(ticket: iTicket) {
      router.replace(`${this.navPath}/${ticket.id}`);
    },
  },
  beforeMount() {},
});
