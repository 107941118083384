import { apikeys } from "./apikeys"
import { notifications } from "./notifications"
import { roles } from "./roles"
import { tickets } from "./tickets"
import { ticketActions } from "./ticketActions"
import { users } from "./users"


export const mgr = {
  apikeys,
  notifications,
  roles,
  tickets,
  ticketActions,
  users,
}