import Vue from "vue";

export default Vue.extend({
  methods: {
    ruleSet(presetName: string, required = true): any[] {
      return validation.ruleSet(presetName, required)
    }
  },
});


export const validation = {
  validationRules() {
    return {
      notEmpty: (v: any) => !!v || "Must not be empty",
      float: (v: any) => !isNaN(v || "") || "Must be a valid number",
      int: (v: any) => v === "" || Number.isInteger(parseFloat(v || "")) || "Must be an integer",
      positive: (v: any) => v === "" || parseFloat(v || "") >= 0 || "Must be positive number",
      negative: (v: any) => v === "" || parseFloat(v || "") < 0 || "Must be negative number",
      greaterThanZero: (v: any) => v === "" || parseFloat(v) > 0 || "Must be greater than zero",
      email: (v: any) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      length3orMore: (v: any) => (v || "").toString().length >= 3 || "Must be at lease 3 characters long",
      length6orMore: (v: any) => (v || "").toString().length >= 8 || "Must be at lease 6 characters long",
      length8orMore: (v: any) => (v || "").toString().length >= 8 || "Must be at lease 8 characters long",
      length4only: (v: any) => (v || "").toString().length === 4 || "Must be 4 characters long",
      containAt: (v: any) => String(v || "").includes("@") || "Must contain an '@' symbol",
      containStop: (v: any) => String(v || "").includes(".") || "Must contain an '.' symbol",
      notContainStop: (v: any) => !String(v || "").includes(".") || "Can not contain an '.' symbol",
      sixDecimalsPlaces: (v: any) => (String(v || "").includes(".") && String(v || "").split(".")[1].length >= 6) || "Must have at lease 6 decimal places",
      greaterThanNeg180: (v: any) => parseFloat(v) >= -180 || "Must be larger than -180 degrees",
      lessThanPos180: (v: any) => parseFloat(v) <= 180 || "Must be smaller than 180 degrees",
      greaterThanNeg90: (v: any) => parseFloat(v) >= -90 || "Must be larger than -90 degrees",
      lessThanPos90: (v: any) => parseFloat(v) <= 90 || "Must be smaller than 90 degrees",
      onlyUppercaseTextAndDigits: (v: any) => /^[A-Z0-9]*$/.test(v) || "Must contain only uppercase text or numbers",
      year: (v: any) => /\d{4}/.test(v) || "Must be a year",
      date: (v: any) => /\d{4}-\d{2}-\d{2}/.test(v) || "Must be a date",
      startWithPlus: (v: any) => /^\+/.test(v.replace(/\s/g, '')) || "Must start with '+'",
      phoneNumber: (v: any) => /(^\d\d{8}\d$)|(^\+\d{10}\d$)/.test(v.replace(/\s/g, '')) || "Must be a valid cellphone number",
    };
  },
  validationPresets(rulePresetName: string): any[] {
    switch (rulePresetName) {
      case "required":
        return [this.validationRules().notEmpty];
      case "float":
        return [this.validationRules().notEmpty, this.validationRules().float];
      case "positiveFloat":
        return [this.validationRules().notEmpty, this.validationRules().float, this.validationRules().positive];
      case "int":
        return [
          this.validationRules().notEmpty,
          this.validationRules().float,
          this.validationRules().int,
          this.validationRules().notContainStop,
        ];
      case "positiveInt":
        return [
          this.validationRules().notEmpty,
          this.validationRules().float,
          this.validationRules().int,
          this.validationRules().positive,
          this.validationRules().notContainStop,
        ];
      case "string":
        return [this.validationRules().notEmpty, this.validationRules().length3orMore];
      case "password":
        return [this.validationRules().notEmpty, this.validationRules().length8orMore];
      case "email":
        return [this.validationRules().notEmpty, this.validationRules().email];
      case "latitude":
        return [
          this.validationRules().notEmpty,
          this.validationRules().float,
          this.validationRules().containStop,
          this.validationRules().sixDecimalsPlaces,
          this.validationRules().lessThanPos90,
          this.validationRules().greaterThanNeg90,
        ];
      case "longitude":
        return [
          this.validationRules().notEmpty,
          this.validationRules().float,
          this.validationRules().containStop,
          this.validationRules().sixDecimalsPlaces,
          this.validationRules().lessThanPos180,
          this.validationRules().greaterThanNeg180,
        ];
      case "numberPlate":
        return [
          this.validationRules().notEmpty,
          this.validationRules().length6orMore,
          this.validationRules().onlyUppercaseTextAndDigits,
        ];
      case "year":
        return [
          this.validationRules().notEmpty,
          this.validationRules().year
        ];
      case "date":
        return [
          this.validationRules().notEmpty,
          this.validationRules().date,
        ];
      case "time":
        return [
          this.validationRules().notEmpty,
        ];
      case "phone":
        return [
          this.validationRules().notEmpty,
          this.validationRules().startWithPlus,
          this.validationRules().phoneNumber,
        ];
    }
    console.error(`Validation preset [${rulePresetName}] not found`);
    return [];
  },
  ruleSet(presetName: string, required = true): any[] {
    // Finds the matching rules from the presetName
    // Then flattens the rules array into one function
    const rules = this.validationPresets(presetName)
    const flatRule = (v: any) => {
      // Skip any further rules if empty and not required
      if (required == false && (v === "" || !v)) return true;
      // Check each rule
      for (const rule of rules) {
        const result = rule(v)
        if (typeof result === "string") return result
      }
      return true
    }
    return [flatRule]
  }
};
