import { render, staticRenderFns } from "./EventUpdater.vue?vue&type=template&id=cceb0ff8&scoped=true&"
import script from "./EventUpdater.vue?vue&type=script&lang=ts&"
export * from "./EventUpdater.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cceb0ff8",
  null
  
)

export default component.exports