
import Vue from "vue";
import ValidationMixin from "@/mixins/ValidationMixin";
import clonedeep from "lodash.clonedeep";

export default Vue.extend({
  name: "ItemUpdater",
  mixins: [ValidationMixin],
  components: {},
  props: {
    title: { type: String, default: "Edit" },
    item: { type: Object, required: true },
    saveBtnText: { type: String, default: "Save" },
  },
  data() {
    return {
      live: {},
      isValidForm: false,
    };
  },
  computed: {},
  methods: {
    close() {
      this.$emit("close");
      (this.$refs["update-form"] as any).resetValidation();
    },
    save() {
      if (!(this.$refs["update-form"] as any).validate()) return;
      // Break the reactivity chain
      const initial: any = Object.assign({}, this.item);
      const updated: any = Object.assign({}, this.live);

      // Swap empty strings to null
      Object.entries(updated).forEach(([key, value]) => {
        if (value === "") updated[key] = null;
      });

      this.$emit("save", { initial, updated, reset: this.reset });
    },
    reset() {
      (this.$refs["update-form"] as any).reset();
      this.live = {};
    },
    resetToOriginal() {
      (this.$refs["update-form"] as any).resetValidation();
      this.live = clonedeep(this.item);
    },
  },
  watch: {
    item(item) {
      this.live = clonedeep(this.item);
    },
  },
  beforeMount() {
    this.live = clonedeep(this.item);
  },
});
