import Vue from 'vue'
import clonedeep from "lodash.clonedeep";
import { Store } from "vuex";
import { VueConstructor } from "vue/types/umd";

import { iApikey } from '../managers/apikeys';
import { iTicket } from '../managers/tickets';
import { iRole } from "@/managers/roles";
import { iUser } from "@/managers/users"
import { iNotification } from './flashNotifications';

export enum StoreKey {
  APIKEYS = "apikeys",
  TICKETS = "tickets",
  ROLES = "roles",
  USERS = "users",
  NOTIFICATIONS = "notifications",
}
interface iDataStoreState {
  apikeys: Record<string, iApikey> | null;
  tickets: Record<string, iTicket> | null;
  roles: Record<string, iRole> | null;
  users: Record<string, iUser> | null;
  notifications: Record<string, iNotification> | null;
}


export interface iStorableItem {
  id: number;
}

export enum FilterSetKey {
  NEW_TICKETS = "newTickets",
  MY_TICKETS = "myTickets",
  IN_PROGRESS_TICKETS = "inProgressTickets",
  VIEWER_TICKETS = "viewerTickets",
  MAINTENANCE_TICKETS = "maintenanceTickets",
  CUSTOMER_CARE_TICKETS = "customerCapeTickets",
  DRIVER_DISCIPLINE_TICKETS = "driverDisciplineTickets",
  TELEMETRY_PROVIDER_TICKETS = "telemetryProviderTickets",
  TRANSPORT_SCHEDULE_TICKETS = "transportScheduleTickets"
}
interface iFilterSetState {
  newTickets: iFilterSet | null;
  myTickets: iFilterSet | null;
  inProgressTickets: iFilterSet | null;
  viewerTickets: iFilterSet | null;
  maintenanceTickets: iFilterSet | null;
  customerCapeTickets: iFilterSet | null;
  driverDisciplineTickets: iFilterSet | null;
  telemetryProviderTickets: iFilterSet | null;
  transportScheduleTickets: iFilterSet | null;
}


export type QueryParams = Record<string, undefined | null | string | string[] | number | number[] | boolean | boolean[]>
export interface iFilterSet {
  storeKey: StoreKey
  ids: number[];
  queryParams: QueryParams;
  total: number
}
export interface iFilterSetResult<T extends iStorableItem> extends iFilterSet {
  items: T[];
}
export interface PaginatedQueryParams extends QueryParams {
  skip?: number;
  limit?: number;
  order?: string;
  descending?: boolean;
}
function buildDataStore(store: Store<any>) {
  // Build initial state from StoreKey and set each value to null
  const state: iDataStoreState = Object.values(StoreKey).reduce((acc, key) => { acc[key] = null; return acc }, {} as any)

  // There are only 3 possible mutations: Upsert, Replace and Remove
  const mutations = {
    _dataStore_upsertItems(state: iDataStoreState, { items, key, merge }: { key: StoreKey; items: iStorableItem[]; merge: boolean }) {
      // Tries to find and update an existing item, if its cant find a match it adds the items
      // If 'merge=true` then the the items properties will be merged into the existing object instead of replacing it
      const record = state[key] ?? {};
      items.forEach((item) => {
        const id = item.id.toString()
        // If the merge flag is true then merge the existing and the new item, else just replace the existing item
        if (merge && record[id] !== undefined) item = Object.assign(record[id], item);
        Vue.set(record, id, clonedeep(item))
      });
      state[key] = record as any;
    },
    _dataStore_replaceItems(state: iDataStoreState, { items, key }: { key: StoreKey; items: iStorableItem[] }) {
      // Overwrites the state[key] with the items provided
      // Convert array to object with id as keys
      const record = items.reduce((acc, item) => (acc[item.id.toString()] = item, acc), {} as Record<string, any>);
      // Use deep clone to break any vue reactivity
      state[key] = clonedeep(record);
    },
    _dataStore_removeItems(state: iDataStoreState, { key, ids }: { key: StoreKey; ids: number[] }) {
      // Remove the items provided from state[key]
      const record = state[key];
      if (record == null) return;
      ids.forEach((id) => {
        if (record[id.toString()] !== undefined) Vue.delete(record, id)
      });
    },
  };

  // Use getters to take advantage of vuex caching
  const getters = {
    _dataStore_getById: (state: iDataStoreState) => (key: StoreKey, id: number) => {
      const record = state[key] as Record<string, iStorableItem> ?? null
      if (!record) return null
      return clonedeep(record[id.toString()]) ?? null
    },
    _dataStore_getByIds: (state: iDataStoreState) => (key: StoreKey, ids: number[]) => {
      const record = state[key] as Record<string, iStorableItem> ?? null
      if (!record) return []
      const items: iStorableItem[] = []
      ids.forEach(id => {
        const item = record[id.toString()] ?? null
        if (item) items.push(item)
      })
      return clonedeep(items)
    },
    _dataStore_getAll: (state: iDataStoreState) => (key: StoreKey) => {
      const record = state[key] as Record<string, iStorableItem> ?? null
      if (!record) return []
      return clonedeep(Object.values(record)) as iStorableItem[]
    },
  }

  return { state, mutations, getters }
}


function buildFilterSetsStore(store: Store<any>) {
  // Build initial state from FilterSetKey and set each value to null
  const state: iFilterSetState = Object.values(FilterSetKey).reduce((acc, key) => { acc[key] = null; return acc }, {} as any)

  const mutations = {
    _filterSetStore_upsert(state: iFilterSetState, { filterSetKey, filterSet, appendIds = false }: { filterSetKey: FilterSetKey, filterSet: iFilterSet, appendIds: boolean }) {
      const existing = state[filterSetKey];
      if (existing) {
        // update
        const updatedIdSet: iFilterSet = Object.assign({}, filterSet);
        if (appendIds === true) updatedIdSet.ids = [...new Set([...existing.ids, ...filterSet.ids])]
        state[filterSetKey] = updatedIdSet;
      } else {
        // insert
        state[filterSetKey] = filterSet;
      }
    },
    _filterSetStore_replace(state: iFilterSetState, { filterSetKey, filterSet }: { filterSetKey: FilterSetKey, filterSet: iFilterSet }) {
      state[filterSetKey] = filterSet;
    },
    _filterSetStore_remove(state: iFilterSetState, { filterSetKey }: { filterSetKey: FilterSetKey }) {
      state[filterSetKey] = null;
    }
  };
  const getters = {
    _filterSetStore_getFilterSetKey: (state: iFilterSetState) => (filterSetKey: FilterSetKey) => {
      return clonedeep(state[filterSetKey] as iFilterSet | null);
    }
  }

  return { state, mutations, getters }
}


// Context singleton initialise on install
let STORE = {} as Store<any>;

// Install the plugin
export default {
  install(Vue: VueConstructor<Vue>, { store }: { store: Store<any> }) {
    if (!store) throw new Error("Please provide vuex store.");
    STORE = store;
    store.registerModule("dataStore", buildDataStore(store))
    store.registerModule("filterSetStore", buildFilterSetsStore(store))
  },
};

const dataStore = {
  /** Retrieve the item by it's id from the store */
  getById(key: StoreKey, id: number) {
    return STORE.getters._dataStore_getById(key, id) as iStorableItem | null
  },

  /** Retrieve the item by it's 'slug' from the store */
  getBySlug(key: StoreKey, slug: string) {
    const items = STORE.getters._dataStore_getAll(key) as iStorableItem[]
    return items.find((x: any) => x["slug"] === slug) ?? null;
  },

  /** Retrieve the multiple items from the store by their ids */
  getByIds(key: StoreKey, ids: number[]) {
    return STORE.getters._dataStore_getByIds(key, ids) as iStorableItem[]
  },

  /** Retrieve all items from the store */
  getAll(key: StoreKey) {
    return STORE.getters._dataStore_getAll(key) as iStorableItem[]
  },

  /** Add or update items into the store */
  upsertItems(key: StoreKey, items: any[], merge = false) {
    STORE.commit("_dataStore_upsertItems", { key, items, merge });
  },

  /** Replace all items in the store with new items */
  replaceItems(key: StoreKey, items: iStorableItem[]) {
    STORE.commit("_dataStore_replaceItems", { key, items });
  },

  /** Remove some items from the store */
  removeItems(key: StoreKey, items: iStorableItem[]) {
    const ids = items.map(x => x.id);
    STORE.commit("_dataStore_removeItems", { key, ids });
  },

  /** Remove some items from the store */
  removeItemsByIds(key: StoreKey, ids: number[]) {
    STORE.commit("_dataStore_removeItems", { key, ids });
  }
}

const filterSetStore = {
  /** Retrieve a filter set and associated items from the store */
  getFilterSet(filterSetKey: FilterSetKey) {
    const filterSet = STORE.getters._filterSetStore_getFilterSetKey(filterSetKey) as iFilterSetResult<iStorableItem> | null;
    if (!filterSet) return null;
    filterSet.items = dataStore.getByIds(filterSet.storeKey, filterSet.ids);
    return filterSet
  },

  /** Add or update a filter set and upsert the items into the store */
  upsertFilterSet(filterSetKey: FilterSetKey, queryParams: QueryParams, storeKey: StoreKey, items: iStorableItem[], total = 0, appendIds = false) {
    const filterSet: iFilterSet = {
      storeKey,
      ids: items.map(x => x.id),
      queryParams: queryParams,
      total
    }
    STORE.commit("_filterSetStore_upsert", { filterSetKey, filterSet, appendIds });
    STORE.commit("_dataStore_upsertItems", { key: storeKey, items, merge: false });
  },

  /** Replace the filter set and upsert the items into the store */
  replaceFilterSet(filterSetKey: FilterSetKey, queryParams: QueryParams, storeKey: StoreKey, items: iStorableItem[], total = 0) {
    const filterSet: iFilterSet = {
      storeKey,
      ids: items.map(x => x.id),
      queryParams: queryParams,
      total
    }
    STORE.commit("_filterSetStore_replace", { filterSetKey, filterSet });
    STORE.commit("_dataStore_upsertItems", { key: storeKey, items, merge: false });
  }
  ,
  /** Remove the filter set and leave the items in the store */
  removeFilterSet(filterSetKey: FilterSetKey) {
    STORE.commit("_filterSetStore_remove", { filterSetKey });
  }
}

export const store = {
  ...dataStore,
  ...filterSetStore
}
