
import Vue from "vue";
import AppTopBar from "./components/AppTopBar.vue";
import AppSideMenu from "./components/AppSideMenu.vue";
import Notifications from "./components/Notifications.vue";
import router from "./router";
import { auth } from "@/plugins/auth";
import { Role } from "@/managers/roles";
import { kvStore, kvStoreKeys } from "./plugins/keyValueStore";

export default Vue.extend({
  name: "App",
  components: { AppTopBar, AppSideMenu, Notifications },
  props: {},
  data: () => ({
    openSideMenu: true,
    interval: undefined as number | undefined,
  }),
  computed: {
    showSideMenu() {
      if (auth.hasAtLeastOneRole([Role.DASHBOARD_VIEWER]) && !auth.currentUser()?.superuser) return false;
      return this.openSideMenu;
    },
  },
  methods: {
    toggleSideMenu(open: boolean) {
      if (open == false) this.openSideMenu = false;
      if (router.currentRoute.meta?.closeSideMenu) return;
      this.openSideMenu = open;
    },
  },
  watch: {
    $route(to) {
      if (to.meta?.closeSideMenu) this.toggleSideMenu(false);
      else this.toggleSideMenu(true);
    },
  },
  beforeMount() {
    if (router.currentRoute.meta?.closeSideMenu) this.toggleSideMenu(false);
    else this.toggleSideMenu(true);

    // Set global time and updated it
    this.interval = setInterval(() => {
      kvStore.set(kvStoreKeys.timeAgoBaseDate, new Date());
    }, 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
});
