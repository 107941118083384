
import Vue from "vue";
import { mgr } from "@/managers";
import { iTicket } from "@/managers/tickets";
import { iSopStep } from "@/managers/sop";
import { ActionType } from "@/managers/ticketActions";

export default Vue.extend({
  name: "CheckDriverHistory",
  components: {},
  props: {
    step: { type: Object as () => iSopStep, required: true },
    ticket: { type: Object as () => iTicket, required: true },
  },
  data: () => ({
    checkedDriverHistory: false,
    checkedDriverTicketHistory: false,
  }),
  computed: {
    actionTypes(): ActionType[] {
      return this.ticket.actions.map((x) => x.actionType);
    },
    isResolved(): boolean {
      if (this.actionTypes.includes(ActionType.CLOSED)) return true;
      if (this.actionTypes.includes(ActionType.HISTORY_DETERMINED)) return true;
      return false;
    },
    historyDetermined(): boolean {
      if (this.checkedDriverHistory == true && this.checkedDriverTicketHistory == true) return true;
      return false;
    },
  },
  methods: {
    updateStep() {
      if (this.actionTypes.includes(ActionType.HISTORY_DETERMINED)) {
        this.checkedDriverHistory = true;
        this.checkedDriverTicketHistory = true;
      }

      if (this.isResolved) {
        this.$emit("updateStep", { step: this.step, resolved: true });
      } else {
        if (!this.historyDetermined) {
          this.$emit("updateStep", {
            step: this.step,
            resolved: false,
            unResolvedReason: "Ticket history has not been determined",
          });
          return;
        }
      }
    },
  },
  watch: {
    actionTypes() {
      this.updateStep();
    },
    historyDetermined() {
      if (this.historyDetermined && !this.actionTypes.includes(ActionType.HISTORY_DETERMINED))
        mgr.ticketActions.historyDetermined(this.ticket.id);
    },
  },
  mounted() {
    this.updateStep();
  },
});
