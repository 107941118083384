
import Vue from "vue";
import L from "leaflet";
import { iEvent } from "@/managers/tickets";

export default Vue.extend({
  name: "MapViewer",
  components: {},
  props: {
    mapId: { type: String, default: "ticket-location-map" },
    height: { type: String, default: "800px" },
    events: { type: Array as () => iEvent[], default: () => [] },
    focus: { type: Array as () => iEvent[], default: () => [] },
    scrollOff: { type: Boolean, default: false },
    startZoom: { type: Number, default: 13 },
  },
  data: () => ({
    map: {} as L.Map,
    layerGroup: {} as L.LayerGroup<any>,
  }),
  computed: {
    locationString() {
      return this.events
        .map((x) => {
          return `${x.id}|${x.latitude}|${x.longitude}`;
        })
        .join(",");
    },
  },
  methods: {
    createMap() {
      this.map = L.map(this.mapId).setView([-33.924899, 18.770399], this.startZoom);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' +
          '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
          'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      }).addTo(this.map);

      this.layerGroup = L.layerGroup().addTo(this.map);
      if (this.scrollOff) this.map.scrollWheelZoom.disable();
    },
    updateMarkers(events: iEvent[]) {
      this.layerGroup.clearLayers();

      events.forEach((event) => {
        if (event.latitude && event.longitude)
          L.marker([event.latitude, event.longitude], {
            icon: L.icon({
              iconUrl: "/img/marker-icon.png",
              shadowUrl: "/img/marker-shadow.png",
              iconSize: [24, 40],
              shadowSize: [40, 40],
              iconAnchor: [12, 40],
              shadowAnchor: [12, 40],
              popupAnchor: [0, -30],
            }),
          })
            .bindPopup(this.createLabel(event))
            .addTo(this.layerGroup);
      });
    },
    createLabel(event: iEvent) {
      let label = `<p style="margin:3px"> <strong>Ticket: #${event.ticketId}</strong></p>`;
      label += `<p style="margin:3px">${event.alertType}</p>`;
      if (event.vehicleCode) label += `<p style="margin:3px">${event.vehicleCode}</p>`;
      label += `<p style="margin:3px">${event.vehicleKph || "-"} km/h</p>`;
      return label;
    },
    zoomAll(events: iEvent[]) {
      if (events.length == 0) return;
      const points = events
        .filter((x) => x.latitude && x.longitude)
        .map((x) => {
          return [x.latitude, x.longitude];
        });
      if (points.length > 0) this.map.fitBounds(points as any);
      // Don't zoom in too far
      if (this.map.getZoom() >= this.startZoom) {
        this.map.setZoom(this.startZoom);
      }
    },
  },
  watch: {
    locationString(newStr: string, oldStr: string) {
      if (newStr != oldStr) {
        this.updateMarkers(this.events);
        this.zoomAll(this.events);
      }
    },
    focus(events: iEvent[]) {
      this.zoomAll(events);
    },
  },
  mounted() {
    this.createMap();
    this.updateMarkers(this.events);
    this.zoomAll(this.events);
  },
});
