
import Vue from "vue";
import ItemUpdater from "@/components/ItemUpdater.vue";
import ConfirmationCard from "@/components/ConfirmationCard.vue";
import FindTicket from "@/components/inputs/FindTicket.vue";
import { iDriver, iEvent, iEventUpdate, iStore, iVehicle } from "@/managers/tickets";
import { flash } from "@/plugins/flashNotifications";
import { mgr } from "@/managers";
import axios from "axios";

export default Vue.extend({
  name: "EventUpdater",
  components: { ItemUpdater, ConfirmationCard, FindTicket },
  props: {
    value: { type: Object as () => iEvent },
  },
  data: () => ({
    drivers: [] as iDriver[],
    vehicles: [] as iVehicle[],
    stores: [] as iStore[],
    showMoveConfirmDialog: false,
  }),
  computed: {
    driverOptions(): any[] {
      return [
        ...this.drivers.map((x) => {
          return { value: x.id, text: `${x.name} (${x.staffId})` };
        }),
        { value: -1, text: "None" },
      ];
    },
    vehicleOptions(): any[] {
      return [
        ...this.vehicles.map((x) => {
          return { value: x.id, text: `${x.code} ${x.type}` };
        }),
        { value: -1, text: "None" },
      ];
    },
    storeOptions(): any[] {
      return [
        ...this.stores.map((x) => {
          return { value: x.id, text: `${x.name} (${x.code})` };
        }),
        { value: -1, text: "None" },
      ];
    },
  },
  methods: {
    updateEvent({ initial, updated }: { initial: iEvent; updated: any }) {
      const payload: iEventUpdate = {};
      if (updated.ticketId && updated.ticketId !== initial.ticketId) payload.ticketId = updated.ticketId;
      if (updated.driverId && updated.driverId !== initial.driverId) payload.driverId = updated.driverId;
      if (updated.vehicleId && updated.vehicleId !== initial.vehicleId) payload.vehicleId = updated.vehicleId;

      if (updated.storeId && updated.storeId !== initial.storeId) payload.storeId = updated.storeId;

      if (Object.keys(payload).length == 0) {
        flash.success("No changes detected");
        return;
      }

      mgr.tickets.updateEvent(this.value.id, payload);

      this.close();
    },
    moveToNewTicket() {
      mgr.tickets.moveEventToNewTicket(this.value.id);
      this.showMoveConfirmDialog = false;
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
  beforeMount() {
    axios
      .get("/app/pages/alerts")
      .then((response) => response.data)
      .then((data) => {
        this.drivers = data.drivers;
        this.stores = data.stores;
        this.vehicles = data.vehicles;
      });
  },
});
