
import Vue from "vue";
import { mgr } from "@/managers";
import { iSopStep } from "@/managers/sop";
import { TicketFlag, ActionType } from "@/managers/ticketActions";
import { screamingSnakeToRegularCase } from "@/utils";
import { iTicket } from "@/managers/tickets";

export default Vue.extend({
  name: "SetFlags",
  components: {},
  props: {
    step: { type: Object as () => iSopStep, required: true },
    ticket: { type: Object as () => iTicket, required: true },
    specifiedFlags: { type: Array as () => TicketFlag[], required: true },
  },
  data: () => ({
    selectedFlag: "",
  }),
  computed: {
    flagOptions(): { text: string; value: any; disabled: boolean }[] {
      return Object.values(TicketFlag).map((x) => {
        return { text: screamingSnakeToRegularCase(x), value: x, disabled: false };
      });
    },
    actionTypes(): ActionType[] {
      return this.ticket.actions.map((x) => x.actionType);
    },
    isResolved(): boolean {
      return this.actionTypes.includes(ActionType.FLAG_ADDED);
    },
    specifiedFlag(): string {
      return this.specifiedFlags[0] || "";
    },
  },
  methods: {
    submitFlags() {
      if (this.isResolved) return;
      let flag: string | undefined = this.selectedFlag;
      if (flag === "") flag = undefined;
      mgr.ticketActions.flagSubmitted(this.ticket.id, flag as TicketFlag);
    },
    updateStep() {
      if (this.isResolved) {
        this.$emit("updateStep", { step: this.step, resolved: true });
        this.selectedFlag = this.ticket.actions.find((x) => x.actionType == ActionType.FLAG_ADDED)?.data
          .flag as TicketFlag;
      } else {
        this.$emit("updateStep", {
          step: this.step,
          resolved: false,
          unResolvedReason: "Confirm if a flag is needed or not",
        });
      }
    },
  },
  watch: {
    actionTypes() {
      this.updateStep();
    },
    specifiedFlag(value: string) {
      if (value !== "") this.selectedFlag = value;
    },
  },
  mounted() {
    this.updateStep();
    this.selectedFlag = this.specifiedFlag;
  },
});
