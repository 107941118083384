
import Vue from "vue";
import { iClosingAction, iSopStep } from "@/managers/sop";
import { iTicket } from "@/managers/tickets";
import { ActionType } from "@/managers/ticketActions";
import { mgr } from "@/managers";

export default Vue.extend({
  name: "CloseTicket",
  components: {},
  props: {
    step: { type: Object as () => iSopStep, required: true },
    ticket: { type: Object as () => iTicket, required: true },
  },
  data: () => ({
    selectedAction: "",
    otherAction: "",
    description: "",
  }),
  computed: {
    actionTypes(): ActionType[] {
      return this.ticket.actions.map((x) => x.actionType);
    },
    isResolved(): boolean {
      return this.actionTypes.includes(ActionType.CLOSED);
    },
    validActions(): iClosingAction[] {
      return this.step.closingActions?.filter((x) => x.valid) ?? [];
    },
    canClose(): boolean {
      if (this.isResolved) return false;

      if (this.selectedAction === "Other intervention" && this.otherAction !== "") return true;
      if (this.selectedAction !== "Other intervention" && this.selectedAction !== "") return true;
      return false;
    },
  },
  methods: {
    closeTicket() {
      if (this.isResolved) return;
      let action = this.selectedAction;
      if (action === "Other intervention") action = "Other intervention: " + this.otherAction;
      mgr.ticketActions.closedTicket(this.ticket.id, action, this.description)?.then(() => {
        // Navigate back to inbox you came from once you have resolved the ticket
        const matches = /([\/a-z-]*)\/\d*/.exec(this.$router.currentRoute.fullPath);
        const root = matches ? matches[1] : undefined;
        if (root) this.$router.replace(root);
      });
    },
    setFocus(ref: string) {
      if (this.isResolved) return;
      if (ref == "valid" && this.selectedAction !== "Other intervention") {
        this.selectedAction = "Other intervention";
        this.$nextTick(() => {
          const textField: any = this.$refs[ref];
          textField?.$el?.querySelector("input")?.focus?.();
        });
      }
    },
    updateStep() {
      if (this.isResolved) {
        this.$emit("updateStep", { step: this.step, resolved: true });
        const action = this.ticket.actions.find((x) => x.actionType == ActionType.CLOSED);
        if (action) {
          this.description = action.data.description ?? "";
          const closingActionText: string = action.data.closing_action ?? "";
          if (closingActionText.startsWith("Other intervention: "))
            this.otherAction = closingActionText.replace("Other intervention: ", "");
          else this.selectedAction = closingActionText || "";
        }
      } else {
        this.$emit("updateStep", {
          step: this.step,
          resolved: false,
          unResolvedReason: "No closing action added yet",
        });
      }
    },
  },
  watch: {
    actionTypes() {
      this.updateStep();
    },
  },
  mounted() {
    this.updateStep();
  },
});
