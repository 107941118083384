
import { AlertType, alertTypeAbv, iTicket, TicketCategory, TicketStatus } from "@/managers/tickets";
import Vue from "vue";
import TimeMixin from "@/mixins/TimeMixin";
import DisplayMixin from "@/mixins/DisplayMixin";
import { FilterSetKey, iFilterSetResult, QueryParams } from "@/plugins/dataStore";
import { paramsToTableOptions, tableOptionsToParams } from "@/managers/_baseManager";
import { TicketFlag, ActionType } from "@/managers/ticketActions";
import AlertTypeChip from "@/components/utils/AlertTypeChip.vue";
import TimeAgoToolTip from "@/components/utils/TimeAgoToolTip.vue";
import FlagsDisplay from "@/components/utils/FlagsDisplay.vue";
import DateRange from "@/components/inputs/DateRange.vue";
import { mgr } from "@/managers";
import { screamingSnakeToRegularCase } from "@/utils";
import axios from "axios";

interface iTableData extends iTicket {
  alertTypeAbv: string;
  reason: string;
  driverInfo: string;
  vehicleInfo: string;
  storeInfo: string;
}

interface iTableOptions {
  itemsPerPage: number;
  page: number;
}

export default Vue.extend({
  name: "TicketTable",
  mixins: [TimeMixin, DisplayMixin],
  components: {
    AlertTypeChip,
    DateRange,
    TimeAgoToolTip,
    FlagsDisplay,
  },
  props: {
    filterSetKey: { type: String as () => FilterSetKey, required: true },
    searchDefaults: { type: Object, default: () => {} },
    optionsDefaults: { type: Object, default: () => {} },
    hideSearch: { type: Array as () => string[], default: () => [] },
    hideHeaders: { type: Array as () => string[], default: () => [] },
  },
  data: () => ({
    search: {
      date: [] as string[],
      alertType: [] as string[],
      alertTypeAll: false,
      status: [] as string[],
      flags: [] as string[],
      driverName: "",
      vehicleCode: "",
      storeName: "",
    },
    options: {} as iTableOptions,
    expanded: [],
    assignedOptions: {},
    loading: false,
    driverNames: [] as string[],
    vehicleCodes: [] as string[],
    storeNames: [] as string[],
  }),
  computed: {
    ticketsFilterSet(): iFilterSetResult<iTicket> | null {
      return mgr.tickets.getFilterSet(this.filterSetKey);
    },
    tickets(): iTicket[] {
      return this.ticketsFilterSet?.items || [];
    },
    tableData(): iTableData[] {
      return this.tickets.map((x) =>
        Object.assign(x, {
          alertTypeAbv: alertTypeAbv(x.alertType),
          reason: x.actions.find((a) => a.actionType === ActionType.REASON_ADDED)?.data.reason ?? "-",
          driverInfo: x.events.map((e) => e.driverName).find((d) => !!d) ?? "-",
          vehicleInfo: x.events.map((e) => e.vehicleCode).find((v) => !!v) ?? "-",
          storeInfo: x.events.map((e) => e.storeName).find((s) => !!s) ?? "-",
        })
      );
    },
    tableHeaders() {
      const headers = [
        { text: "No.", value: "id" },
        { text: "Created", value: "createdUtc" },
        { text: "Type", value: "alertType" },
        { text: "Status", value: "status" },
        { text: "Driver", value: "driverInfo", sortable: false },
        { text: "Vehicle", value: "vehicleInfo", sortable: false },
        { text: "Store", value: "storeInfo", sortable: false },
        { text: "Reason", value: "reason", sortable: false },
        { text: "Assigned", value: "assignedUser", sortable: false },
        { text: "Flags", value: "flags", sortable: false },
        { text: "", value: "data-table-expand", sortable: false, cellClass: "pr-0" },
        { text: "", value: "btns", sortable: false },
      ];
      return headers.filter((x) => !this.hideHeaders.includes(x.value));
    },
    alertTypeOptions() {
      return Object.values(AlertType).map((x) => {
        return { value: x, text: screamingSnakeToRegularCase(x) };
      });
    },
    statusOptions() {
      return Object.values(TicketStatus).map((x) => {
        return { value: x, text: screamingSnakeToRegularCase(x) };
      });
    },
    categoryOptions() {
      return Object.values(TicketCategory).map((x) => {
        return { value: x, text: screamingSnakeToRegularCase(x) };
      });
    },
    flagOptions() {
      return Object.values(TicketFlag).map((x) => {
        return { value: x, text: screamingSnakeToRegularCase(x) };
      });
    },
  },
  methods: {
    statusColor(status: TicketStatus): String {
      return "ticket-" + status.toLowerCase();
    },
    openTicket(ticket: iTicket) {
      this.$emit("openTicket", ticket);
    },
    resetSearch() {
      this.search.date = [];
      this.search.alertType = [];
      this.search.status = [];
      this.search.flags = [];
      this.search.driverName = "";
      this.search.vehicleCode = "";
      this.search.storeName = "";
      this.options.page = 1;
      this.setSearchDefaults();
      this.fetchTickets();
    },
    searchTickets() {
      this.options.page = 1;
      this.fetchTickets();
    },
    fetchTickets() {
      let params = tableOptionsToParams(this.options) as QueryParams;

      // Add in search fields
      params.alertType = this.search.alertType;
      params.status = this.search.status;
      const fromDate = this.search.date[0] || undefined;
      let toDate = this.search.date[1] || undefined;
      if (this.search.date.length == 1) toDate = fromDate;
      if (fromDate) params.fromDate = fromDate;
      if (toDate) params.toDate = toDate;

      params.flags = this.search.flags;

      params.driverName = this.search.driverName;
      params.vehicleCode = this.search.vehicleCode;
      params.storeName = this.search.storeName;

      // params = this.processParams(params);

      this.loading = true;
      mgr.tickets.fetchFilterSet(this.filterSetKey, params).finally(() => {
        this.loading = false;
      });
    },
    setSearchDefaults() {
      this.search = { ...this.search, ...this.searchDefaults };
    },
    setOptions() {
      // Get search and table options out of the params
      if (this.ticketsFilterSet) {
        const params = this.ticketsFilterSet.queryParams;
        const options = { ...this.optionsDefaults, ...paramsToTableOptions(params) };
        // set search options
        if (params.alertType) this.search.alertType = params.alertType as string[];
        if (params.fromDate && params.toDate) this.search.date = [params.fromDate as string, params.toDate as string];
        if (params.status) this.search.status = params.status as string[];
        if (params.flags) this.search.flags = params.flags as string[];
        if (params.driverName) this.search.driverName = params.driverName as string;
        if (params.vehicleCode) this.search.vehicleCode = params.vehicleCode as string;
        if (params.storeName) this.search.storeName = params.storeName as string;

        // set table options
        this.options = Object.assign({}, this.options, options);
        this.assignedOptions = this.ticketsFilterSet?.items
          .filter((x) => x.assignedUser)
          .map((x) => {
            return { value: x.assignedUser?.id, text: x.assignedUser?.username };
          });
      } else {
        this.options = this.optionsDefaults;
      }
    },
    toggleAllAlertType() {
      if (this.search.alertTypeAll) this.search.alertType = [];
      else this.search.alertType = this.alertTypeOptions.map((x) => x.value).slice();
      this.search.alertTypeAll = !this.search.alertTypeAll;
    },
    loadDriversVehiclesStores() {
      // We don't mind calling this in 'beforeMount' because the response should be cached by the browser
      // Cache-Control: max-age=21600, must-revalidate
      axios
        .get("/app/pages/alerts")
        .then((response) => response.data)
        .then((data) => {
          this.driverNames = data.drivers.map((x: any) => x.name);
          this.storeNames = data.stores.map((x: any) => x.name);
          this.vehicleCodes = data.vehicles.map((x: any) => x.code);
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchTickets();
      },
      deep: true,
    },
  },
  beforeMount() {
    this.loadDriversVehiclesStores();
    this.setSearchDefaults();
    this.setOptions();
  },
});
/*
  How filterSets work
  A filterSets represents a named collection of items and a set of parameter, these parameter define how the items should be filtered on the server.
  The parameters can directly be mapped to url query parameters like ?skip=3&limit=3&descending=false

  You can get a filterSet by its name (FilterSetKey) like so getFilterSet(FilterSetKey.VIEWER_TICKETS)
  You can update a filterSet by its name and giving it params fetchFilterSetIfDifferent(FilterSetKey.VIEWER_TICKETS, params)

  If the params are the same as what it currently has stored, it will just return what it has stored.
  If the params are different, then it will send a request for new data and store the data and the params.
*/

/*
  How filterSets work with search and table options.

  The Vuetify table is set to sync its options with our data prop "options"
  We also have search inputs that map to another data prop "search"
  Together some of the fields from the table options and search options will make up our params for our filterSet

  We want to keep the table and search options in sync with our stored params so that this page will remember its filterSet as we navigate around.
  To do this there are two steps:
  1. When we navigate to this page we must first set the table options and search options to match the stored filterSet params.
  2. Before we send a request for more items(Tickets) we must build our params object from the table options and search options.
  We use a watcher to detect changes to the table options and the search button to trigger a request
*/
