import Vue from "vue";
import { screamingSnakeToRegularCase } from "@/utils"

export default Vue.extend({
  methods: {
    // Enum to display string
    displayEnum(enm?: any) {
      if (!enm) return "";
      return screamingSnakeToRegularCase(enm)
    }
  }
});