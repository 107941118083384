import { render, staticRenderFns } from "./JobCardNumber.vue?vue&type=template&id=8856b5fe&scoped=true&"
import script from "./JobCardNumber.vue?vue&type=script&lang=ts&"
export * from "./JobCardNumber.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8856b5fe",
  null
  
)

export default component.exports