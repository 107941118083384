import Vue from 'vue'
import App from './App.vue'
import router from '../src/router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

// Sentry
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: "https://57d97abbd20b492d9a2032615374cf61@o597990.ingest.sentry.io/5744074",
    ignoreErrors: [
      'Error: Request failed with status code 401',
      'Error: Request failed with status code 403'
    ],
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0,
    logErrors: true
  });
}

// import plugins
import auth from "./plugins/auth";
import flashNotifications from "./plugins/flashNotifications";
import dataStore from "./plugins/dataStore";

import keyValueStore from "./plugins/keyValueStore";
import axiosInterceptors from "./plugins/axiosInterceptors";
import sockets from "./plugins/sockets";

// Register plugins
Vue.use(auth, { store, router });
Vue.use(flashNotifications, { store, displayTime: 6000 });
Vue.use(dataStore, { store });
Vue.use(keyValueStore, { store });
Vue.use(axiosInterceptors);
Vue.use(sockets, { store });

Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
