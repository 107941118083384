
import Vue from "vue";
import { mgr } from "@/managers";
import { auth } from "@/plugins/auth";
import router from "@/router";
import { iTicket, TicketCategory, TicketStatus } from "@/managers/tickets";
import TimeMixin from "@/mixins/TimeMixin";
import InboxTicketTable from "@/components/InboxTicketTable.vue";
import { FilterSetKey, iFilterSetResult, QueryParams } from "@/plugins/dataStore";
import { sub, isAfter } from "date-fns";
import { tableOptionsToParams } from "@/managers/_baseManager";
import { screamingSnakeToRegularCase } from "@/utils";
import { Role } from "@/managers/roles";

export default Vue.extend({
  name: "Inbox",
  mixins: [TimeMixin],
  components: { InboxTicketTable },
  props: {},
  data: () => ({
    ticketCategory: null as null | TicketCategory,
    newTicketsLoading: true,
    myTicketsLoading: true,
    inProgressTicketsLoading: true,
  }),
  computed: {
    isAdmin() {
      return auth.isAdmin();
    },
    newTickets() {
      return mgr.tickets.getFilterSet(FilterSetKey.NEW_TICKETS)?.items || [];
    },
    myTicketsInfo() {
      return mgr.tickets.getFilterSet(FilterSetKey.MY_TICKETS);
    },
    inProgressTicketsInfo() {
      return mgr.tickets.getFilterSet(FilterSetKey.IN_PROGRESS_TICKETS);
    },
    myTickets(): iTicket[] {
      return this.myTicketsInfo?.items || [];
    },
    tickets(): iTicket[] {
      const timeAgo = sub(new Date(), { hours: 24 });
      const newest = mgr.tickets
        .getAll()
        .filter((x) => x.status == TicketStatus.NEW && isAfter(new Date(x.createdUtc), timeAgo));
      return newest;
    },
    inProgressTickets() {
      return mgr.tickets.getFilterSet(FilterSetKey.IN_PROGRESS_TICKETS)?.items || [];
    },
    categoryOptions() {
      return [
        { value: null, text: "All" },
        ...Object.values(TicketCategory).map((x) => {
          return { value: x, text: screamingSnakeToRegularCase(x) };
        }),
      ];
    },
  },
  methods: {
    openTicket(ticket: any) {
      router.replace(`/inbox/${ticket.value}`);
    },
    fetchAll() {
      const defaultTableOptions = {
        sortBy: ["createdUtc"],
        sortDesc: [true],
      };
      this.fetchNewTickets();
      this.fetchMyTickets(defaultTableOptions);
      this.fetchInProgressTickets(defaultTableOptions);
    },
    fetchNewTickets() {
      this.newTicketsLoading = true;
      mgr.tickets.fetchForPast24Hours(this.ticketCategory).finally(() => {
        this.newTicketsLoading = false;
      });
    },
    fetchMyTickets(options: any) {
      this.myTicketsLoading = true;
      const params = tableOptionsToParams(options) as QueryParams;
      params.assignedUserId = auth.currentUser()?.id;
      params.status = TicketStatus.ASSIGNED;
      params.category = this.ticketCategory;
      mgr.tickets.fetchFilterSet(FilterSetKey.MY_TICKETS, params).then(() => {
        this.myTicketsLoading = false;
      });
    },
    fetchInProgressTickets(options: any) {
      this.inProgressTicketsLoading = true;

      const params = tableOptionsToParams(options) as QueryParams;
      params.status = TicketStatus.ASSIGNED;
      params.category = this.ticketCategory;

      mgr.tickets.fetchFilterSet(FilterSetKey.IN_PROGRESS_TICKETS, params).then(() => {
        this.inProgressTicketsLoading = false;
      });
    },
    setDefaultCategory() {
      const roles = (auth.currentUser()?.roles || []).map((x) => x.name);
      if (roles.includes(Role.DRIVER_DISCIPLINE_OPERATOR)) this.ticketCategory = TicketCategory.DRIVER_DISCIPLINE;
      else if (roles.includes(Role.CUSTOMER_CARE_OPERATOR)) this.ticketCategory = TicketCategory.CUSTOMER_CARE;
      else if (roles.includes(Role.MAINTENANCE_OPERATOR)) this.ticketCategory = TicketCategory.MAINTENANCE;
      else this.ticketCategory = null;
    },
  },
  watch: {},
  beforeMount() {
    this.setDefaultCategory();
    this.fetchAll();
  },
});
