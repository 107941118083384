import { VueConstructor } from "vue/types/umd";
import RobustWebSocket from "robust-websocket"
import { Store } from "vuex";
import cloneDeep from "lodash.clonedeep";
import { mgr } from "@/managers";
import { auth } from "./auth";
import { flash } from "./flashNotifications";
import { settings } from "@/config"
import { StoreKey, store as dataStore } from "@/plugins/dataStore";
import { screamingSnakeToRegularCase } from "@/utils"
import { store } from "@/store/index";
import { AlertType, iTicketCount } from "@/managers/tickets";

// Manages user authentication and current user info

/*
    To use, in main.ts add:
    import sockets from "./plugins/sockets";
    Vue.use(sockets);

        // In a component
    import { sockets } from "@/plugins/sockets";
    sockets.sendMessage("some message");
*/

export enum MessageType {
  CONNECTION_CREATED = "CONNECTION_CREATED",
  CONNECTION_DISCONNECTED = "CONNECTION_DISCONNECTED",
  TICKET_CREATED = "TICKET_CREATED",
  TICKET_UPDATED = "TICKET_UPDATED"
}

enum ConnectionStatus {
  CONNECTING = "Connecting",
  CONNECTED = "Connected",
  DISCONNECTING = "Disconnecting",
  DISCONNECTED = "Disconnected",
  UNAUTHORIZED = "Unauthorized",
}

interface iSocketState {
  status: ConnectionStatus
  messages: iMessage[]
  connections: iConnectionInfo[]
}

interface iConnectionInfo {
  userId: number;
  username: string;
  connectedUtc: string;
}

export interface iMessage {
  type: MessageType;
  data: any;
  sentUtc: string
  code: number // status code
}


let STORE = {} as Store<any>;
let CONNECTION = {} as WebSocket;

export default {
  install(Vue: VueConstructor<Vue>, { store }: { store: Store<any> }) {
    if (!store) throw new Error("Please provide vuex store.");
    STORE = store;

    const state: iSocketState = {
      status: ConnectionStatus.DISCONNECTED,
      messages: [],
      connections: []
    };
    const mutations = {
      _setConnectionStatus(state: iSocketState, readyState: number) {
        // https://developer.mozilla.org/en-US/docs/Web/API/WebSocket#Ready_state_constants
        if (readyState === 0) state.status = ConnectionStatus.CONNECTING
        else if (readyState === 1) state.status = ConnectionStatus.CONNECTED
        else if (readyState === 2) state.status = ConnectionStatus.DISCONNECTING
        else if (readyState === 3) state.status = ConnectionStatus.DISCONNECTED
        else if (readyState === 4) state.status = ConnectionStatus.UNAUTHORIZED
        else state.status === ConnectionStatus.DISCONNECTED
      },
      _appendMessage(state: iSocketState, message: iMessage) {
        state.messages.push(cloneDeep(message))
      },
      _setConnections(state: iSocketState, connections: iConnectionInfo[]) {
        state.connections = connections
      },
    };
    const actions = {};
    store.registerModule("socketConnection", { state, mutations, actions });

    sockets.createConnection();
  },
};


export const sockets = {
  appendMessage(message: iMessage) {
    STORE.commit("_appendMessage", message)
  },
  getConnections() {
    return STORE.state.socketConnection.connections as iConnectionInfo[] || []
  },
  getMessages() {
    return STORE.state.socketConnection.messages as iMessage[] || []
  },
  getStatus() {
    return STORE.state.socketConnection.status as ConnectionStatus
  },
  getConnection() {
    return CONNECTION as WebSocket
  },
  isConnected() {
    return this.getStatus() == ConnectionStatus.CONNECTED
  },
  sendMessage(text: any) {
    CONNECTION?.send(text)
  },
  closeConnection() {
    CONNECTION.close()
  },
  createConnection(userId?: number) {
    const user = auth.currentUser();
    if (!user) return;
    if (!auth.isSignedIn()) return;
    if (sockets.isConnected()) return;

    // https://github.com/nathanboktae/robust-websocket#readme  
    CONNECTION = new RobustWebSocket(`${settings.wsServerHost}app/ws?user_id=${user.id}`, undefined, {
      // Reconnect with an exponential back-off clamped at a max value
      shouldReconnect: function (event: any, ws: any) {
        const wait = Math.pow(1.5, ws.attempts) * 500
        const maxWait = 5 * 60 * 1000; // milliseconds
        if (wait > maxWait) return maxWait
        return wait
      },
      // By default RobustWebSocket instances use connectivity events to avoid triggering reconnection when the browser is offline.We want to always this
      ignoreConnectivityEvents: true
    })

    CONNECTION.addEventListener("open", function (event) {
      // Set state as unauthorized if it is connected
      const state = CONNECTION.readyState
      if (state == 1) STORE.commit("_setConnectionStatus", 4)
      else STORE.commit("_setConnectionStatus", CONNECTION.readyState)
      flash.success("Connected", "sockets")
    })

    CONNECTION.addEventListener("message", function (event) {
      const data = JSON.parse(event.data) as iMessage
      STORE.commit("_setConnectionStatus", CONNECTION.readyState)
      sockets.appendMessage(data)
      parseMessage(data)
    })

    CONNECTION.addEventListener("error", function (event) {
      // console.error("WS ERROR: ", event)
      STORE.commit("_setConnectionStatus", CONNECTION.readyState)
    })

    CONNECTION.addEventListener("close", function (event) {
      STORE.commit("_setConnectionStatus", CONNECTION.readyState)
      STORE.commit("_setConnections", [])
      // flash.warning("Disconnected", "sockets")
    })
  },
}

function parseMessage(message: iMessage) {
  if (message.type == MessageType.CONNECTION_CREATED) {
    STORE.commit("_setConnections", message.data.connections)
  }
  if (message.type == MessageType.CONNECTION_DISCONNECTED) {
    STORE.commit("_setConnections", message.data.connections)
  }
  if (message.type == MessageType.TICKET_CREATED) {
    const ticket = mgr.tickets.transformInbound(message.data.ticket)
    dataStore.upsertItems(StoreKey.TICKETS, [ticket])

    // update inbox counts
    const ticketCounts = mgr.tickets.transformInboundTicketCounts(message.data.ticketCounts)
    STORE.commit("_setTicketCounts", ticketCounts)


    let msg = `New ticket: ${screamingSnakeToRegularCase(ticket.alertType)}`;
    if (ticket.events.length > 0 && ticket.events[0].driverName)
      msg += " for " + ticket.events[0].driverName

    flash.error(msg, "sockets")
    mgr.notifications.addFromTicket(ticket)
    // Ensure we are always up to date and update inbox counts
    mgr.tickets.fetchForPast24Hours()

    if (ticket.alertType === AlertType.PANIC_ALARM || ticket.alertType === AlertType.UNAUTHORIZED_STOP) {
      playAudio("/sounds/deep-beep.mp3")
      sendBrowserNotification(msg, ticket.id.toString())
    } else {
      playAudio("/sounds/message-ting.mp3")
    }
  }
  if (message.type == MessageType.TICKET_UPDATED) {
    const ticket = mgr.tickets.transformInbound(message.data.ticket)
    dataStore.upsertItems(StoreKey.TICKETS, [ticket])

    // update inbox counts
    const ticketCounts = mgr.tickets.transformInboundTicketCounts(message.data.ticketCounts)
    STORE.commit("_setTicketCounts", ticketCounts)

    const msg = `Ticket #${ticket.id} updated`;
    flash.info(msg, "sockets")
  }
}

function playAudio(src: string = "/sounds/deep-beep.mp3") {
  // https://pixabay.com/sound-effects/search/alarm/
  if (!window?.Audio) return
  const audio = new Audio(src);

  audio.addEventListener("canplaythrough", function () {
    audio.play().catch(error => {
      // console.log("Audio prevented from auto playing: ", error)
    });

  })

}

function sendBrowserNotification(message: string, tag: string, title: string = "Watson Bureau alert") {
  if (!window.Notification) return;

  // https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API
  // https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
  const send = () => {
    const image = "/img/alert-icon-red.png";
    new Notification(title, {
      body: message,
      tag: tag,
      icon: image,
      image: image,
      badge: image,
      renotify: true,
      requireInteraction: true,
    });
  };

  if (Notification.permission !== "granted") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") send();
    });
  } else send();

}
