
import Vue from "vue";
import axios from "axios";
import DisplayMixin from "@/mixins/DisplayMixin";
import format from "date-fns/format";
import { mgr } from "@/managers";
import { iTicket, AlertType, TicketStatus, TicketCategory } from "@/managers/tickets";
import { TicketFlag } from "@/managers/ticketActions";
import DateRange from "@/components/inputs/DateRange.vue";
import { screamingSnakeToRegularCase, timeAgo } from "@/utils";

interface iTicketSearch extends iTicket {
  resultString: string;
}

export default Vue.extend({
  name: "FindTicket",
  mixins: [DisplayMixin],
  components: { DateRange },
  props: {
    value: { type: Number, default: undefined },
    label: { type: String, default: undefined },
    select: { type: Boolean, default: true },
    solo: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
  },
  data: () => ({
    isLoading: false,
    showAdvanced: false,
    selectedTicket: "",
    searchResults: [] as iTicketSearch[],
    search: {
      id: "",
      alertType: "",
      status: "",
      category: "",
      dates: [] as string[],
      flags: "",
    },
    error: false,
    errorMessage: [] as string[],
    inputTimer: 0,
  }),
  computed: {
    results(): any[] {
      return this.searchResults.map((x) => {
        return { value: x.id.toString(), text: x.resultString };
      });
    },
    alertTypeOptions() {
      return Object.values(AlertType).map((x) => {
        return { value: x, text: screamingSnakeToRegularCase(x) };
      });
    },
    statusOptions() {
      return Object.values(TicketStatus).map((x) => {
        return { value: x, text: screamingSnakeToRegularCase(x) };
      });
    },
    categoryOptions() {
      return Object.values(TicketCategory).map((x) => {
        return { value: x, text: screamingSnakeToRegularCase(x) };
      });
    },
    flagOptions() {
      return Object.values(TicketFlag).map((x) => {
        return { value: x, text: screamingSnakeToRegularCase(x) };
      });
    },
  },
  methods: {
    onInput(val: string) {
      this.$emit("input", Number(val));
    },
    selectTicket(val: any) {
      if (
        val &&
        /^\d*$/.test(val) &&
        this.searchResults.find((x) => {
          return x.id == val;
        })
      ) {
        this.$emit("ticketSelected", Number(val));
        this.closeAdvanced();
      }
    },
    openAdvanced() {
      this.showAdvanced = true;
      this.clearSearch();
    },
    closeAdvanced() {
      this.showAdvanced = false;
      this.clearSearch();
    },
    clearSearch() {
      this.search = {
        id: "",
        alertType: "",
        status: "",
        category: "",
        dates: [],
        flags: "",
      };
      this.searchResults = [];
    },
  },
  watch: {
    search: {
      handler(value) {
        // clear setTimeout on each update of search value
        clearTimeout(this.inputTimer);

        if (value.id && !/^\d*$/.test(value.id)) {
          this.error = true;
          this.errorMessage = ["Ticket Id must be an integer"];
          return;
        }

        this.isLoading = true;

        this.error = false;
        this.errorMessage = [];

        // send get request after 1 second delay to allow user to finish typing
        this.inputTimer = setTimeout(() => {
          const params = new URLSearchParams();

          if (value.id && value.id != "") params.set("id", value.id);
          if (value.alertType) params.set("alertType", value.alertType);
          if (value.status) params.set("status", value.status);
          if (value.category) params.set("category", value.category);
          if (value.flags) params.set("flags", value.flags);

          if (value.dates.length !== 0) {
            if (value.dates.length < 2) params.set("fromDate", value.dates[0]);
            if (value.dates.length === 2) {
              params.set("fromDate", value.dates[0]);
              params.set("toDate", value.dates[1]);
            }
          }

          if (params.toString() !== "") {
            axios
              .get("/app/tickets/find?" + params.toString())
              .then((response) => response.data)
              .then((data: iTicket[]) => {
                this.searchResults = data.map((x) => {
                  x = mgr.tickets.transformInbound(x);
                  const ticketInfo = `${x.id} - ${x.alertType} - ${format(
                    new Date(x.createdUtc),
                    "yyyy-MM-dd HH:mm"
                  )} (${timeAgo(x.createdUtc)})`;
                  return { ...x, resultString: ticketInfo };
                });
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => (this.isLoading = false));
          } else this.isLoading = false;
        }, 1000);
      },
      deep: true,
    },
  },
  beforeMount() {
    if (this.value) {
      this.selectedTicket = this.value.toString();
      this.searchResults.push({ id: this.value, resultString: this.value.toString() } as any);
    }
  },
});
