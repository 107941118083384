
import Vue from "vue";
import startOfWeek from "date-fns/startOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfWeek from "date-fns/endOfWeek";
import endOfMonth from "date-fns/endOfMonth";
import sub from "date-fns/sub";

export default Vue.extend({
  name: "DateRange",
  components: {},
  props: {
    value: { type: Array as () => string[], required: true },
    label: { type: String, default: "Dates" },
    dense: { type: Boolean, default: false },
  },
  data: () => ({
    date: [] as string[],
    showMenu: false,
  }),
  computed: {},
  methods: {
    clear() {
      this.date = [];
    },
    close() {
      this.showMenu = false;
    },
    save() {
      if (this.date.length > 1) {
        this.date = this.date.sort();
      }
      (this.$refs["date-range-menu"] as any).save(this.date);
    },
    today() {
      const date = new Date();
      this.date = [date.toISOString().substr(0, 10)];
      this.save();
    },
    yesterday() {
      const date = sub(new Date(), { days: 1 });
      this.date = [date.toISOString().substr(0, 10)];
      this.save();
    },
    thisWeek() {
      const from = startOfWeek(new Date());
      const to = endOfWeek(new Date());
      // 0 = Sunday
      this.date = [from.toISOString().substr(0, 10), to.toISOString().substr(0, 10)];
      this.save();
    },
    lastWeek() {
      const date = sub(new Date(), { weeks: 1 });
      const from = startOfWeek(date);
      const to = endOfWeek(date);
      // 0 = Sunday
      this.date = [from.toISOString().substr(0, 10), to.toISOString().substr(0, 10)];
      this.save();
    },
    thisMonth() {
      const from = startOfMonth(new Date());
      const to = endOfMonth(new Date());
      this.date = [from.toISOString().substr(0, 10), to.toISOString().substr(0, 10)];
      this.save();
    },
    lastMonth() {
      const date = sub(new Date(), { months: 1 });
      const from = startOfMonth(date);
      const to = endOfMonth(date);
      this.date = [from.toISOString().substr(0, 10), to.toISOString().substr(0, 10)];
      this.save();
    },
  },
  watch: {
    value(value: string[]) {
      this.date = value;
    },
    date(date: string[]) {
      this.$emit("input", date);
    },
  },
  beforeMount() {
    this.date = this.value;
  },
});
