import { StoreKey, store, iStorableItem, FilterSetKey, iFilterSetResult, QueryParams, PaginatedQueryParams } from "@/plugins/dataStore";
import axios from "axios";
import { flash } from "@/plugins/flashNotifications";
import { localToUtc, pascalToSnakeCase, snakeToCamelCase, utcToLocal } from "@/utils";

type TransformInbound<T> = (data: any) => T
type TransformOutbound = (data: any) => any


export const canGetById = function <T extends iStorableItem>(storeKey: StoreKey) {
  return { getById: (id: number) => store.getById(storeKey, id) as T | null }
}

export const canGetAll = function <T extends iStorableItem>(storeKey: StoreKey) {
  return { getAll: () => store.getAll(storeKey) as T[] }
}

export const canFetch = function <T extends iStorableItem>(storeKey: StoreKey, baseUrl: string, transformInbound: TransformInbound<T> = baseTransformInbound) {
  return {
    /** Fetched items from the api and loads them into the store */
    async fetch(url = baseUrl, replaceAllItems = false) {
      const res = await axios.get(url);
      const items = extractItems(res, transformInbound);
      if (replaceAllItems === true) store.removeItems(storeKey, items);
      else store.upsertItems(storeKey, items);
    },
  }
}

export const canFetchIfEmpty = function <T extends iStorableItem>(storeKey: StoreKey, baseUrl: string, transformInbound: TransformInbound<T> = baseTransformInbound) {
  return {
    /** Check if there are any items stored, if none then fetches them and loads the items into the store */
    async fetchIfEmpty(url = baseUrl, replaceAllItems = false) {
      if (store.getAll(storeKey).length == 0) canFetch(storeKey, baseUrl, transformInbound).fetch(url, replaceAllItems);
    }
  }
}

export const canFetchById = function <T extends iStorableItem>(storeKey: StoreKey, baseUrl: string, transformInbound: TransformInbound<T> = baseTransformInbound) {
  return {
    /** Fetch a single item by id or slug and upsert it into the store */
    async fetchForId(idOrSlug: number | string, url?: string) {
      if (!url) url = `${baseUrl}/${idOrSlug}`
      const res = await axios.get(url);
      store.upsertItems(storeKey, [transformInbound(res.data)]);
      return res.data.id as number;
    }
  }
}

export const canFetchByIdIfEmpty = function <T extends iStorableItem>(storeKey: StoreKey, baseUrl: string, transformInbound: TransformInbound<T> = baseTransformInbound) {
  return {
    /** Check if that the item is in the store, if not fetch it and upsert it into the store */
    async fetchForIdIfEmpty(idOrSlug: number | string, url = baseUrl) {
      if (typeof idOrSlug === "number") {
        const found = store.getById(storeKey, idOrSlug)
        if (!found) return await canFetchById(storeKey, baseUrl, transformInbound).fetchForId(idOrSlug, url)
        else return found.id
      }
      else {
        const found = store.getBySlug(storeKey, idOrSlug)
        if (!found) return await canFetchById(storeKey, baseUrl, transformInbound).fetchForId(idOrSlug, url)
        else return found.id
      }
    }
  }
}

// Composite group
export const storeAble = <T extends iStorableItem>(storeKey: StoreKey, baseUrl: string, transformInbound: TransformInbound<T> = baseTransformInbound) => {
  return {
    ...canGetById<T>(storeKey),
    ...canGetAll<T>(storeKey),
    ...canFetch<T>(storeKey, baseUrl, transformInbound),
    ...canFetchIfEmpty<T>(storeKey, baseUrl, transformInbound),
    ...canFetchById<T>(storeKey, baseUrl, transformInbound),
    ...canFetchByIdIfEmpty<T>(storeKey, baseUrl, transformInbound),
  }
}

interface iCreateOptions {
  url?: string
  showMsgs?: boolean
  initMsg?: string
  successMsg?: string
  errorMsg?: string
}

export const canCreate = function <T extends iStorableItem, createType>(
  storeKey: StoreKey,
  baseUrl: string,
  transformInbound: TransformInbound<T> = baseTransformInbound,
  transformOutbound: TransformOutbound = baseTransformOutbound,
) {
  return {
    /** Sends a POST request to the api and upserts the results to the dataStore */
    async create(payload: createType, options?: iCreateOptions) {
      // Merge default options and optionally provided options
      const opts = Object.assign({}, {
        url: baseUrl,
        merge: false,
        showMsgs: true,
        initMsg: "Creating...",
        successMsg: "Successfully created",
        errorMsg: "There was an error while creating",
      }, options)

      if (opts.showMsgs) flash.info(opts.initMsg, `${storeKey}Create`)
      try {
        const res = await axios.post(opts.url, transformOutbound(payload));
        store.upsertItems(storeKey, [transformInbound(res.data)], false);
        if (opts.showMsgs) flash.success(opts.successMsg, `${storeKey}Create`)
      } catch (error) {
        if (opts.showMsgs) flash.error(opts.errorMsg, `${storeKey}Create`)
      }
    }
  }
}

interface iUpdateOptions {
  url?: string
  merge?: boolean
  showMsgs?: boolean
  initMsg?: string
  successMsg?: string
  errorMsg?: string
}
export const canUpdate = function <T extends iStorableItem, updateType>(
  storeKey: StoreKey,
  baseUrl: string,
  transformInbound: TransformInbound<T> = baseTransformInbound,
  transformOutbound: TransformOutbound = baseTransformOutbound,
) {
  return {
    /**
  * Sends a PATCH request to the api and upserts the results to the dataStore
  */
    async update(id: number, payload: updateType, options?: iUpdateOptions) {
      // Merge default options and optionally provided options
      const opts = Object.assign({}, {
        url: `${baseUrl}/${id}`,
        merge: false,
        showMsgs: true,
        initMsg: "Updating...",
        successMsg: "Successfully updated",
        errorMsg: "There was an error during the update",
      }, options)

      if (opts.showMsgs) flash.info(opts.initMsg, `${storeKey}Update`)
      try {
        const res = await axios.patch(opts.url, transformOutbound(payload));
        store.upsertItems(storeKey, [transformInbound(res.data)], opts.merge);
        if (opts.showMsgs) flash.success(opts.successMsg, `${storeKey}Update`)
      } catch (error) {
        if (opts.showMsgs) flash.error(opts.errorMsg, `${storeKey}Update`)
      }
    }
  }
}

interface iDeleteOptions {
  url?: string
  showMsgs?: boolean
  initMsg?: string
  successMsg?: string
  errorMsg?: string
}

export const canDelete = function <T extends iStorableItem>(storeKey: StoreKey, baseUrl: string) {
  return {
    /** Sends a DELETE request to the api and removes item from the dataStore */
    async delete(id: number, options?: iDeleteOptions) {
      const opts = Object.assign({}, {
        url: `${baseUrl}/${id}`,
        merge: false,
        initMsg: "Deleting...",
        successMsg: "Successfully deleted",
        errorMsg: "There was an error during the delete",
      }, options)

      if (opts.initMsg) flash.success(opts.initMsg, `${storeKey}Delete`)
      try {
        const res = await axios.delete(opts.url)
        store.removeItemsByIds(storeKey, [res.data as number]);
        if (opts.successMsg) flash.success(opts.successMsg, `${storeKey}Delete`)
      } catch (error) {
        if (opts.errorMsg) flash.error(opts.errorMsg, `${storeKey}Delete`)
      }
    }
  }
}

// ------------------------------------------------------------------------
// FilterSet Functions
// ------------------------------------------------------------------------

export const canGetFilterSet = function <T extends iStorableItem>() {
  return {
    getFilterSet(filterSetKey: FilterSetKey) {
      return store.getFilterSet(filterSetKey) as iFilterSetResult<T> | null;
    }
  }
}



interface iFetchSetOptions {
  url?: string // The url used for the request
  // forceFetch?: boolean // Don't look at the cache, always make the api call
  appendIds?: boolean // Append the set of ids or replace the set of ids
}
export const canFetchFilterSet = function <T extends iStorableItem>(storeKey: StoreKey, baseUrl: string, transformInbound: TransformInbound<T> = baseTransformInbound) {
  return {
    /** Fetch paginated data from the api and store the pagination into as a filter set and upsert the data into the store */
    async fetchFilterSet(filterSetKey: FilterSetKey, params: QueryParams = {}, options?: iFetchSetOptions) {
      // Merge default options and optionally provided options
      const opts = Object.assign({}, {
        url: baseUrl,
        appendIds: false,
      }, options)

      const url = `${opts.url}?${objToUrlSearchParams(params).toString()}`;
      const response = await axios.get(url);
      const items = extractItems<T>(response, transformInbound);
      store.upsertFilterSet(filterSetKey, params, storeKey, items, response.data?.pagination?.total ?? 0, opts.appendIds)
    }
  }
}

export const canFetchFilterSetIfDifferent = function <T extends iStorableItem>(storeKey: StoreKey, baseUrl: string, transformInbound: TransformInbound<T> = baseTransformInbound) {
  return {

    async fetchFilterSetIfDifferent(filterSetKey: FilterSetKey, params: QueryParams = {}, options?: iFetchSetOptions) {
      // Merge default options and optionally provided options
      const opts = Object.assign({}, {
        url: baseUrl,
        appendIds: false,
      }, options)

      const filterSet = store.getFilterSet(filterSetKey)

      // Fetch if no set exists
      if (!filterSet) {
        // console.log("NONE, no filterSet so fetching...", filterSet)
        await canFetchFilterSet<T>(storeKey, baseUrl, transformInbound).fetchFilterSet(filterSetKey, params, opts)
        return;
      }

      // Fetch if params are different
      const a = objToUrlSearchParams(filterSet.queryParams);
      const b = objToUrlSearchParams(params);
      if (a.toString() != b.toString()) {
        // console.log("CHANGED, filterSets don't match so fetching...", a.toString(), b.toString())
        await canFetchFilterSet<T>(storeKey, baseUrl, transformInbound).fetchFilterSet(filterSetKey, params, opts)
        return;
      }
      // console.log("MATCH, filterSets params are the same so doing nothing.", a.toString())
    }
  }
}

// Composite group
export const filterAble = <T extends iStorableItem>(storeKey: StoreKey, baseUrl: string, transformInbound: TransformInbound<T> = baseTransformInbound) => {
  return {
    ...canGetFilterSet<T>(),
    ...canFetchFilterSet<T>(storeKey, baseUrl, transformInbound),
    ...canFetchFilterSetIfDifferent<T>(storeKey, baseUrl, transformInbound),
  }
}

// ------------------------------------------------------------------------
// Utility functions
// ------------------------------------------------------------------------

/** Deserialize data arriving from the api */
export function baseTransformInbound<T>(data: any): T {
  Object.keys(data).forEach(key => {
    if (key.endsWith("Utc") && data[key]) data[key] = utcToLocal(data[key])
  });
  return data
}

/** Serialize data before sending it to the api */
export function baseTransformOutbound(data: any): any {
  Object.keys(data).forEach(key => {
    if (key.endsWith("Utc") && data[key]) data[key] = localToUtc(data[key])
  });
  return data
}

export interface iDataTableOptions {
  groupBy?: string[];
  groupDesc?: boolean[];
  itemsPerPage?: number;
  multiSort?: boolean;
  mustSort?: boolean;
  page?: number;
  sortBy?: string[];
  sortDesc?: boolean[];
}

/** Convert Vuetify data-table options into a standard format used by the api*/
export function tableOptionsToParams(options: iDataTableOptions) {
  const params: PaginatedQueryParams = {}
  if (options.page && options.itemsPerPage) params.skip = (options.page - 1) * options.itemsPerPage;
  if (options.itemsPerPage) params.limit = options.itemsPerPage > 0 ? options.itemsPerPage : undefined;
  if (options.sortBy) params.order = pascalToSnakeCase(options.sortBy?.length > 0 ? options.sortBy[0] : undefined);
  if (options.sortDesc) params.descending = options.sortDesc.length > 0 ? options.sortDesc[0] : undefined;
  return params
}

/** Convert PaginatedQueryParams into Vuetify data-table options */
export function paramsToTableOptions(params: PaginatedQueryParams) {
  const skip = (params.skip as number) || 0;
  const limit = (params.limit as number) || 0;
  const options: iDataTableOptions = {};

  options.page = skip / (limit || 1) + 1;
  if (params.limit !== undefined) options.itemsPerPage = params.limit as number;
  if (params.order !== undefined) options.sortBy = [snakeToCamelCase(params.order as string)];
  if (params.descending !== undefined) options.sortDesc = [params.descending as boolean];
  return options
}

/** Extract the items out of a possibly paginated response */
function extractItems<T>(res: { data: any }, transformInbound: TransformInbound<T> = baseTransformInbound): T[] {
  if (res.data.pagination !== undefined) {
    const key = Object.keys(res.data).filter(x => x != "pagination")[0];
    if (!Object.values(StoreKey).includes(key as any)) console.log(`Key '${key}' is not a store key`)
    if (!key) return []
    return res.data[key].map((x: any) => transformInbound(x));
  } else return res.data.map((x: any) => transformInbound(x));
}

/** Convert an object into a URLSearchParams */
function objToUrlSearchParams(obj: any) {
  const params = new URLSearchParams();
  Object.keys(obj).forEach((key) => {
    const val = obj[key as keyof typeof obj]
    if (val === undefined || val === null || val === "") return;
    if (Array.isArray(val)) val.forEach((v: any) => { params.append(key, v) })
    else params.append(key, val.toString())
  })
  return params
}