import { VueConstructor } from "vue/types/umd";
import { iStorableItem } from "./dataStore";

// Show flash notifications to the user

/*
    To use, in main.ts add:
    import store from "./store";
    import flashNotifications from "./plugins/flashNotifications";
    Vue.use(flashNotifications, { store });

    // In a component
    import { flash } from "@/plugins/flashNotifications";
    flash.info("Your msg!")
*/

export interface iNotification extends iStorableItem {
  id: number;
  level: NotificationLevel;
  message: string;
  group: string
}

export enum NotificationLevel {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

interface iNotificationState {
  notifications: iNotification[];
  nextId: number;
}

const CONTEXT = {
  store: {} as any, // The vuex store instance after installation
  displayTime: 10 * 1000, // seconds
};

export default {
  install(Vue: VueConstructor<Vue>, { store }: { store: any }) {
    if (!store) throw new Error("Please provide vuex store.");
    CONTEXT.store = store;

    const state: iNotificationState = {
      notifications: [],
      nextId: 0,
    };

    const mutations = {
      _flashNotifications_add(state: iNotificationState, notification: iNotification) {
        // Increment the "nextId" counter and add the notification
        state.nextId = state.nextId + 1;
        Vue.set(state.notifications, state.notifications.length, notification);
      },
      _flashNotifications_remove(state: iNotificationState, notification: iNotification) {
        const index = state.notifications.findIndex((x: iNotification) => x.id === notification.id);
        if (index >= 0) {
          state.notifications.splice(index, 1);
        }
      },
      _flashNotifications_removeAll(state: iNotificationState) {
        state.notifications = []
      },
      _flashNotifications_removeGrouped(state: iNotificationState, group: string) {
        state.notifications = state.notifications.filter(x => x.group != group);
      },
    };

    const getters = {
      _flashNotifications_getAll: (state: iNotificationState) => {
        return state.notifications as iNotification[]
      }
    }

    CONTEXT.store.registerModule("flashNotifications", {
      state,
      mutations,
      getters,
    });
  },
};

export const flash = {
  /** Display an 'Information' flash message */
  info(message: string, group = "default") { addNotification(message, NotificationLevel.INFO, group) },
  /** Display a 'Success' flash message */
  success(message: string, group = "default") { addNotification(message, NotificationLevel.SUCCESS, group) },
  /** Display a 'Warning' flash message */
  warning(message: string, group = "default") { addNotification(message, NotificationLevel.WARNING, group) },
  /** Display an 'Error' flash message */
  error(message: string, group = "default") { addNotification(message, NotificationLevel.ERROR, group) },

  /** Retrieve all flash messages */
  getAll(): iNotification[] { return CONTEXT.store.getters._flashNotifications_getAll },
  /** Remove all flash messages */
  clearAll() { CONTEXT.store.commit("_flashNotifications_removeAll"); },
  /** Remove all flash messages what have the same group */
  clearGroup(group = "default") { CONTEXT.store.commit("_flashNotifications_removeGrouped", group); },
  /** Remove a specific flash message */
  clear(notification: iNotification) { CONTEXT.store.commit("_flashNotifications_remove", notification); }
};

function addNotification(message: string, level: NotificationLevel, group = "default") {
  const notification: iNotification = { id: CONTEXT.store.state.flashNotifications.nextId, level, message, group }
  // We only want one notification per group
  CONTEXT.store.commit("_flashNotifications_removeGrouped", group);
  CONTEXT.store.commit("_flashNotifications_add", notification);

  // Set a timer to remove that notification
  setTimeout(() => {
    CONTEXT.store.commit("_flashNotifications_remove", notification);
  }, CONTEXT.displayTime);
}
