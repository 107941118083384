
import { iTicket, TicketStatus, alertTypeAbv } from "@/managers/tickets";
import DisplayMixin from "@/mixins/DisplayMixin";
import Vue from "vue";

export default Vue.extend({
  name: "AlertTypeChip",
  mixins: [DisplayMixin],
  components: {},
  props: {
    ticket: { type: Object as () => iTicket, required: true },
  },
  data: () => ({}),
  computed: {
    updatedTicket() {
      const newTicket = this.ticket;
      if (newTicket) return { ...newTicket, alertTypeAbv: alertTypeAbv(newTicket.alertType) } || {};
    },
  },
  methods: {
    alertLevelColor(ticket: iTicket): String {
      let cls = "ticket";
      cls += " ticket-" + ticket.alertLevel.toLowerCase();
      if (ticket.status == TicketStatus.RESOLVED) cls += " ticket-resolved";
      if (ticket.status == TicketStatus.CLOSED) cls += " ticket-closed";
      return cls;
    },
  },
  beforeMount() {},
});
