import { TicketFlag } from "./ticketActions";
import { AlertType } from "./tickets";

export enum SopType {
  INBOX = "INBOX",
  MAINTENANCE = "MAINTENANCE",
  DRIVER_DISCIPLINE = "DRIVER_DISCIPLINE",
  CUSTOMER_CARE = "CUSTOMER_CARE",
  TELEMETRY_PROVIDER = "TELEMETRY_PROVIDER",
  TRANSPORT_SCHEDULING = "TRANSPORT_SCHEDULING"
}
export enum componentNames {
  CheckHistory = "CheckHistory",
  CheckMap = "CheckMap",
  CheckVideo = "CheckVideo",
  ContactDriver = "ContactDriver",
  ContactStore = "ContactStore",
  ContactOthers = "ContactOthers",
  ConfirmDriverSafe = "ConfirmDriverSafe",
  Reason = "Reason",
  Description = "Description",
  SetFlags = "SetFlags",
  Resolve = "Resolve",
  ConfirmDeparture = "ConfirmDeparture",
  ConfirmDelayEnded = "ConfirmDelayEnded",
  ConfirmCommunicationRestored = "ConfirmCommunicationRestored",
  ConfirmBatterReconnected = "ConfirmBatterReconnected",
  MarkAsSeen = "MarkAsSeen",
  CloseTicket = "CloseTicket",
  CheckCustomerHistory = "CheckCustomerHistory",
  CheckDriverHistory = "CheckDriverHistory",
  CheckMaintenanceHistory = "CheckMaintenanceHistory",
  CheckTelemetryHistory = "CheckTelemetryHistory",
  CheckSchedulingHistory = "CheckSchedulingHistory",
  OngoingInvestigation = "OngoingInvestigation",
  ReassignFlag = "ReassignFlag",
  JobCardNumber = "JobCardNumber",
  CompleteRequiredFields = "CompleteRequiredFields",
  ConfirmEventReportSent = "ConfirmEventReportSent",
}

export interface iSop {
  description: string;
  steps: iSopStep[];
}

export interface iSopStep {
  component: componentNames;
  required: boolean;
  resolved?: boolean;
  reasons?: iReason[];
  unResolvedReason?: string | false;
  toContact?: iSopContact[];
  addOnSuccess?: iSopStep
  addOnFail?: iSopStep
  closingActions?: iClosingAction[]
}

export interface iSopContact {
  person: sopContacts
  info?: string
  required?: boolean
}

export interface iReason {
  text: string
  valid: boolean
  flags?: TicketFlag[]
  nextStep?: iSopStep;
  addSuggestion?: true;
}

export interface iClosingAction {
  text: string
  valid: boolean
}

export enum sopContacts {
  RISK_MANAGER = "Risk manager",
  RISK_CONTROLLER = "Risk controller",
  TRANSPORT_MANAGER = "Transport manager",
  CUSTOMER_CARE = "Customer care",
  TRACKER = "Tracker",
  STORE = "Store",
}

const STANDARD_REASONS: iReason[] = [
  { text: "Emergency", valid: true, nextStep: { component: componentNames.ContactOthers, required: true, toContact: [{ person: sopContacts.RISK_MANAGER, required: true }, { person: sopContacts.TRANSPORT_MANAGER, required: true }] } },
  { text: "Telemetry error", valid: true, flags: [TicketFlag.TELEMETRY_PROVIDER_FOLLOWUP] },
  { text: "Vehicle breakdown", valid: true, flags: [TicketFlag.MAINTENANCE] }
]

const STANDARD_SOP: iSop = {
  description: "Determine what has happened",
  steps: [
    { component: componentNames.CheckMap, required: false },
    { component: componentNames.CheckVideo, required: false },
    { component: componentNames.ContactDriver, required: true, addOnFail: { component: componentNames.ContactOthers, required: true, toContact: [{ person: sopContacts.TRANSPORT_MANAGER, required: true }] } },
    { component: componentNames.ConfirmDriverSafe, required: true },
    {
      component: componentNames.Reason, required: true, reasons: [...STANDARD_REASONS]
    },
    { component: componentNames.SetFlags, required: true },
    { component: componentNames.CompleteRequiredFields, required: false },
    { component: componentNames.Resolve, required: true },
  ]
}

const STOPPED_SOP: iSop = {
  description: "Determine why the vehicle has stopped?",
  steps: [
    { component: componentNames.CheckMap, required: false },
    { component: componentNames.CheckVideo, required: false },
    { component: componentNames.ContactDriver, required: true, addOnFail: { component: componentNames.ContactOthers, required: true, toContact: [{ person: sopContacts.TRANSPORT_MANAGER, required: true }] } },
    { component: componentNames.ConfirmDriverSafe, required: true },
    {
      component: componentNames.Reason, required: true, reasons: [
        ...STANDARD_REASONS,
        { text: "Road works", valid: true, flags: [] },
        { text: "Road works - Stop and Go", valid: true, flags: [] },
        { text: "Stopped at Weighbridge", valid: true, flags: [] },
        { text: "Long traffic light", valid: true, flags: [] },
        { text: "Heavy traffic", valid: true, flags: [] },
        { text: "Un geo-fenced rest area", valid: false, flags: [TicketFlag.DRIVER_DISCIPLINE] },
        { text: "Toilet stop", valid: false, flags: [TicketFlag.DRIVER_DISCIPLINE] },
      ]
    },
    { component: componentNames.SetFlags, required: true },
    { component: componentNames.CompleteRequiredFields, required: false },
    { component: componentNames.ConfirmDeparture, required: false },
    { component: componentNames.Resolve, required: true },
  ]
}

const DELAYED_SOP: iSop = {
  description: "Determine why there is a delay",
  steps: [
    { component: componentNames.CheckMap, required: false },
    { component: componentNames.CheckVideo, required: false },
    {
      component: componentNames.ContactStore, required: true, addOnFail: {
        component: componentNames.ContactDriver, required: true, addOnFail: {
          component: componentNames.ContactOthers, required: true, toContact: [{ person: sopContacts.CUSTOMER_CARE, required: true }]
        }
      }
    },
    { component: componentNames.ConfirmDriverSafe, required: true },
    {
      component: componentNames.Reason, required: true, reasons: [
        { text: "Telemetry error", valid: true, flags: [TicketFlag.TELEMETRY_PROVIDER_FOLLOWUP] },
        { text: "Vehicle breakdown", valid: true, flags: [TicketFlag.MAINTENANCE] },
        { text: "Store not open", valid: true, flags: [TicketFlag.CUSTOMER_CARE] },
        { text: "Busy with supplier", valid: true, flags: [TicketFlag.CUSTOMER_CARE] },
        { text: "Consolidating assets for returns", valid: true, flags: [TicketFlag.CUSTOMER_CARE] },
        { text: "Preparing receiving area", valid: true, flags: [TicketFlag.CUSTOMER_CARE] },
        { text: "Vehicle arrived early", valid: true, flags: [TicketFlag.TRANSPORT_SCHEDULING] },
        { text: "Balance truck", valid: true, flags: [] },
        { text: "Truck late", valid: true, flags: [] },
        { text: "Slow offloading at store", valid: false, flags: [] },
      ]
    },
    { component: componentNames.SetFlags, required: true },
    { component: componentNames.CompleteRequiredFields, required: false },
    { component: componentNames.ConfirmDelayEnded, required: true },
    { component: componentNames.Resolve, required: true },
  ]
}

const MARK_AS_SEEN_SOP: iSop = {
  description: "Mark as seen",
  steps: [
    { component: componentNames.MarkAsSeen, required: true },
    { component: componentNames.CompleteRequiredFields, required: false },
    { component: componentNames.Resolve, required: true },
  ]
}

const SPEEDING_SOP: iSop = {
  description: "Determine why the driver is speeding?",
  steps: [
    { component: componentNames.CheckMap, required: false },
    { component: componentNames.CheckVideo, required: false },
    { component: componentNames.ContactDriver, required: true, addOnFail: { component: componentNames.ContactOthers, required: true, toContact: [{ person: sopContacts.TRANSPORT_MANAGER, required: true }] } },
    { component: componentNames.ConfirmDriverSafe, required: true },
    {
      component: componentNames.Reason, required: true, reasons: [...STANDARD_REASONS, { text: "Driver was speeding", valid: false, flags: [TicketFlag.DRIVER_DISCIPLINE] }]
    },
    { component: componentNames.SetFlags, required: true },
    { component: componentNames.CompleteRequiredFields, required: false },
    { component: componentNames.Resolve, required: true },
  ]
}

const ESEAL_SOP_STEPS: iSopStep[] = [
  { component: componentNames.CheckMap, required: false },
  { component: componentNames.CheckVideo, required: false },
  { component: componentNames.ContactDriver, required: true, addOnFail: { component: componentNames.ContactOthers, required: true, toContact: [{ person: sopContacts.TRANSPORT_MANAGER, required: true }] } },
  { component: componentNames.ConfirmDriverSafe, required: true },
  {
    component: componentNames.Reason, required: true, reasons: [
      { text: "Tampered lock", valid: false, flags: [TicketFlag.DRIVER_DISCIPLINE] },
      { text: "Lock faulty - unable to open with OTP / RFID card", valid: true, flags: [TicketFlag.TELEMETRY_PROVIDER_FOLLOWUP] },
    ]
  },
  { component: componentNames.SetFlags, required: true },
  { component: componentNames.CompleteRequiredFields, required: false },
  { component: componentNames.Resolve, required: true },
]

export const SOP: Record<AlertType, iSop> = {

  PANIC_ALARM: {
    description: "Determine what the emergency is?",
    steps: [
      { component: componentNames.CheckMap, required: false },
      { component: componentNames.CheckVideo, required: false },
      { component: componentNames.ContactDriver, required: true, addOnFail: { component: componentNames.ContactOthers, required: true, toContact: [{ person: sopContacts.TRANSPORT_MANAGER, required: true }] } },
      { component: componentNames.ConfirmDriverSafe, required: true },
      {
        component: componentNames.Reason, required: true, reasons: [
          ...STANDARD_REASONS,
          { text: "Testing panic button", valid: true, flags: [] },
          { text: "Vehicle at workshop", valid: true, flags: [] },
          { text: "Driver accidentally pressed panic button", valid: false, flags: [TicketFlag.DRIVER_DISCIPLINE] },
        ]
      },
      { component: componentNames.SetFlags, required: true },
      { component: componentNames.CompleteRequiredFields, required: false },
      { component: componentNames.Resolve, required: true },
    ]
  },
  ESEAL_CABLE_CUT: { description: "Determine why the eSeal cable has been cut?", steps: ESEAL_SOP_STEPS },
  ESEAL_COVER_OPEN: { description: "Determine why the eSeal back cover has been opened?", steps: ESEAL_SOP_STEPS },
  ESEAL_UNLOCKED: { description: "Determine why the eSeal locking cable has been released?", steps: ESEAL_SOP_STEPS },
  ESEAL_LOW_BATTERY: { description: "Determine why the eSeal battery has dropped blow 40%?", steps: ESEAL_SOP_STEPS },
  UNAUTHORIZED_STOP: STOPPED_SOP,
  UNAUTHORIZED_STOP_DEPARTURE: MARK_AS_SEEN_SOP,
  UNAUTHORIZED_DOOR_OPEN: Object.assign({}, STANDARD_SOP, { description: "Determine why the door is open?" }),
  MOUNTAIN_PASS_SPEEDING: Object.assign({}, SPEEDING_SOP, { description: "Determine why the driver is speeding on a mountain pass?" }),
  SPEEDING: Object.assign({}, SPEEDING_SOP),
  TURNAROUND_DELAY: DELAYED_SOP,
  TURNAROUND_DELAY_DEPARTURE: MARK_AS_SEEN_SOP,
  PREOFFLOAD_DELAY: DELAYED_SOP,
  PREOFFLOAD_DELAY_END: MARK_AS_SEEN_SOP,
  REST_STOP_DELAY: {
    description: "Determine why the vehicle has stopped?",
    steps: [
      { component: componentNames.CheckMap, required: false },
      { component: componentNames.CheckVideo, required: false },
      { component: componentNames.ContactDriver, required: true, addOnFail: { component: componentNames.ContactOthers, required: true, toContact: [{ person: sopContacts.TRANSPORT_MANAGER, required: true }] } },
      { component: componentNames.ConfirmDriverSafe, required: true },
      {
        component: componentNames.Reason, required: true, reasons: [
          ...STANDARD_REASONS,
          { text: "Toilet stop", valid: false, flags: [] },
        ]
      },
      { component: componentNames.SetFlags, required: true },
      { component: componentNames.CompleteRequiredFields, required: false },
      { component: componentNames.ConfirmDeparture, required: false },
      { component: componentNames.Resolve, required: true },
    ]
  },
  REST_STOP_DELAY_DEPARTURE: MARK_AS_SEEN_SOP,
  EXCESSIVE_TEMPERATURE_SPIKE: {
    description: "Determine why the temperature has spiked?",
    steps: [
      { component: componentNames.CheckMap, required: false },
      { component: componentNames.CheckVideo, required: false },
      { component: componentNames.ContactDriver, required: true, addOnFail: { component: componentNames.ContactOthers, required: true, toContact: [{ person: sopContacts.TRANSPORT_MANAGER, required: true }] } },
      {
        component: componentNames.Reason, required: true, reasons: [
          { text: "Vehicle breakdown", valid: true, flags: [TicketFlag.MAINTENANCE] },
          { text: "Vehicle at workshop", valid: true, flags: [] },
          { text: "Doors left open at store", valid: true, flags: [TicketFlag.CUSTOMER_CARE] },
        ]
      },
      { component: componentNames.SetFlags, required: true },
      { component: componentNames.CompleteRequiredFields, required: false },
      { component: componentNames.Resolve, required: true },
    ]
  },
  EXCESSIVE_IDLING: {
    description: "Determine why the driver is idling excessively?",
    steps: [
      { component: componentNames.CheckMap, required: false },
      { component: componentNames.CheckVideo, required: false },
      { component: componentNames.ContactDriver, required: true, addOnFail: { component: componentNames.ContactOthers, required: true, toContact: [{ person: sopContacts.TRANSPORT_MANAGER, required: true }] } },
      {
        component: componentNames.Reason, required: true, reasons: [
          { text: "Vehicle at workshop", valid: true, flags: [] },
          { text: "Road works", valid: true, flags: [] },
          { text: "Road works - Stop and Go", valid: true, flags: [] },
          { text: "Long traffic light", valid: true, flags: [] },
          { text: "Heavy traffic", valid: true, flags: [] },
          { text: "Shunting", valid: true, flags: [] },
          { text: "Air Leak, building air", valid: true, flags: [TicketFlag.MAINTENANCE] },
          { text: "Driver at fault", valid: false, flags: [TicketFlag.DRIVER_DISCIPLINE] },
        ]
      },
      { component: componentNames.SetFlags, required: true },
      { component: componentNames.CompleteRequiredFields, required: false },
      { component: componentNames.Resolve, required: true },
    ]
  },
  COMMUNICATION_LOSS: {
    description: "Determine why the communication signal was lost?",
    steps: [
      { component: componentNames.CheckMap, required: false },
      { component: componentNames.CheckVideo, required: false },
      { component: componentNames.ContactDriver, required: true, addOnFail: { component: componentNames.ContactOthers, required: true, toContact: [{ person: sopContacts.TRANSPORT_MANAGER, required: true }] } },
      {
        component: componentNames.Reason, required: true, reasons: [
          { text: "Vehicle at workshop", valid: true, flags: [] },
          { text: "No signal, driving through remote area", valid: true, flags: [] },
          { text: "Vehicle breakdown", valid: true, flags: [TicketFlag.MAINTENANCE] },
          { text: "Sensor malfunction", valid: false, flags: [TicketFlag.MAINTENANCE, TicketFlag.TELEMETRY_PROVIDER_FOLLOWUP] },
        ]
      },
      { component: componentNames.ConfirmCommunicationRestored, required: false },
      { component: componentNames.SetFlags, required: true },
      { component: componentNames.CompleteRequiredFields, required: false },
      { component: componentNames.Resolve, required: true },
    ]
  },
  COMMUNICATION_LOSS_RESTORED: MARK_AS_SEEN_SOP,
  EXTERNAL_BATTERY_DISCONNECTION: {
    description: "Determine why the external battery was disconnected?",
    steps: [
      { component: componentNames.CheckMap, required: false },
      { component: componentNames.CheckVideo, required: false },
      { component: componentNames.ContactDriver, required: true, addOnFail: { component: componentNames.ContactOthers, required: true, toContact: [{ person: sopContacts.TRANSPORT_MANAGER, required: true }] } },
      {
        component: componentNames.Reason, required: true, reasons: [
          { text: "Vehicle at workshop", valid: true, flags: [] },
          { text: "Vehicle breakdown", valid: true, flags: [TicketFlag.MAINTENANCE] },
          { text: "Sensor fault", valid: true, flags: [TicketFlag.TELEMETRY_PROVIDER_FOLLOWUP] }
        ]
      },
      { component: componentNames.ConfirmBatterReconnected, required: false },
      { component: componentNames.SetFlags, required: true },
      { component: componentNames.CompleteRequiredFields, required: false },
      { component: componentNames.Resolve, required: true },
    ]
  },
  EXTERNAL_BATTERY_DISCONNECTION_RESTORED: MARK_AS_SEEN_SOP,
}

const CLOSING_DRIVER_DISCIPLINE_SOP: iSop = {
  description: "Has this driver discipline issue been resolved",
  steps: [
    { component: componentNames.CheckDriverHistory, required: true },
    { component: componentNames.OngoingInvestigation, required: true },
    { component: componentNames.ReassignFlag, required: true },
    {
      component: componentNames.CloseTicket, required: true, closingActions: [
        { text: "Driver not at fault", valid: true },
        { text: "Counselling", valid: true },
        { text: "Verbal warning", valid: true },
        { text: "1st written warning", valid: true },
        { text: "2nd written warning", valid: true },
        { text: "Final written warning", valid: true },
      ]
    },
  ]
}
const CLOSING_CUSTOMER_CARE_SOP: iSop = {
  description: "Has this customer care issue been resolved",
  steps: [
    { component: componentNames.CheckCustomerHistory, required: true },
    { component: componentNames.OngoingInvestigation, required: true },
    { component: componentNames.ReassignFlag, required: true },
    {
      component: componentNames.CloseTicket, required: true, closingActions: [
        { text: "Store not at fault", valid: true },
        { text: "Store contacted", valid: true },
        { text: "Store visit scheduled", valid: true },
        { text: "Store meeting setup with DC management", valid: true },
      ]
    },
  ]
}
const CLOSING_MAINTENANCE_SOP: iSop = {
  description: "Has this maintenance issue been resolved",
  steps: [
    { component: componentNames.CheckMaintenanceHistory, required: true },
    { component: componentNames.JobCardNumber, required: true },
    { component: componentNames.OngoingInvestigation, required: true },
    { component: componentNames.ReassignFlag, required: true },
    {
      component: componentNames.CloseTicket, required: true, closingActions: [
        { text: "Vehicle has no maintenance faults", valid: true },
        { text: "Part fixed", valid: true },
        { text: "Part replaced", valid: true },
      ]
    },
  ]
}
const CLOSING_TELEMETRY_PROVIDER_SOP: iSop = {
  description: "Has this telemetry issue been resolved",
  steps: [
    { component: componentNames.CheckTelemetryHistory, required: true },
    { component: componentNames.OngoingInvestigation, required: true },
    { component: componentNames.ReassignFlag, required: true },
    {
      component: componentNames.CloseTicket, required: true, closingActions: [
        { text: "Vehicle has no telemetry faults", valid: true },
        { text: "Hardware fixed", valid: true },
        { text: "Hardware replaced", valid: true },
        { text: "Software updated", valid: true },
      ]
    },
  ]
}
const CLOSING_TRANSPORT_SCHEDULING_SOP: iSop = {
  description: "Has this telemetry issue been resolved",
  steps: [
    { component: componentNames.CheckSchedulingHistory, required: true },
    { component: componentNames.OngoingInvestigation, required: true },
    { component: componentNames.ReassignFlag, required: true },
    {
      component: componentNames.CloseTicket, required: true, closingActions: [
        { text: "The schedule remains", valid: true },
        { text: "The schedule has been adjusted", valid: true },
      ]
    },
  ]
}
const No_SOP: iSop = {
  description: "No SOP for this alert type",
  steps: []
}

export function getSop(alertType: AlertType, sopType: SopType): iSop {
  if (sopType === SopType.INBOX)
    return SOP[alertType]
  if (sopType === SopType.DRIVER_DISCIPLINE)
    return CLOSING_DRIVER_DISCIPLINE_SOP
  if (sopType === SopType.CUSTOMER_CARE)
    return CLOSING_CUSTOMER_CARE_SOP
  if (sopType === SopType.MAINTENANCE)
    return CLOSING_MAINTENANCE_SOP
  if (sopType === SopType.TELEMETRY_PROVIDER)
    return CLOSING_TELEMETRY_PROVIDER_SOP
  if (sopType === SopType.TRANSPORT_SCHEDULING)
    return CLOSING_TRANSPORT_SCHEDULING_SOP
  return No_SOP
}
