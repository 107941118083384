
import Vue from "vue";
import { mgr } from "@/managers";
import { iTicket } from "@/managers/tickets";
import { iSopStep } from "@/managers/sop";
import { ActionType } from "@/managers/ticketActions";

export default Vue.extend({
  name: "JobCardNumber",
  components: {},
  props: {
    step: { type: Object as () => iSopStep, required: true },
    ticket: { type: Object as () => iTicket, required: true },
  },
  data: () => ({
    jobCardNumber: null as null | number,
  }),
  computed: {
    actionTypes(): ActionType[] {
      return this.ticket.actions.map((x) => x.actionType);
    },
    isResolved(): boolean {
      if (this.actionTypes.includes(ActionType.CLOSED)) return true;
      if (this.actionTypes.includes(ActionType.MAINTENANCE_JOB_CARD_ADDED)) return true;
      return false;
    },
    canSubmit(): boolean {
      if (this.isResolved) return false;
      if (this.jobCardNumber && this.jobCardNumber > 0) return true;
      return false;
    },
  },
  methods: {
    submitJobCardNum() {
      if (this.isResolved) return;
      if (this.jobCardNumber) mgr.ticketActions.jobCardNumberAdded(this.ticket.id, this.jobCardNumber);
    },
    updateStep() {
      if (this.isResolved) {
        this.$emit("updateStep", { step: this.step, resolved: true });
        const action = this.ticket.actions.find((x) => x.actionType == ActionType.MAINTENANCE_JOB_CARD_ADDED);
        if (action) {
          this.jobCardNumber = action.data.job_card_number;
        }
      } else {
        this.$emit("updateStep", {
          step: this.step,
          resolved: false,
          unResolvedReason: "Job card number not yet added",
        });
      }
    },
  },
  watch: {
    actionTypes() {
      this.updateStep();
    },
  },
  mounted() {
    this.updateStep();
  },
});
