
import Vue from "vue";
import { mgr } from "@/managers";
import { flash, iNotification } from "@/plugins/flashNotifications";
import { auth } from "@/plugins/auth";
import { sockets } from "@/plugins/sockets";
import router from "@/router";
import { AlertType, TicketStatus } from "@/managers/tickets";
// import TimeMixin from "@/mixins/TimeMixin";

export default Vue.extend({
  name: "AppTopBar",
  // mixins: [TimeMixin],
  components: {},
  props: { value: { type: Boolean, default: true } },
  data: () => ({
    showUserInfo: false,
    showMNotifications: false,
    showOnlineUserInfo: false,
  }),
  computed: {
    isSignedIn() {
      return auth.isSignedIn();
    },
    isAdmin() {
      return auth.isAdmin();
    },
    user() {
      return auth.currentUser();
    },
    connected() {
      return sockets.isConnected();
    },
    status() {
      const status = sockets.getStatus();
      switch (status) {
        case "Connecting":
          return { msg: status, color: "warning", icon: "rotate_right" };
        case "Connected":
          return { msg: status, color: "", icon: "wifi" };
        case "Disconnecting":
          return { msg: status, color: "warning", icon: "rotate_left" };
        case "Unauthorized":
          return { msg: status, color: "error", icon: "wifi_lock" };
        default:
          return { msg: "Disconnected", color: "error", icon: "wifi_off" };
      }
    },
    usersOnline() {
      return sockets.getConnections().map((x) => x.username);
    },
    notifications() {
      return mgr.notifications.getAll();
    },
    newPanicAlerts(): boolean {
      return mgr.tickets.getAll().some((x) => x.alertType === AlertType.PANIC_ALARM && x.status === TicketStatus.NEW);
    },
  },
  methods: {
    signOut() {
      this.showUserInfo = false;
      auth.signOut();
    },
    reCreateConnection() {
      if (!sockets.isConnected()) {
        const user = auth.currentUser();
        if (user) sockets.createConnection(user.id);
        else flash.warning("You need to sign in first to connect");
      }
    },
    clearNotification(notification: iNotification) {
      mgr.notifications.clear(notification);
    },
    clearAllNotifications() {
      mgr.notifications.clearAll();
    },
    goToTicket(notification: iNotification) {
      if (router.currentRoute.path != `/inbox/${notification.id}`) router.replace(`/inbox/${notification.id}`);
      mgr.notifications.clear(notification);
    },
  },
  beforeMount() {},
});
