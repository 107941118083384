import { iNotification, NotificationLevel } from "@/plugins/flashNotifications";
import { StoreKey, store } from "@/plugins/dataStore";
import { iTicket } from "./tickets";
import { screamingSnakeToRegularCase } from "@/utils"

const alertLevelToNotificationLevelMapping = {
  CRITICAL: NotificationLevel.ERROR,
  IMPORTANT: NotificationLevel.INFO,
  NORMAL: NotificationLevel.INFO,
}


export const notifications = {
  addFromTicket(ticket: iTicket) {
    const notification: iNotification = {
      id: ticket.id,
      level: alertLevelToNotificationLevelMapping[ticket.alertLevel],
      message: `New ticket: ${screamingSnakeToRegularCase(ticket.alertType)}`,
      group: "newTicket"
    }
    store.upsertItems(StoreKey.NOTIFICATIONS, [notification])
  },
  add(notification: iNotification) {
    store.upsertItems(StoreKey.NOTIFICATIONS, [notification])
  },
  clear(notification: iNotification) {
    store.removeItems(StoreKey.NOTIFICATIONS, [notification])
  },
  clearAll() {
    store.removeItems(StoreKey.NOTIFICATIONS, [])
  },
  getAll() {
    return store.getAll(StoreKey.NOTIFICATIONS) as iNotification[]
  }
}
