
import Vue from "vue";
import { ActionType } from "@/managers/ticketActions";
import { iTicket } from "@/managers/tickets";
import TimeMixin from "@/mixins/TimeMixin";
import { mgr } from "@/managers";
export default Vue.extend({
  name: "TicketNoteAdder",
  mixins: [TimeMixin],
  components: {},
  props: {
    ticket: { type: Object as () => iTicket, required: true },
  },
  data: () => ({
    note: "",
    noteFormIsValid: false,
  }),
  computed: {
    notes() {
      const ticket = this.ticket as iTicket | null;
      if (!ticket) return [];
      return ticket.actions.filter((x) => x.actionType == ActionType.NOTE_ADDED).reverse();
    },
  },
  methods: {
    addNote() {
      const ticket = this.ticket as iTicket | null;
      if (!ticket) return;
      if (this.note == "") {
        (this.$refs["note-form"] as any).resetValidation();
        return;
      }
      if (!(this.$refs["note-form"] as any).validate()) return;
      mgr.ticketActions.noteAdded(ticket.id, this.note);
      this.note = "";
      (this.$refs["note-form"] as any).resetValidation();
    },
  },
  beforeMount() {},
});
