
import { TicketFlag } from "@/managers/ticketActions";
import DisplayMixin from "@/mixins/DisplayMixin";
import Vue from "vue";

export default Vue.extend({
  name: "FlagsDisplay",
  mixins: [DisplayMixin],
  props: {
    value: { type: Array as () => TicketFlag[], default: () => [] },
    outlined: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
  },
});
