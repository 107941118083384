import { formatDistance, parseISO } from 'date-fns';
import { kvStore, kvStoreKeys } from './plugins/keyValueStore';

// Time and date
export function utcToLocal(dateTime: string): string {
  if (!dateTime) return ""
  if (dateTime.toLowerCase().includes("gmt")) dateTime = new Date(dateTime).toISOString();
  if (!dateTime.toLowerCase().endsWith("z")) dateTime = dateTime + "Z"
  const localDate = parseISO(dateTime);
  return localDate.toISOString();
}

export function localToUtc(dateTime: string): string {
  if (!dateTime) return ""
  const localDate = parseISO(dateTime);
  return localDate.toUTCString()
}

export function timeAgo(date: Date | number | string) {
  if (typeof date === "string") date = new Date(date)
  if (!date) return ""
  return formatDistance(date, kvStore.get(kvStoreKeys.timeAgoBaseDate)).replace("about ", "").replace("less than ", "") + " ago";
}

// URLSearchParams

export function objToUrlSearchParams(obj: any) {
  const params = new URLSearchParams();
  Object.keys(obj).forEach((key) => {
    const val = obj[key as keyof typeof obj]
    if (val === undefined || val === null || val === "") return;
    if (Array.isArray(val)) {
      val.forEach((v: any) => { params.append(key, v) })
    }
    else {
      params.append(key, val.toString())
    }
  })
  return params
}

export function urlSearchParamsToObj(urlParams: URLSearchParams) {
  const result: any = {}
  for (const [key, value] of urlParams.entries()) {
    if (result[key]) result[key] = [result[key], value]
    else result[key] = value;
  }
  return result;
}

// Case converts

export function pascalToSnakeCase(str?: string) {
  if (!str) return "";
  return str
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
};
export function snakeToCamelCase(str?: string) {
  if (!str) return "";
  return str.replace(/([-_]\w)/g, g => g[1].toUpperCase());
}
export function snakeToPascalCase(str?: string) {
  if (!str) return "";
  const camelCase = snakeToCamelCase(str);
  const pascalCase = camelCase[0].toUpperCase() + camelCase.substr(1);
  return pascalCase;
}
export function screamingSnakeToRegularCase(str?: string) {
  if (!str) return "";
  const lowerCase = str
    .split("_")
    .join(" ")
    .toLowerCase();
  const regularCase = lowerCase[0].toUpperCase() + lowerCase.substr(1);
  return regularCase;
};