
import Vue from "vue";
import { flash } from "@/plugins/flashNotifications";

export default Vue.extend({
  name: "InlineInput",
  components: {},
  props: {
    value: { type: Number || String, default: "" },
    type: { type: String, default: "v-text-field" },
    label: { type: String, default: "Edit" },
    suffix: { type: String, default: "" },
    rules: { type: Array, default: (() => []) as () => any[] },
    disabled: { type: Boolean, default: true },
  },
  data() {
    return {
      editing: false,
      internalValue: "" as string | number,
      hasError: false,
    };
  },
  methods: {
    save() {
      if (this.hasError) {
        flash.warning("Invalid input");
        return;
      }
      this.editing = false;
      this.$emit("input", this.internalValue);
    },
    close() {
      this.editing = false;
      this.internalValue = this.value;
    },
    onOpen() {
      this.internalValue = this.value;
    },
    toggleMode() {
      if (this.editing) this.editing = false;
      else {
        this.editing = true;
        this.onOpen();
      }
    },
  },
});
