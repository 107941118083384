
import { iTicket } from "@/managers/tickets";
import { screamingSnakeToRegularCase } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "TicketHeader",
  props: {
    previousTo: { type: String, default: "/" },
    previousName: { type: String, default: "" },
    ticket: { type: Object as () => iTicket },
  },
  computed: {
    currentName(): string {
      let name = "";
      if (!this.ticket) return name;
      name += "#" + this.ticket.id + " - ";
      name += screamingSnakeToRegularCase(this.ticket.alertType);
      const driver = this.ticket.events[0]?.driverName;
      if (driver) name += ` for ${driver}`;
      return name;
    },
  },
});
