
import Vue from "vue";
import router from "@/router";
import TimeMixin from "@/mixins/TimeMixin";
import { mgr } from "@/managers";
import { flash } from "@/plugins/flashNotifications";
import { auth } from "@/plugins/auth";
import { iUser } from "@/managers/users";
import ConfirmationCard from "./ConfirmationCard.vue";
import { Role } from "@/managers/roles";
import AlertTypeChip from "@/components/utils/AlertTypeChip.vue";
import { AlertLevel, iTicket } from "@/managers/tickets";
import { timeAgo } from "@/utils";

interface iRow extends iTicket {
  latestEventAlertUtc?: string;
}

export default Vue.extend({
  name: "InboxTicketTable",
  mixins: [TimeMixin],
  components: { ConfirmationCard, AlertTypeChip },
  props: {
    title: { type: String, default: "Tickets" },
    tickets: { type: Array as () => iTicket[], required: true },
    loading: { type: Boolean, default: false },
    serverItemsLength: { type: Number, default: -1 },
    hideExtra: { type: Boolean, default: false },
    showAssignedUtc: { type: Boolean, default: false },
  },
  data: () => ({
    groupBy: null as null | string,
    ticketToAssign: null as null | iTicket,
    ticketToUnAssign: null as null | iTicket,
    options: {
      sortBy: ["createdUtc"],
      sortDesc: [true],
    } as {
      groupBy: string[];
      groupDesc: boolean[];
      itemsPerPage: number;
      multiSort: boolean;
      mustSort: boolean;
      page: number;
      sortBy: string[];
      sortDesc: boolean[];
    },
    extraColumns: ["Driver", "Vehicle", "Store"],
    selectedColumns: ["Driver", "Vehicle"],
  }),
  computed: {
    headers() {
      const headers = [
        { text: "No.", value: "id", width: "3ch", sortable: false },
        { text: "Ticket created", value: "createdUtc" },
        { text: "Latest event", value: "latestEventAlertUtc" },
        { text: "Alert type", value: "alertType" },
        { text: "Assigned", value: "assignedUser", align: "right" },
      ];
      if (this.showAssignedUtc) headers[1].text = "Ticket assigned";
      if (this.selectedColumns.includes("Driver"))
        headers.splice(headers.length - 1, 0, { text: "Driver", value: "driverName" });
      if (this.selectedColumns.includes("Vehicle"))
        headers.splice(headers.length - 1, 0, { text: "Vehicle", value: "vehicles" });
      if (this.selectedColumns.includes("Store"))
        headers.splice(headers.length - 1, 0, { text: "Store", value: "stores" });
      if (!this.hideExtra) return headers;
      return headers.filter(
        (x) => x.value !== "driverName" && x.value !== "vehicles" && x.value !== "latestEventAlertUtc"
      );
    },
    tableData(): iRow[] {
      return this.tickets.map((ticket: iRow) => {
        if (ticket.events.length > 0) {
          const latestEvent = ticket.events[0];
          const latestEventAlertUtc = timeAgo(latestEvent.alertUtc);
          ticket.latestEventAlertUtc = latestEventAlertUtc == timeAgo(ticket.createdUtc) ? "-" : latestEventAlertUtc;
        } else ticket.latestEventAlertUtc = "No events";

        return ticket;
      });
    },
    criticalTickets() {
      return this.tickets.filter((x) => x.alertLevel == AlertLevel.CRITICAL);
    },
    importantTickets() {
      return this.tickets.filter((x) => x.alertLevel == AlertLevel.IMPORTANT);
    },
    normalTickets() {
      return this.tickets.filter((x) => x.alertLevel == AlertLevel.NORMAL);
    },
    assignableUsers() {
      return mgr.users
        .getAll()
        .filter((x) =>
          x.roles
            .map((r) => r.name)
            .some((r) =>
              [
                Role.OPERATOR,
                Role.DRIVER_DISCIPLINE_OPERATOR,
                Role.CUSTOMER_CARE_OPERATOR,
                Role.MAINTENANCE_OPERATOR,
              ].includes(r)
            )
        );
    },
    isAdmin() {
      return auth.isAdmin();
    },
    showDrivers() {
      return this.selectedColumns.includes("Driver");
    },
    showVehicles() {
      return this.selectedColumns.includes("Vehicle");
    },
    showStores() {
      return this.selectedColumns.includes("Store");
    },
  },
  methods: {
    openTicket(ticket: iTicket) {
      router.replace(`/inbox/${ticket.id}`);
    },
    claimTicket(ticket: iTicket) {
      const user = auth.currentUser();
      if (!user) return;
      flash.info("Assigning tickets to yourself...");
      mgr.ticketActions.claimBy(ticket.id)?.then(() => {
        flash.success("Ticket assigned to you");
        this.$emit("updateNeeded");
      });
    },
    openAssignUserDialog(ticket: iTicket) {
      if (this.assignableUsers.length === 0) mgr.users.fetch();
      this.ticketToAssign = ticket;
    },
    assignTicket(ticket: iTicket, assignedUser: iUser) {
      const user = auth.currentUser();
      if (!assignedUser || !ticket || !user) return;
      flash.info(`Assigning tickets to ${assignedUser.username}...`);
      this.ticketToAssign = null;
      mgr.ticketActions.assignTo(ticket.id, assignedUser.id, assignedUser.username)?.then(() => {
        flash.success(`Ticket assigned to ${assignedUser.username}`);
        this.$emit("updateNeeded");
      });
    },
    unAssignTicket(ticket: iTicket) {
      const user = auth.currentUser();
      if (!ticket || !user || !ticket.assignedUser) return;
      flash.info(`Un-assigning ticket...`);
      this.ticketToUnAssign = null;
      mgr.ticketActions.unAssign(ticket.id)?.then(() => {
        flash.success(`Ticket un-assigned`);
        this.$emit("updateNeeded");
      });
    },
    alertLevelColor(level: AlertLevel): String {
      return "ticket-" + level.toLowerCase();
    },
    isAssignedToMe(ticket: iTicket) {
      return ticket.assignedUser?.id == auth.currentUser()?.id;
    },
    firstDriver(ticket: iTicket) {
      return ticket.events.find((x) => x.driverName)?.driverName ?? "-";
    },
    firstVehicle(ticket: iTicket) {
      return ticket.events.find((x) => x.vehicleCode)?.vehicleCode ?? "-";
    },
    firstStore(ticket: iTicket) {
      return ticket.events.find((x) => x.storeName)?.storeName ?? "-";
    },
  },
  watch: {
    options: {
      handler() {
        this.$emit("optionsChanged", this.options);
      },
      deep: true,
    },
  },
  beforeMount() {},
});
