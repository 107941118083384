
import { mgr } from "@/managers";
import { iTicket } from "@/managers/tickets";
import DisplayMixin from "@/mixins/DisplayMixin";
import TimeMixin from "@/mixins/TimeMixin";
import axios from "axios";
import Vue from "vue";

export default Vue.extend({
  name: "RelatedTickets",
  mixins: [TimeMixin, DisplayMixin],
  components: {},
  props: {
    ticket: { type: Object as () => iTicket },
  },
  data: () => ({
    driver: [] as iTicket[],
    vehicle: [] as iTicket[],
    store: [] as iTicket[],
    showDialog: false,
    dialogTicket: null as null | iTicket,
  }),
  computed: {},
  methods: {
    openTicket(ticket: iTicket) {
      if (this.dialogTicket?.id === ticket.id) this.showDialog = true;
      else
        axios
          .get(`/app/tickets/${ticket.id}`)
          .then((res) => res.data)
          .then((data: iTicket) => {
            this.dialogTicket = mgr.tickets.transformInbound(data);
            this.showDialog = true;
          });
    },
  },
  beforeMount() {
    if (this.ticket)
      axios
        .get(`/app/tickets/related/${this.ticket.id}`)
        .then((res) => res.data)
        .then((data) => {
          this.driver = data.driver.map((x: iTicket) => mgr.tickets.transformInbound(x));
          this.vehicle = data.vehicle.map((x: iTicket) => mgr.tickets.transformInbound(x));
          this.store = data.store.map((x: iTicket) => mgr.tickets.transformInbound(x));
        });
    // TODO: convert to local times
  },
});
