import { iUser } from "@/managers/users";
import { iRole, Role } from "@/managers/roles";
import axios, { AxiosResponse } from "axios";
import { VueRouter } from "vue-router/types/router";
import { flash } from "./flashNotifications";
import { sockets } from "./sockets";
// Manages user authentication and current user info

/*
    To use, in main.ts add:
    import store from "./store";
    import auth from "./plugins/auth";
    Vue.use(auth, { store, router });

        // In a component
    import { auth } from "@/plugins/auth";
    auth.signIn("email", "password");
    auth.currentUser();
*/


interface iAuthConfigOptions {
  store: any; // Vuex store
  router: VueRouter;
}

interface iAuthState {
  currentUser: iUser | null;
}

// Context singleton initialise on install
const CONTEXT = {
  store: {} as any,
  router: {} as any,
  localStorageTokenKey: "WatsonBureauAuthToken",
};


export default {
  install(Vue: any, { store, router }: iAuthConfigOptions) {
    if (!store) throw new Error("Please provide vuex store.");
    if (!router) throw new Error("Please provide vue router.");
    CONTEXT.store = store;
    CONTEXT.router = router;

    // Try set axios default header to include the authToken from localStorage
    const storedToken = getAuthToken(CONTEXT.localStorageTokenKey);
    if (storedToken !== null) setAxiosAuthHeader(storedToken);

    const state: iAuthState = {
      currentUser: null,
    };

    const mutations = {
      _setCurrentUser(state: iAuthState, currentUser: iUser) {
        state.currentUser = currentUser;
      },
      _removeCurrentUser(state: iAuthState) {
        state.currentUser = null;
      },
    };
    const actions = {};
    store.registerModule("auth", { state, mutations, actions });
  },
};

export const auth = {
  signIn(email: string, password: string) {
    const formData = new FormData();
    formData.set("username", email);
    formData.set("password", password);
    flash.info("Signing in...");
    return axios.post("/app/auth/sign-in", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then(res => {
      const authToken: string = res.data.authToken;
      const user: iUser = res.data.user

      // Store the token
      localStorage.setItem(CONTEXT.localStorageTokenKey, authToken);
      setAxiosAuthHeader(authToken);

      CONTEXT.store.commit("_setCurrentUser", user);

      flash.success(`You are logged in as ${user.username}`);

      return user

      // sockets.createConnection(user.id)
    }).catch(e => {
      flash.error(e.response?.data?.detail || "Error signing in");
      localStorage.removeItem(CONTEXT.localStorageTokenKey);
      CONTEXT.store.commit("_removeCurrentUser");
      throw new Error("Sign in unsuccessful");

    })
  },
  signOut(msg = "Signed out") {
    localStorage.removeItem(CONTEXT.localStorageTokenKey);
    CONTEXT.store.commit("_removeCurrentUser");
    if (CONTEXT.router.currentRoute.path !== "/sign-in")
      CONTEXT.router.replace("/sign-in");
    flash.success(msg,);
    sockets.closeConnection()
  },
  fetchCurrentUser() {
    return axios.get("/app/auth").then(res => {
      const user: iUser = res.data
      if (!user.confirmedEmail || !user.active) auth.signOut();
      else CONTEXT.store.commit("_setCurrentUser", user);
    })
  },
  isSignedIn(): boolean {
    const user: iUser | null = CONTEXT.store.state.auth.currentUser;
    const authToken = getAuthToken(CONTEXT.localStorageTokenKey);

    if (!user || !user.active || !user.confirmedEmail || user?.email?.length <= 0 || !authToken) {
      auth.signOut
      return false;
    }
    return true;
  },
  isAdmin(): boolean {
    return this.hasAtLeastOneRole([Role.ADMIN]);
  },
  currentUser() {
    return CONTEXT.store.state.auth.currentUser as iUser | null;
  },
  hasAtLeastOneRole(roles: (Role | iRole)[]) {
    if (roles?.length === 0) return true;
    const user = auth.currentUser()
    if (!user) return false;
    if (user.superuser === true) return true;

    let canAccess = false;
    for (const requiredRole of roles) {
      const name = typeof requiredRole == "string" ? requiredRole : requiredRole.name;
      if (user.roles.some(x => x.name === name)) canAccess = true;
    }
    return canAccess;
  },
  hasAllRoles(roles: (Role | iRole)[]) {
    if (roles?.length === 0) return true;
    const user = auth.currentUser()
    if (!user) return false;
    if (user.superuser === true) return true;

    const userRoleNames = user.roles.map(x => x.name)
    for (const requiredRole of roles) {
      const name = typeof requiredRole === "string" ? requiredRole : requiredRole.name;
      if (!userRoleNames.includes(name)) return false;
    }
    return true;
  },
  hasNoneOfRoles(roles: (Role | iRole)[]) {
    if (roles?.length === 0) return true;
    const user = auth.currentUser()
    if (!user) return false;
    if (user.superuser === true) return true;

    const userRoleNames = user.roles.map(x => x.name)
    for (const forbiddenRole of roles) {
      const name = typeof forbiddenRole == "string" ? forbiddenRole : forbiddenRole.name;
      if (userRoleNames.includes(name)) return false;
    }
    return true;
  },
  retrieveLocalStorageToken() {
    return getAuthToken(CONTEXT.localStorageTokenKey);
  },
  removeLocalStorageToken() {
    localStorage.removeItem(CONTEXT.localStorageTokenKey);
  },
  hasAuthToken(): boolean {
    const token = getAuthToken(CONTEXT.localStorageTokenKey);
    if (token !== null && typeof token === "string") return true;
    return false;
  }
}


// Helper functions

function getAuthToken(key: string): string | null {
  const dataString: string | null = localStorage.getItem(key);
  if (!dataString) return null;
  return dataString;
}


function setAxiosAuthHeader(token: string): void {
  axios.defaults.headers.common["Authorization"] = "Bearer" + " " + token;
}
