
import Vue from "vue";
import LayoutTicketList from "@/components/LayoutTicketList.vue";
import { FilterSetKey } from "@/plugins/dataStore";

export default Vue.extend({
  name: "Tickets",
  components: { LayoutTicketList },
  data: () => ({
    filterSetKey: FilterSetKey.VIEWER_TICKETS,
  }),
});
