
import Vue from "vue";
import TimeMixin from "@/mixins/TimeMixin";
import DisplayMixin from "@/mixins/DisplayMixin";
import { iTicket, TicketStatus, iTicketUpdate, AlertType, TicketCategory, AlertLevel } from "@/managers/tickets";
import EventsList from "@/components/EventsList.vue";
import TicketMap from "@/components/TicketMap.vue";
import ItemUpdater from "@/components/ItemUpdater.vue";
import FlagsDisplay from "@/components/utils/FlagsDisplay.vue";
import { auth } from "@/plugins/auth";
import { screamingSnakeToRegularCase } from "@/utils";
import { TicketFlag } from "@/managers/ticketActions";
import { cloneDeep } from "lodash";
import { mgr } from "@/managers";
import { flash } from "@/plugins/flashNotifications";
import { update } from "plotly.js";

export default Vue.extend({
  name: "TicketInfoBar",
  mixins: [TimeMixin, DisplayMixin],
  components: { EventsList, TicketMap, FlagsDisplay, ItemUpdater },
  props: {
    ticket: { type: Object as () => iTicket, required: true },
    startInfoOpen: { type: Boolean, default: false },
    startMapOpen: { type: Boolean, default: false },
  },
  data: () => ({
    expanded: false,
    ticketToEdit: null as iTicket | null,
  }),
  computed: {
    isAdmin() {
      return auth.isAdmin();
    },
    alertTypeOptions() {
      return Object.values(AlertType).map((x) => {
        return { value: x, text: screamingSnakeToRegularCase(x) };
      });
    },
    alertLevelOptions() {
      return Object.values(AlertLevel).map((x) => {
        return { value: x, text: screamingSnakeToRegularCase(x) };
      });
    },
    statusOptions() {
      return Object.values(TicketStatus).map((x) => {
        return { value: x, text: screamingSnakeToRegularCase(x) };
      });
    },
    categoryOptions() {
      return Object.values(TicketCategory).map((x) => {
        return { value: x, text: screamingSnakeToRegularCase(x) };
      });
    },
    flagOptions() {
      return Object.values(TicketFlag).map((x) => {
        return { value: x, text: screamingSnakeToRegularCase(x) };
      });
    },
  },
  methods: {
    alertLevelColor(ticket: iTicket): String {
      if (ticket.status == TicketStatus.RESOLVED) return "ticket-resolved";
      if (ticket.status == TicketStatus.CLOSED) return "ticket-closed";
      return "ticket-" + ticket.alertLevel.toLowerCase();
    },
    openTicketEditDialog() {
      this.ticketToEdit = this.ticket;
      // this.ticketToEdit = cloneDeep(this.ticket);
    },
    updateTicket({ initial, updated }: { initial: iTicket; updated: any }) {
      const payload: iTicketUpdate = {};

      if (updated.alertType && updated.alertType !== initial.alertType) payload.alertType = updated.alertType;
      if (updated.category && updated.category !== initial.category) payload.category = updated.category;
      if (updated.alertLevel && updated.alertLevel !== initial.alertLevel) payload.alertLevel = updated.alertLevel;
      if (updated.flags && updated.flags?.join(",") !== initial.flags?.join(",")) payload.flags = updated.flags;

      if (Object.keys(payload).length == 0) {
        flash.info("No changes detected");
        return;
      }

      mgr.tickets.update(this.ticket.id, payload);
      this.ticketToEdit = null;
    },
  },
  beforeMount() {
    if (this.startInfoOpen == true) {
      this.expanded = true;
    }
  },
});
