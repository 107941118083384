
import Vue from "vue";

export default Vue.extend({
  name: "PasswordInput",
  components: {},
  props: {
    value: { type: String, required: true },
    label: { type: String, default: "Password" },
    solo: { type: Boolean, default: false },
  },
  data() {
    return {
      show: false,
      rules: {
        required: (v: string) => !!v || "Required",
        min: (v: string) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  computed: {},
  methods: {
    onInput(val: string) {
      this.$emit("input", val);
    },
  },
});
