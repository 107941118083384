
import Vue from "vue";
import { auth } from "@/plugins/auth";
import { Role } from "@/managers/roles";
import TicketHeader from "@/components/TicketHeader.vue";
import { mgr } from "@/managers";
import TimeMixin from "@/mixins/TimeMixin";
import DisplayMixin from "@/mixins/DisplayMixin";
import { iTicket } from "@/managers/tickets";
import TicketInfoBar from "@/components/TicketInfoBar.vue";
import TicketHistory from "@/components/TicketHistory.vue";
import TicketNoteAdder from "@/components/TicketNoteAdder.vue";
import TicketMap from "@/components/TicketMap.vue";
import SopManager from "@/components/sops/SopManager.vue";
import RelatedTickets from "@/components/RelatedTickets.vue";
import { SopType } from "@/managers/sop";

export default Vue.extend({
  name: "LayoutTicket",
  mixins: [TimeMixin, DisplayMixin],
  components: { TicketInfoBar, TicketHistory, TicketNoteAdder, TicketMap, SopManager, TicketHeader, RelatedTickets },
  props: {
    title: { type: String, default: "Tickets" },
    navPath: { type: String, default: "/tickets" },
    sopType: { type: String as () => SopType, required: true },
  },
  data: () => ({
    ticketId: null as null | number,
  }),
  computed: {
    ticket(): iTicket | null {
      if (this.ticketId) return mgr.tickets.getById(this.ticketId);
      else return null;
    },
    sopTypes(): SopType[] {
      const sopTypes = [this.sopType];
      if (this.ticket) {
        this.ticket.flags.forEach((x: any) => {
          if (x === "MAINTENANCE" && !sopTypes.includes(SopType.MAINTENANCE)) {
            if (
              this.ticket?.status === "CLOSED" ||
              (this.ticket?.status === "RESOLVED" && auth.hasAtLeastOneRole([Role.MAINTENANCE_OPERATOR]))
            )
              sopTypes.push(SopType.MAINTENANCE);
          }
          if (x === "DRIVER_DISCIPLINE" && !sopTypes.includes(SopType.DRIVER_DISCIPLINE)) {
            if (
              this.ticket?.status === "CLOSED" ||
              (this.ticket?.status === "RESOLVED" && auth.hasAtLeastOneRole([Role.DRIVER_DISCIPLINE_OPERATOR]))
            )
              sopTypes.push(SopType.DRIVER_DISCIPLINE);
          }
          if (x === "CUSTOMER_CARE" && !sopTypes.includes(SopType.CUSTOMER_CARE)) {
            if (
              this.ticket?.status === "CLOSED" ||
              (this.ticket?.status === "RESOLVED" && auth.hasAtLeastOneRole([Role.CUSTOMER_CARE_OPERATOR]))
            )
              sopTypes.push(SopType.CUSTOMER_CARE);
          }
          if (x === "TELEMETRY_PROVIDER_FOLLOWUP" && !sopTypes.includes(SopType.TELEMETRY_PROVIDER)) {
            if (
              this.ticket?.status === "CLOSED" ||
              (this.ticket?.status === "RESOLVED" && auth.hasAtLeastOneRole([Role.TELEMETRY_PROVIDER]))
            )
              sopTypes.push(SopType.TELEMETRY_PROVIDER);
          }
          if (x === "TRANSPORT_SCHEDULING" && !sopTypes.includes(SopType.TRANSPORT_SCHEDULING)) {
            if (
              this.ticket?.status === "CLOSED" ||
              (this.ticket?.status === "RESOLVED" && auth.hasAtLeastOneRole([Role.TRANSPORT_SCHEDULE_OPERATOR]))
            )
              sopTypes.push(SopType.TRANSPORT_SCHEDULING);
          }
        });
      }
      return sopTypes;
    },
  },
  methods: {
    updateTicketId() {
      this.ticketId = mgr.tickets.fetchByIdFromRouteParams();
    },
  },
  beforeMount() {
    this.updateTicketId();
  },
  watch: {
    "$route.params.id": {
      handler() {
        this.updateTicketId();
      },
      deep: true,
      immediate: true,
    },
  },
});
