import { StoreKey, QueryParams, FilterSetKey, store } from "@/plugins/dataStore";
import { iTicketAction } from "./ticketActions";
import { iUserSkinny } from "./users";
import { storeAble, filterAble, baseTransformInbound, canUpdate } from "./_baseManager";
import router from "@/router";
import axios from "axios";
import { flash } from "@/plugins/flashNotifications";
import { snakeToCamelCase } from "@/utils";

export enum AlertType {
  PANIC_ALARM = "PANIC_ALARM",
  ESEAL_CABLE_CUT = "ESEAL_CABLE_CUT",
  ESEAL_COVER_OPEN = "ESEAL_COVER_OPEN",
  ESEAL_UNLOCKED = "ESEAL_UNLOCKED",
  ESEAL_LOW_BATTERY = "ESEAL_LOW_BATTERY",
  UNAUTHORIZED_STOP = "UNAUTHORIZED_STOP",
  UNAUTHORIZED_STOP_DEPARTURE = "UNAUTHORIZED_STOP_DEPARTURE",
  UNAUTHORIZED_DOOR_OPEN = "UNAUTHORIZED_DOOR_OPEN",
  MOUNTAIN_PASS_SPEEDING = "MOUNTAIN_PASS_SPEEDING",
  SPEEDING = "SPEEDING",
  TURNAROUND_DELAY = "TURNAROUND_DELAY",
  TURNAROUND_DELAY_DEPARTURE = "TURNAROUND_DELAY_DEPARTURE",
  PREOFFLOAD_DELAY = "PREOFFLOAD_DELAY",
  PREOFFLOAD_DELAY_END = "PREOFFLOAD_DELAY_END",
  REST_STOP_DELAY = "REST_STOP_DELAY",
  REST_STOP_DELAY_DEPARTURE = "REST_STOP_DELAY_DEPARTURE",
  EXCESSIVE_TEMPERATURE_SPIKE = "EXCESSIVE_TEMPERATURE_SPIKE",
  EXCESSIVE_IDLING = "EXCESSIVE_IDLING",
  COMMUNICATION_LOSS = "COMMUNICATION_LOSS",
  COMMUNICATION_LOSS_RESTORED = "COMMUNICATION_LOSS_RESTORED",
  EXTERNAL_BATTERY_DISCONNECTION = "EXTERNAL_BATTERY_DISCONNECTION",
  EXTERNAL_BATTERY_DISCONNECTION_RESTORED = "EXTERNAL_BATTERY_DISCONNECTION_RESTORED",
}

export enum AlertLevel {
  CRITICAL = "CRITICAL",
  IMPORTANT = "IMPORTANT",
  NORMAL = "NORMAL",
}

export enum EventStatus {
  IGNORED = "IGNORED",
  DUPLICATE = "DUPLICATE",
  RECEIVED = "RECEIVED",
}

export enum TicketStatus {
  NEW = "NEW",
  ASSIGNED = "ASSIGNED", // The ticket has been assigned to an operator
  RESOLVED = "RESOLVED", // The ticket has been process by the operator
  CLOSED = "CLOSED", // All remedial action completed
}

export enum TicketCategory {
  DRIVER_DISCIPLINE = "DRIVER_DISCIPLINE",
  CUSTOMER_CARE = "CUSTOMER_CARE",
  MAINTENANCE = "MAINTENANCE",
}

export interface iDriver {
  id: number;
  cellNumber: string;
  name: string;
  staffId: number;
}

export interface iTicketCount {
  inboxTicketCount: number,
  driverDisciplineTicketCount: number,
  customerCareTicketCount: number,
  maintenanceTicketCount: number,
  telemetryProviderFollowupTicketCount: number,
  transportSchedulingTicketCount: number,
}

export interface iVehicle {
  id: number;
  code: string;
  registration: string;
  type: string;
}

export interface iStore {
  id: number;
  code: number;
  name: string;
}


export interface iEvent {
  id: number;
  ticketId: number;
  status: EventStatus;
  updatedUtc: string;
  createdUtc: string;

  // Alert data (required)
  provider: string;
  providerReference: string;
  alertType: AlertType;
  alertUtc: string;

  // Alert data (Optional)
  eventId: number | null; // From fm

  driverId: number | null;
  driverName: string | null;
  driverNumber: string | null;

  vehicleId: number | null;
  vehicleCode: string | null;
  vehicleType: string | null;
  vehicleRegistration: string | null;
  vehicleKph: number | null;

  storeId: number | null;
  storeName: string | null;
  storeCode: number | null;

  latitude: number | null;
  longitude: number | null;
  locationDescription: string | null;

  liveTrackingUrl: string | null;
  temperatureCel: number | null;
  timeElapsedMin: number | null;
}

export interface iEventUpdate {
  ticketId?: number
  driverId?: number
  vehicleId?: number
  storeId?: number
  latitude?: number;
  longitude?: number;
}

export interface iFlag {
  id: number;
  name: string;
}

export interface iTicket {
  id: number;
  alertType: AlertType;
  status: TicketStatus;
  category: TicketCategory;
  alertLevel: AlertLevel
  flags: iFlag[]

  assignedUser: iUserSkinny | null;
  reason: string | null;
  closedComment: string | null;

  createdUtc: string;
  assignedUtc: string | null;
  resolvedUtc: string | null;
  closedUtc: string | null;
  updatedUtc: string | null;

  clientReceivedUtc: string;

  events: iEvent[];
  actions: iTicketAction[];
}

export interface iTicketUpdate {
  alertType?: AlertType;
  // status?: TicketStatus;
  category?: TicketCategory;
  alertLevel?: AlertLevel
  flags?: iFlag[]
  assignedId?: number | null;
}

export const tickets = function () {
  const storeKey = StoreKey.TICKETS;
  const baseUrl = "/app/tickets";

  const transformInbound = (data: any) => {
    // Also transform all events
    if (data.events) data.events = data.events.map((x: any) => baseTransformInbound<iEvent>(x))
    if (data.actions) data.actions = data.actions.map((x: any) => baseTransformInbound<iEvent>(x))
    data.clientReceivedUtc = new Date().toUTCString()
    return baseTransformInbound<iTicket>(data);
  }


  return {
    storeKey: storeKey,
    url: baseUrl,
    transformInbound,
    ...storeAble<iTicket>(storeKey, baseUrl, transformInbound),
    ...canUpdate<iTicket, iTicketUpdate>(storeKey, baseUrl, transformInbound),
    ...filterAble<iTicket>(storeKey, baseUrl, transformInbound),

    fetchForPast24Hours(category: TicketCategory | null = null) {
      const params = { category: category } as QueryParams;
      return this.fetchFilterSet(FilterSetKey.NEW_TICKETS, params, { url: "/app/tickets/current" })
    },

    /** Looks at the Vue Router current route params and for an id, if there is an id then it calls fetchById and returns the id */
    fetchByIdFromRouteParams() {
      const id = parseInt(router.currentRoute.params?.id || "-1");
      if (id == -1) return null;
      this.fetchForId(id);
      return id;
    },

    transformInboundTicketCounts(obj: any) {
      const entries = Object.entries(obj);

      return Object.fromEntries(
        entries.map(([key, value]) => {
          return [snakeToCamelCase(key), value];
        }),
      );
    },

    async updateEvent(eventId: number, payload: iEventUpdate) {
      try {
        const res = await axios.patch(`/app/events/${eventId}`, payload);
        store.upsertItems(storeKey, [transformInbound(res.data)]);
        flash.success("Event updated")
      } catch (error) {
        flash.error("Could not update event")
      }
    },

    async moveEventToNewTicket(eventId: number) {
      try {
        const res = await axios.patch(`/app/events/move-to-new/${eventId}`);
        store.upsertItems(storeKey, [transformInbound(res.data)]);
        flash.success("Event moved to newly created ticket")
      } catch (error) {
        flash.error("Could not move ticket")
      }
    },

  } as const
}()

export const alertTypeAbv = function (alert: AlertType) {
  switch (alert) {
    case AlertType.PANIC_ALARM:
      return "Panic alarm";
    case AlertType.ESEAL_CABLE_CUT:
      return "eSeal cable cut";
    case AlertType.ESEAL_COVER_OPEN:
      return "eSeal over opened";
    case AlertType.ESEAL_UNLOCKED:
      return "eSeal unlocked";
    case AlertType.ESEAL_LOW_BATTERY:
      return "eSeal low battery";
    case AlertType.UNAUTHORIZED_STOP:
      return "Unauth stop";
    case AlertType.UNAUTHORIZED_STOP_DEPARTURE:
      return "Unauth stop departure";
    case AlertType.UNAUTHORIZED_DOOR_OPEN:
      return "Door open";
    case AlertType.MOUNTAIN_PASS_SPEEDING:
      return "Mtn speeding";
    case AlertType.SPEEDING:
      return "Speeding";
    case AlertType.TURNAROUND_DELAY:
      return "Turnaround delay";
    case AlertType.TURNAROUND_DELAY_DEPARTURE:
      return "Turnaround departure";
    case AlertType.PREOFFLOAD_DELAY:
      return "Pre-offload delay";
    case AlertType.PREOFFLOAD_DELAY_END:
      return "Pre-offload end";
    case AlertType.REST_STOP_DELAY:
      return "Rest stop delay";
    case AlertType.REST_STOP_DELAY_DEPARTURE:
      return "Rest departure";
    case AlertType.EXCESSIVE_TEMPERATURE_SPIKE:
      return "Temp spike";
    case AlertType.EXCESSIVE_IDLING:
      return "Idling";
    case AlertType.COMMUNICATION_LOSS:
      return "Coms loss";
    case AlertType.COMMUNICATION_LOSS_RESTORED:
      return "Coms restored";
    case AlertType.EXTERNAL_BATTERY_DISCONNECTION:
      return "Battery disconnection";
    case AlertType.EXTERNAL_BATTERY_DISCONNECTION_RESTORED:
      return "Battery restored";
    default:
      return "";
  }
}