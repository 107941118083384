
import Vue from "vue";
import { iEvent, iTicket } from "@/managers/tickets";
import MapViewer from "@/components/MapViewer.vue";

export default Vue.extend({
  name: "TicketsMultiMap",
  components: { MapViewer },
  props: {
    title: { type: String, default: "Map" },
    tickets: { type: Array as () => iTicket[], required: true },
    startOpen: { type: Boolean, default: false },
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    allEvents(): iEvent[] {
      return this.tickets.map((x) => x.events).flat();
    },
  },
  methods: {},
  beforeMount() {
    if (this.startOpen == true) {
      this.expanded = true;
    }
  },
});
