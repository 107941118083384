
import Vue from "vue";

export default Vue.extend({
  name: "EmailInput",
  components: {},
  props: {
    value: { required: true },
    label: { type: String, default: "Email" },
    solo: { type: Boolean, default: false },
  },
  data() {
    return {
      rules: {
        required: (v: string) => !!v || "Required",
        isEmail: (v: string) => {
          const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return emailRegex.test(String(v).toLowerCase()) || "Please enter a valid email address";
        },
      },
    };
  },
  methods: {
    onInput(val: string) {
      this.$emit("input", val);
    },
  },
});
