
import Vue from "vue";
import { mgr } from "@/managers";
import { iSopStep } from "@/managers/sop";
import { TicketFlag, ActionType } from "@/managers/ticketActions";
import { screamingSnakeToRegularCase } from "@/utils";
import { iFlag, iTicket } from "@/managers/tickets";
import router from "@/router";

export default Vue.extend({
  name: "ReassignFlag",
  components: {},
  props: {
    step: { type: Object as () => iSopStep, required: true },
    ticket: { type: Object as () => iTicket, required: true },
  },
  data: () => ({
    selectedFlag: "",
    description: "",
  }),
  computed: {
    flagOptions(): { text: string; value: any }[] {
      return Object.values(TicketFlag).map((x) => {
        return { text: screamingSnakeToRegularCase(x), value: x, disabled: false };
      });
    },
    actionTypes(): ActionType[] {
      return this.ticket.actions.map((x) => x.actionType);
    },
    isResolved(): boolean {
      if (this.actionTypes.includes(ActionType.FLAG_REASSIGNED) || this.actionTypes.includes(ActionType.CLOSED))
        return true;
      return false;
    },
  },
  methods: {
    updateFlags() {
      if (this.isResolved) return;
      let flag: string | undefined = this.selectedFlag;
      if (flag == "") flag = undefined;
      mgr.ticketActions.flagUpdated(this.ticket.id, this.selectedFlag as TicketFlag, this.description);
      if (flag) {
        // navigate back to ticket inbox after current ticket moves to new inbox
        const inboxPath = router.currentRoute.path.replace(`/${this.ticket.id}`, "/");
        if (router.currentRoute.path != inboxPath) router.replace(inboxPath);
      }
    },
    updateStep() {
      // set selectedFlag to currently assigned flag
      const flagsReassigned = this.ticket.actions.filter((x) => x.actionType == ActionType.FLAG_REASSIGNED);
      if (flagsReassigned.length > 0) {
        this.selectedFlag = flagsReassigned[flagsReassigned.length - 1].data.flag as TicketFlag;
        this.description = flagsReassigned[flagsReassigned.length - 1].data.description;
      } else {
        const flagAdded = this.ticket.actions.find((x) => x.actionType == ActionType.FLAG_ADDED);
        if (flagAdded) {
          this.selectedFlag = flagAdded.data.flag as TicketFlag;
        }
      }

      if (this.isResolved) {
        this.$emit("updateStep", { step: this.step, resolved: true });
      } else {
        this.$emit("updateStep", { step: this.step, resolved: false, unResolvedReason: "Ticket not closed yet" });
      }
    },
  },
  watch: {
    actionTypes() {
      this.updateStep();
    },
  },
  mounted() {
    this.updateStep();
  },
});
