
import { iTicketAction } from "@/managers/ticketActions";
import { iTicket } from "@/managers/tickets";
import Vue from "vue";
import TimeMixin from "@/mixins/TimeMixin";
import DisplayMixin from "@/mixins/DisplayMixin";
export default Vue.extend({
  name: "TicketHistory",
  mixins: [TimeMixin, DisplayMixin],
  components: {},
  props: {
    ticket: { type: Object as () => iTicket },
    startClosed: { type: Boolean, default: false },
  },
  data: () => ({
    headers: [
      { text: "Time", value: "createdUtc" },
      { text: "Type", value: "actionType" },
      { text: "Action", value: "comment" },
      { text: "Data", value: "data" },
    ],
    tableRowExpanded: [],
    expanded: true,
  }),
  computed: {
    history(): iTicketAction[] {
      if (!this.ticket) return [];
      const history = [...this.ticket.actions];

      // Add a fake history item to note ticket creation
      history.unshift({
        id: -1,
        ticketId: this.ticket.id,
        actionType: "CREATED" as any,
        comment: "Ticket created",
        data: {},
        createdUtc: this.ticket.createdUtc,
        createdByUser: {} as any,
      });
      return history;
    },
  },
  methods: {},
  beforeMount() {
    if (this.startClosed == true) {
      this.expanded = false;
    }
  },
});
