import Vue from 'vue'
import Vuex from 'vuex'
import { iTicket, iTicketCount } from '../managers/tickets'
import clonedeep from "lodash.clonedeep";
import axios from "axios";


Vue.use(Vuex)

const STORE = new Vuex.Store({
  state: {
    selectedTicket: null as null | iTicket,
    ticketCount: null as null | iTicketCount
  },
  mutations: {
    _setSelectedTicket(state, ticket: iTicket | null) {
      state.selectedTicket = clonedeep(ticket);
    },
    _setTicketCounts(state, counts: iTicketCount | null) {
      state.ticketCount = clonedeep(counts);
    },
  },
  actions: {
  },
  modules: {
  }
})

export default STORE;

export const store = {
  setSelectedTicket(ticket: iTicket | null) {
    STORE.commit("_setSelectedTicket", ticket);
  },
  selectedTicket() { return STORE.state.selectedTicket },

  setTicketCounts() {
    axios.get(`/app/tickets/ticket-counts`).then((res) => res.data)
      .then((data) => {
        STORE.commit("_setTicketCounts", data as iTicketCount)
      })
  },
  ticketCount() { return STORE.state.ticketCount }

}