import { tickets } from "./tickets";
import { StoreKey, store } from "@/plugins/dataStore";
import { flash } from "@/plugins/flashNotifications";
import axios from 'axios';
import { sopContacts } from './sop'
import { iUserSkinny } from './users';


// These mush match web/app/models/enums.py
export enum ActionType {
  CLAIMED_BY = "CLAIMED_BY",
  ASSIGNED_TO = "ASSIGNED_TO",
  UN_ASSIGNED = "UN_ASSIGNED",
  MAP_VIEWED = "MAP_VIEWED",
  TRACKING_VIEWED = "TRACKING_VIEWED",
  HISTORY_DETERMINED = "HISTORY_DETERMINED",
  DRIVER_SAFE = "DRIVER_SAFE",
  DRIVER_AT_RISK = "DRIVER_AT_RISK",
  DRIVER_CONTACTED = "DRIVER_CONTACTED",
  DRIVER_UNCONTACTABLE = "DRIVER_UNCONTACTABLE",
  STORE_CONTACTED = "STORE_CONTACTED",
  STORE_UNCONTACTABLE = "STORE_UNCONTACTABLE",
  STORE_NOT_CONTACTED = "STORE_NOT_CONTACTED",
  CONFIRMED_ACTION = "CONFIRMED_ACTION",
  DEPARTURE_CONFIRMED = "DEPARTURE_CONFIRMED",
  DELAY_ENDED = "DELAY_ENDED",
  COMMUNICATION_RESTORED = "COMMUNICATION_RESTORED",
  BATTERY_RECONNECTED = "BATTERY_RECONNECTED",
  REASON_ADDED = "REASON_ADDED",
  CONTACT_CONFIRMED = "CONTACT_CONFIRMED",
  NOTE_ADDED = "NOTE_ADDED",
  FLAG_ADDED = "FLAG_ADDED",
  FLAG_REASSIGNED = "FLAG_REASSIGNED",
  MARK_AS_SEEN = "MARK_AS_SEEN",
  DESCRIPTION_ADDED = "DESCRIPTION_ADDED",
  MAINTENANCE_JOB_CARD_ADDED = "MAINTENANCE_JOB_CARD_ADDED",
  RESOLVED = "RESOLVED",
  EVENT_ADDED = "EVENT_ADDED",
  EVENT_UPDATED = "EVENT_UPDATED",
  EVENT_REPORT_SENT = "EVENT_REPORT_SENT",
  TICKET_UPDATED = "TICKET_UPDATED",
  CLOSED = "CLOSED",
}

export enum TicketFlag {
  MAINTENANCE = "MAINTENANCE",
  DRIVER_DISCIPLINE = "DRIVER_DISCIPLINE",
  CUSTOMER_CARE = "CUSTOMER_CARE",
  TELEMETRY_PROVIDER_FOLLOWUP = "TELEMETRY_PROVIDER_FOLLOWUP",
  TRANSPORT_SCHEDULING = "TRANSPORT_SCHEDULING",
}

export interface iTicketAction {
  id: number;
  ticketId: number
  actionType: ActionType
  comment: string
  data: Record<string, any>
  createdUtc: string
  createdByUser: iUserSkinny
}

interface iTicketActionCreateBase {
  ticketId: number
}
interface iTicketActionCreate extends iTicketActionCreateBase {
  actionType: ActionType;
  note?: string;
  attempts?: number;
  person?: string;
  jobCardNumber?: number;
  storeContact?: string;
  storeContactPosition?: string;
  description?: string;
  confirmed?: string;
  reason?: string;
  flag?: TicketFlag;
  closingAction?: string;
  eventId?: number
}
interface iTicketActionCreateAssignedTo extends iTicketActionCreateBase {
  assignedUserId: number;
  assignedUserUsername: string;
}

export const ticketActions = function () {
  const storeKey = StoreKey.TICKETS;
  const baseUrl = "/app/ticket-actions";
  let canAppend = true;

  return {
    storeKey: storeKey,
    url: baseUrl,
    assignTo(ticketId: number, assignedUserId: number, assignedUserUsername: string) {
      const payload: iTicketActionCreateAssignedTo = { ticketId, assignedUserId, assignedUserUsername }
      if (!canAppend) return
      else {
        canAppend = false
        return axios.post(`${baseUrl}/assigned-to`, payload).then((res) => {
          if (!res) return
          store.upsertItems(storeKey, [tickets.transformInbound(res.data)])
        }).finally(() => (canAppend = true));
      }
    },

    claimBy(ticketId: number) {
      if (!canAppend) return
      else {
        canAppend = false
        return axios.post(`${baseUrl}/claimed-by`, { ticketId }).then((res) => {
          if (!res) return
          store.upsertItems(storeKey, [tickets.transformInbound(res.data)])
        }).finally(() => (canAppend = true));
      }
    },

    unAssign(ticketId: number) {
      if (!canAppend) return
      else {
        canAppend = false
        return axios.post(`${baseUrl}/un-assigned`, { ticketId }).then((res) => {
          if (!res) return
          store.upsertItems(storeKey, [tickets.transformInbound(res.data)])
        }).finally(() => (canAppend = true));
      }
    },

    mapViewed(ticketId: number) {
      return this._appendActions(baseUrl, { ticketId, actionType: ActionType.MAP_VIEWED })
    },
    trackingViewed(ticketId: number) {
      return this._appendActions(baseUrl, { ticketId, actionType: ActionType.TRACKING_VIEWED })
    },

    historyDetermined(ticketId: number) {
      return this._appendActions(baseUrl, { ticketId, actionType: ActionType.HISTORY_DETERMINED })
    },

    driverContacted(ticketId: number) {
      return this._appendActions(baseUrl, { ticketId, actionType: ActionType.DRIVER_CONTACTED })
    },
    driverUncontactable(ticketId: number, attempts: number, note: string) {
      return this._appendActions(baseUrl, { ticketId, attempts, note, actionType: ActionType.DRIVER_UNCONTACTABLE })
    },

    storeContacted(ticketId: number, storeContact: string, storeContactPosition: string) {
      return this._appendActions(baseUrl, { ticketId, storeContact, storeContactPosition, actionType: ActionType.STORE_CONTACTED })
    },
    storeUncontactable(ticketId: number, storeContact: string, storeContactPosition: string, attempts: number, note: string) {
      return this._appendActions(baseUrl, { ticketId, storeContact, storeContactPosition, attempts, note, actionType: ActionType.STORE_UNCONTACTABLE })
    },
    storeNotContacted(ticketId: number) {
      return this._appendActions(baseUrl, { ticketId, actionType: ActionType.STORE_NOT_CONTACTED })
    },

    driverSafe(ticketId: number) {
      return this._appendActions(baseUrl, { ticketId, actionType: ActionType.DRIVER_SAFE })
    },
    driverAtRisk(ticketId: number) {
      return this._appendActions(baseUrl, { ticketId, actionType: ActionType.DRIVER_AT_RISK })
    },

    departureConfirmed(ticketId: number) {
      return this._appendActions(baseUrl, { ticketId, actionType: ActionType.DEPARTURE_CONFIRMED })
    },
    delayEnded(ticketId: number) {
      return this._appendActions(baseUrl, { ticketId, actionType: ActionType.DELAY_ENDED })
    },
    confirmCommunicationRestored(ticketId: number) {
      return this._appendActions(baseUrl, { ticketId, actionType: ActionType.COMMUNICATION_RESTORED })
    },
    confirmBatteryReconnected(ticketId: number) {
      return this._appendActions(baseUrl, { ticketId, actionType: ActionType.BATTERY_RECONNECTED })
    },

    confirmedAction(ticketId: number, confirmed: string) {
      return this._appendActions(baseUrl, { ticketId, confirmed, actionType: ActionType.CONFIRMED_ACTION })
    },
    reasonAdded(ticketId: number, reason: string) {
      return this._appendActions(baseUrl, { ticketId, reason, actionType: ActionType.REASON_ADDED })
    },

    jobCardNumberAdded(ticketId: number, jobCardNumber: number) {
      return this._appendActions(baseUrl, { ticketId, jobCardNumber, actionType: ActionType.MAINTENANCE_JOB_CARD_ADDED })
    },

    noteAdded(ticketId: number, note: string) {
      return this._appendActions(baseUrl, { ticketId, note, actionType: ActionType.NOTE_ADDED })
    },

    markAsSeen(ticketId: number) {
      return this._appendActions(baseUrl, { ticketId, actionType: ActionType.MARK_AS_SEEN })
    },

    descriptionAdded(ticketId: number, description: string) {
      return this._appendActions(baseUrl, { ticketId, description, actionType: ActionType.DESCRIPTION_ADDED })
    },

    flagSubmitted(ticketId: number, flag: TicketFlag | undefined) {
      return this._appendActions(baseUrl, { ticketId, flag, actionType: ActionType.FLAG_ADDED })
    },

    flagUpdated(ticketId: number, flag: TicketFlag, description?: string) {
      const payload: iTicketActionCreate = { ticketId, actionType: ActionType.FLAG_REASSIGNED }
      payload.flag = flag;
      if (description && description !== "") (payload).description = description;
      return this._appendActions(baseUrl, payload)
    },

    eventUpdated(ticketId: number, eventId: number, description?: string) {
      const payload: iTicketActionCreate = { ticketId, actionType: ActionType.EVENT_UPDATED }
      payload.eventId = eventId;
      if (description && description !== "") (payload).description = description;
      return this._appendActions(baseUrl, payload)
    },

    eventReportSent(ticketId: number) {
      return this._appendActions(baseUrl, { ticketId, actionType: ActionType.EVENT_REPORT_SENT })
    },

    contactConfirmed(ticketId: number, person: sopContacts,) {
      return this._appendActions(baseUrl, { ticketId, person, actionType: ActionType.CONTACT_CONFIRMED })
    },

    resolveTicket(ticketId: number) {
      return this._appendActions(baseUrl, { ticketId, actionType: ActionType.RESOLVED })
    },

    closedTicket(ticketId: number, closingAction?: string, description?: string) {
      const payload: iTicketActionCreate = { ticketId, actionType: ActionType.CLOSED }
      if (description && description !== "") payload.description = description;
      if (closingAction && closingAction !== "") payload.closingAction = closingAction;
      return this._appendActions(baseUrl, payload)
    },

    _appendActions(url: string, action: iTicketActionCreate) {
      if (!canAppend) return
      else {
        canAppend = false
        return axios.post(url, action).then((res) => {
          if (!res) return
          store.upsertItems(storeKey, [tickets.transformInbound(res.data)])
        }).catch(error => {
          if (typeof error?.response?.data?.detail === "string") flash.error(error.response.data.detail);
        }).finally(() => (canAppend = true));
      }
    }
  }
}()