import { StoreKey } from "@/plugins/dataStore";
import { canGetAll, canFetch, canFetchIfEmpty } from "./_baseManager";


export enum Role {
  ADMIN = "ADMIN",
  OPERATOR = "OPERATOR",
  TELEMETRY_PROVIDER = "TELEMETRY_PROVIDER",
  DRIVER_DISCIPLINE_OPERATOR = "DRIVER_DISCIPLINE_OPERATOR",
  CUSTOMER_CARE_OPERATOR = "CUSTOMER_CARE_OPERATOR",
  MAINTENANCE_OPERATOR = "MAINTENANCE_OPERATOR",
  TRANSPORT_SCHEDULE_OPERATOR = "TRANSPORT_SCHEDULE_OPERATOR",
  DASHBOARD_VIEWER = "DASHBOARD_VIEWER",
}

export interface iRole {
  id: number;
  name: Role;
  displayName: string;
}

export const roles = function () {
  const storeKey = StoreKey.ROLES;
  const baseUrl = "/app/roles";

  return {
    storeKey: storeKey,
    url: baseUrl,
    ...canGetAll<iRole>(storeKey),
    ...canFetch<iRole>(storeKey, baseUrl),
    ...canFetchIfEmpty<iRole>(storeKey, baseUrl),
  } as const
}()
