import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import { auth } from "./plugins/auth"
import { flash } from "./plugins/flashNotifications";
import { sockets } from './plugins/sockets'
import { Role } from './managers/roles';

import SignIn from './views/SignIn.vue'
import Inbox from './views/tickets/Inbox.vue'
import InboxTicket from './views/tickets/InboxTicket.vue'
import Ticket from "./views/tickets/AllTicketsTicket.vue"
import Tickets from "./views/tickets/AllTicketsInbox.vue"


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  // ------------------------------------
  // Public routes
  // ------------------------------------
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn,
    meta: { public: true, closeSideMenu: true },
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import(/* webpackChunkName: "Welcome" */ './views/Welcome.vue'),
    meta: { public: true, closeSideMenu: true },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "ForgotPassword" */ './views/ForgotPassword.vue'),
    meta: { public: true, closeSideMenu: true },
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: () => import(/* webpackChunkName: "PasswordReset" */ './views/PasswordReset.vue'),
    meta: { public: true, closeSideMenu: true },
  },
  // ------------------------------------
  // Private routes
  // ------------------------------------
  { path: '/', redirect: '/inbox' },
  {
    path: '/inbox',
    name: 'Inbox',
    component: Inbox,
    meta: { requiresRole: [Role.OPERATOR, Role.DRIVER_DISCIPLINE_OPERATOR, Role.CUSTOMER_CARE_OPERATOR, Role.MAINTENANCE_OPERATOR, Role.TRANSPORT_SCHEDULE_OPERATOR] },
  },
  {
    path: '/inbox/:id',
    name: 'InboxTicket',
    component: InboxTicket,
    meta: { requiresRole: [Role.OPERATOR, Role.DRIVER_DISCIPLINE_OPERATOR, Role.CUSTOMER_CARE_OPERATOR, Role.MAINTENANCE_OPERATOR, Role.TRANSPORT_SCHEDULE_OPERATOR] },
  },
  {
    path: '/tickets',
    name: 'Tickets',
    component: Tickets,
    meta: { requiresRole: [Role.OPERATOR, Role.DRIVER_DISCIPLINE_OPERATOR, Role.CUSTOMER_CARE_OPERATOR, Role.MAINTENANCE_OPERATOR, Role.TRANSPORT_SCHEDULE_OPERATOR] },
  },
  {
    path: '/tickets/:id',
    name: 'Ticket',
    component: Ticket,
    meta: { requiresRole: [Role.OPERATOR, Role.DRIVER_DISCIPLINE_OPERATOR, Role.CUSTOMER_CARE_OPERATOR, Role.MAINTENANCE_OPERATOR, Role.TRANSPORT_SCHEDULE_OPERATOR] },
  },
  {
    path: '/disciplinary-tickets',
    name: 'DisciplinaryInbox',
    component: () => import('./views/tickets/DisciplinaryInbox.vue'),
    meta: { requiresRole: [Role.OPERATOR, Role.DRIVER_DISCIPLINE_OPERATOR] },
  },
  {
    path: '/disciplinary-tickets/:id',
    name: 'DisciplinaryTicket',
    component: () => import('./views/tickets/DisciplinaryTicket.vue'),
    meta: { requiresRole: [Role.OPERATOR, Role.DRIVER_DISCIPLINE_OPERATOR] },
  },
  {
    path: '/customer-care-tickets',
    name: 'CustomerCareInbox',
    component: () => import('./views/tickets/CustomerCareInbox.vue'),
    meta: { requiresRole: [Role.OPERATOR, Role.CUSTOMER_CARE_OPERATOR] },
  },
  {
    path: '/customer-care-tickets/:id',
    name: 'CustomerCareTicket',
    component: () => import('./views/tickets/CustomerCareTicket.vue'),
    meta: { requiresRole: [Role.OPERATOR, Role.CUSTOMER_CARE_OPERATOR] },
  },
  {
    path: '/maintenance-tickets',
    name: 'MaintenanceInbox',
    component: () => import('./views/tickets/MaintenanceInbox.vue'),
    meta: { requiresRole: [Role.OPERATOR, Role.MAINTENANCE_OPERATOR] },
  },
  {
    path: '/maintenance-tickets/:id',
    name: 'MaintenanceTicket',
    component: () => import('./views/tickets/MaintenanceTicket.vue'),
    meta: { requiresRole: [Role.OPERATOR, Role.MAINTENANCE_OPERATOR] },
  },
  {
    path: '/telemetry-provider-tickets',
    name: 'TelemetryProviderInbox',
    component: () => import('./views/tickets/TelemetryProviderInbox.vue'),
    meta: { requiresRole: [Role.OPERATOR, Role.TELEMETRY_PROVIDER] },
  },
  {
    path: '/telemetry-provider-tickets/:id',
    name: 'TelemetryProviderTicket',
    component: () => import('./views/tickets/TelemetryProviderTicket.vue'),
    meta: { requiresRole: [Role.OPERATOR, Role.TELEMETRY_PROVIDER] },
  },
  {
    path: '/transport-schedule-tickets',
    name: 'TransportScheduleInbox',
    component: () => import('./views/tickets/TransportScheduleInbox.vue'),
    meta: { requiresRole: [Role.OPERATOR, Role.TRANSPORT_SCHEDULE_OPERATOR] },
  },
  {
    path: '/transport-schedule-tickets/:id',
    name: 'TransportScheduleTicket',
    component: () => import('./views/tickets/TransportScheduleTicket.vue'),
    meta: { requiresRole: [Role.OPERATOR, Role.TRANSPORT_SCHEDULE_OPERATOR] },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('./views/Settings.vue'),
    meta: {},
  },
  {
    path: '/access',
    name: 'Access',
    component: () => import('./views/Access.vue'),
    meta: { requiresRole: [Role.ADMIN] },
  },
  {
    path: '/alerts',
    name: 'Alerts',
    component: () => import('./views/Alerts.vue'),
    meta: { requiresRole: [Role.ADMIN] },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('./views/Dashboard.vue'),
    meta: {
      requiresRole: [Role.ADMIN, Role.OPERATOR, Role.DRIVER_DISCIPLINE_OPERATOR, Role.CUSTOMER_CARE_OPERATOR, Role.MAINTENANCE_OPERATOR, Role.TRANSPORT_SCHEDULE_OPERATOR, Role.DASHBOARD_VIEWER]
    },
  },
  {
    path: '/sop-documentation',
    name: 'SOP documentation',
    component: () => import('./views/SopDocumentation.vue'),
    meta: {
      requiresRole: [Role.ADMIN, Role.OPERATOR, Role.DRIVER_DISCIPLINE_OPERATOR, Role.CUSTOMER_CARE_OPERATOR, Role.MAINTENANCE_OPERATOR, Role.TRANSPORT_SCHEDULE_OPERATOR, Role.DASHBOARD_VIEWER]
    },
  },
  // ------------------------------------
  // Final catch all route
  // ------------------------------------
  {
    path: "*",
    name: "Page Not Found",
    component: () => import(/* webpackChunkName: "NotFoundPage" */ "./views/NotFoundPage.vue"),
    meta: {
      public: true,
      requiresRole: [],
      requiresAllRoles: [],
      forbidRoles: []
    },
  },
]

const router = new VueRouter({
  mode: "history",
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash != "") {
      return { selector: to.hash, behavior: 'smooth', offset: { x: 0, y: 60 } };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

// Route navigation guards
router.beforeEach(async (to, from, next) => {
  // If route is public allow anyone
  if (to.meta?.public === true) {
    // If we have a token try authenticate
    if (auth.hasAuthToken() && !auth.isSignedIn()) {
      auth.removeLocalStorageToken()
    }
    next();
    return;
  }

  // If we have a token but are not signed in, wait to authenticate
  if (auth.hasAuthToken() && !auth.isSignedIn()) {
    await auth.fetchCurrentUser().catch(() => { auth.removeLocalStorageToken() });
  }

  // Must signed in
  const user = auth.currentUser();
  if (!auth.isSignedIn() || !user) {
    flash.error("Please sign in to continue")
    next("/sign-in");
    return
  }

  // Allow superuser anywhere
  if (user?.superuser === true) {
    next();
    // Start a connection
    if (!sockets.isConnected()) sockets.createConnection(user.id)
    return;
  }

  // Restrict based on user roles
  if (Array.isArray(to.meta?.requiresAllRoles) && !auth.hasAllRoles(to.meta?.requiresAllRoles ?? [])) {
    flash.error("You do not have all the required permissions to continue")
    next(false)
    return
  }
  if (Array.isArray(to.meta?.requiresRole) && !auth.hasAtLeastOneRole(to.meta?.requiresRole ?? [])) {
    flash.error("You do not have the required permission to continue")
    next(false)
    return
  }
  if (Array.isArray(to.meta?.forbidRoles) && auth.hasNoneOfRoles(to.meta?.forbidRoles ?? [])) {
    flash.error("You do not have permission to continue")
    next(false)
    return
  }

  // Start a connection
  if (!sockets.isConnected()) sockets.createConnection(user.id)
  next();
});

export default router;
