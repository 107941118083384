import Vue from "vue";
import format from "date-fns/format"
import formatDistance from "date-fns/formatDistance";
import { kvStore, kvStoreKeys } from "@/plugins/keyValueStore";
import { timeAgo } from "@/utils";

export default Vue.extend({
  data: () => ({
    timeAgoBaseDate: new Date()
  }),
  methods: {
    timeFormat(date: Date | number | string, fmt: string = "yyyy-MM-dd HH:mm", options: any = {}): string {
      if (typeof date === "string") date = new Date(date)
      if (!date) return ""
      return format(date, fmt, options)
    },
    timeAgo(date: Date | number | string) {
      return timeAgo(date)
    },
  }
});