
import TimeMixin from "@/mixins/TimeMixin";
import Vue from "vue";

export default Vue.extend({
  mixins: [TimeMixin],
  name: "TimeAgoToolTip",
  props: {
    value: { type: String, default: "" },
    format: { type: String, default: "iii dd MMM yyyy HH:mm:ss" },
    position: { type: String, default: "bottom" },
  },
});
