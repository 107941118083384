
import Vue from "vue";
import TimeMixin from "@/mixins/TimeMixin";
import DisplayMixin from "@/mixins/DisplayMixin";
import { iEvent, iTicket } from "@/managers/tickets";
import EventUpdater from "@/components/EventUpdater.vue";
import TicketMap from "@/components/TicketMap.vue";
import TimeAgoToolTip from "@/components/utils/TimeAgoToolTip.vue";
import axios from "axios";
import { utcToLocal } from "@/utils";
import format from "date-fns/format";

export default Vue.extend({
  name: "EventsList",
  mixins: [TimeMixin, DisplayMixin],
  components: { EventUpdater, TicketMap, TimeAgoToolTip },
  props: {
    ticket: { type: Object as () => iTicket, required: true },
  },
  data: () => ({
    headers: [
      { text: "Event No.", value: "id" },
      { text: "Received", value: "createdUtc" },
      { text: "Alert time", value: "alertUtc" },
      { text: "Event type", value: "alertType" },
      { text: "Driver", value: "driverName" },
      { text: "Vehicle", value: "vehicleCode" },
      { text: "Store", value: "storeName" },
      { text: "", value: "data-table-expand", sortable: false, cellClass: "pr-0" },
      { text: "", value: "actions", sortable: false },
    ],
    expanded: true,
    expandedRows: [],
    options: {
      sortBy: ["createdUtc"],
      sortDesc: [true],
    },
    eventToUpdate: null as null | iEvent,
    loadingScheduledArrivalTime: false,
    showScheduledArrivalTime: false,
    scheduledArrivalTimes: [] as string[],
  }),
  methods: {
    showSatBtn(event: iEvent) {
      if (!event) return false;
      return event.alertUtc && event.storeId;
    },
    findScheduledArrival(event: iEvent) {
      if (!event.alertUtc || !event.storeId) return;
      this.loadingScheduledArrivalTime = true;
      axios
        .get(
          `/app/events/scheduled-arrival?route_date=${event.alertUtc.slice(0, 10)}&trailer_code=${
            event.vehicleCode
          }&store_id=${event.storeId}`
        )
        .then((res) => res.data)
        .then((data: string[]) => {
          this.showScheduledArrivalTime = true;
          this.scheduledArrivalTimes = data.map((x) => {
            const datetime = event.alertUtc.slice(0, 10) + "T" + x;
            return format(new Date(datetime), "yyyy-MM-dd kk:mm");
          });
        })
        .finally(() => {
          this.loadingScheduledArrivalTime = false;
          this.showScheduledArrivalTime = true;
        });
    },
  },
});
