import { baseTransformInbound, canFetch, canFetchIfEmpty, canGetAll, canGetById, canUpdate } from "./_baseManager";
import { StoreKey, store } from "@/plugins/dataStore";
import axios from 'axios';
import { iRole, Role } from "./roles";

export interface iUser {
  id: number
  email: string
  username: string
  confirmedEmail: boolean
  roles: iRole[]
  superuser?: boolean
  apikeyId: number | null
  active: boolean
  createdUtc: string
  lastSeenUtc: string | null
}

export interface iUserSkinny {
  id: number
  email: string
  username: string
}

export interface iUserInvite {
  email: string;
  roles: Role[];
}

export interface iUserRegister {
  emailToken: string;
  password: string;
}

export interface iUserUpdate {
  username?: string;
  active?: boolean;
  roles?: Role[];
}

export interface iUserSelfUpdate {
  username?: string;
}

export interface iUserPasswordReset {
  emailToken: string;
  email: string;
  newPassword: string;
}

export const users = function () {
  const storeKey = StoreKey.USERS;
  const baseUrl = "/app/users";

  return {
    storeKey: storeKey,
    url: baseUrl,
    ...canGetAll<iUser>(storeKey),
    ...canGetById<iUser>(storeKey),
    ...canUpdate<iUser, iUserUpdate>(storeKey, baseUrl),
    ...canFetch<iUser>(storeKey, baseUrl),
    ...canFetchIfEmpty<iUser>(storeKey, baseUrl),
    updateSelf(data: iUserSelfUpdate) {
      return axios.patch(baseUrl, data).then((res) => {
        if (!res) return
        store.upsertItems(storeKey, [baseTransformInbound<iUser>(res.data)]);
      });
    },
    invite(invite: iUserInvite) {
      return axios.post(`${baseUrl}/invite`, invite).then((res) => {
        if (!res) return
        store.upsertItems(storeKey, [baseTransformInbound<iUser>(res.data)]);
      });;
    },
    resendInvite(userId: number) {
      return axios.post(`${baseUrl}/resend-invite`, { id: userId })
    },
    register(password: string, emailToken: string) {
      const payload: iUserRegister = {
        emailToken,
        password
      }
      return axios.patch(`${baseUrl}/register`, payload)
    },
    requestPasswordResetEmail(email: string) {
      return axios.post(`${baseUrl}/request-password-reset`, { email });
    },
    resetPassword(email: string, password: string, token: string) {
      const payload: iUserPasswordReset = {
        emailToken: token,
        email: email,
        newPassword: password
      }
      return axios.post(`${baseUrl}/reset-password`, payload)
    }
  }
}()