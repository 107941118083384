
import Vue from "vue";
import axios from "axios";
import ValidationMixin from "@/mixins/ValidationMixin";
import InlineInput from "@/components/inputs/InlineInput.vue";
import { mgr } from "@/managers";
import { iTicket, iDriver, iEvent, iEventUpdate, iStore, iVehicle } from "@/managers/tickets";
import { iSopStep } from "@/managers/sop";
import { ActionType } from "@/managers/ticketActions";

interface iMissingFieldsEvent extends iEvent {
  missingFields: string[];
}

export default Vue.extend({
  name: "CompleteRequiredFields",
  mixins: [ValidationMixin],
  components: { InlineInput },
  props: {
    step: { type: Object as () => iSopStep, required: true },
    ticket: { type: Object as () => iTicket, required: true },
  },
  data: () => ({
    drivers: [] as iDriver[],
    vehicles: [] as iVehicle[],
    stores: [] as iStore[],
    updates: [] as iEventUpdate[],
  }),
  computed: {
    actionTypes(): ActionType[] {
      return this.ticket.actions.map((x) => x.actionType);
    },
    isResolved(): boolean {
      if (this.eventsWithMissingFields.length == 0 || this.actionTypes.includes(ActionType.RESOLVED)) return true;
      return false;
    },
    driverOptions(): { text: string; value: any }[] {
      return [
        ...this.drivers.map((x) => {
          return { value: x.id, text: `${x.name} (${x.staffId})` };
        }),
      ];
    },
    vehicleOptions(): { text: string; value: any }[] {
      return [
        ...this.vehicles.map((x) => {
          return { value: x.id, text: `${x.code} ${x.type}` };
        }),
      ];
    },
    storeOptions(): { text: string; value: any }[] {
      return [
        ...this.stores.map((x) => {
          return { value: x.id, text: `${x.name} (${x.code})` };
        }),
      ];
    },
    eventsWithMissingFields(): iMissingFieldsEvent[] {
      if (!this.ticket?.events) return [];
      return this.ticket.events
        .filter(
          (x: iEvent) =>
            x.driverId === null ||
            x.vehicleId === null ||
            x.storeId === null ||
            x.latitude === null ||
            x.longitude === null
        )
        .map((x: any) => {
          x.missingFields = [];
          if (x.driverId === null) x.missingFields.push("driverId");
          if (x.vehicleId === null) x.missingFields.push("vehicleId");
          if (x.vehicleId === null) x.missingFields.push("vehicleId");
          if (x.storeId === null) x.missingFields.push("storeId");
          if (x.latitude === null) x.missingFields.push("latitude");
          if (x.longitude === null) x.missingFields.push("longitude");
          return x;
        })
        .sort((a, b) => a.id - b.id);
    },
  },
  methods: {
    updateStep() {
      if (this.isResolved) {
        this.$emit("updateStep", { step: this.step, resolved: true });
      } else {
        this.$emit("updateStep", {
          step: this.step,
          resolved: false,
          unResolvedReason: "Some events have missing information",
        });
      }
    },
    updateEvent(eventId: number, key: keyof iEventUpdate, value: any) {
      const eventUpdate: iEventUpdate = {};
      eventUpdate[key] = value;
      mgr.tickets.updateEvent(eventId, eventUpdate);
    },
  },
  watch: {
    actionTypes() {
      this.updateStep();
    },
  },
  mounted() {
    this.updateStep();
  },
  beforeMount() {
    axios
      .get("/app/pages/alerts")
      .then((response) => response.data)
      .then((data) => {
        this.drivers = data.drivers;
        this.stores = data.stores;
        this.vehicles = data.vehicles;
      });
  },
});
